import React from 'react';
import { SingleDate } from './formElements/SingleDate';
import { MultiText } from './formElements/MultiText';
import { SingleText } from './formElements/SingleText';
import { FileUpload } from './formElements/FileUpload';
import { LocationText } from './formElements/LocationText';

export const FormElement = ({ state, report, updateState, onEnter, onRejectedImage, config, ...props }) => {
    if (config.type === 'date') {
        return <SingleDate value={state} report={report} setValue={updateState} config={config} {...props}/>;
    }

    if (config.input === 'text' && config.type === 'geo') {
        return <LocationText value={state || ''} report={report} setValue={updateState} config={config} onEnter={onEnter} {...props}/>;
    }

    if (config.input === 'text' && config.multi) {
        return <MultiText value={state || ['']} report={report} setValue={updateState} config={config} {...props}/>;
    }

    if (config.input === 'text' || config.input === 'textarea') {
        return <SingleText value={state || (config.type === 'date' ? {} : '')} report={report} setValue={updateState} config={config} onEnter={onEnter} {...props}/>;
    }

    if (config.input === 'file') {
        return <FileUpload value={state || []} report={report} setValue={updateState} config={config} onRejectedImage={onRejectedImage} {...props}/>;
    }

    return null;
};