import { useContext, useMemo } from "react";
import { LineChart } from "../../../components/charts";
import { useDashboardAggregation } from "../../../hooks/useDashboardAggregations";
import { FilterContext } from "./ControlsWrapper";

const timePeriodConfig = {
  day: { by: 'hour', name: 'Hour', title: 'Hour of Day' },
  month: { by: 'day', name: 'Day', title: 'Day of Month' },
};

export const IncidentsByTimePeriod = () => {
  const { filters, dateGranularity, setDateGranularity, setDateNative } = useContext(FilterContext);
  const config = timePeriodConfig[dateGranularity];

  const { data, loading, colors } = useDashboardAggregation({
    aggregation: `incidentsBy${config.name}`,
  });

  const completeData = useMemo(() => {
    const fullData = Array(dateGranularity === 'day' ? 24 : filters.dates[1].getDate()).fill(0);
    data.forEach(({ _id, count }) => (fullData[dateGranularity === 'day' ? _id : (_id-1)] = count));
    return fullData.map((count, _id) => ({ [config.by]: (dateGranularity === 'day' ? ("0" + _id).slice(-2) : _id+1), count }));
  }, [loading, data, dateGranularity, config.by, filters.dates]);

  return (
    <LineChart
      colors={colors}
      fullwidth
      loading={loading}
      data={completeData}
      dataKey={config.by}
      title={`Incidents by ${config.title}`}
      xs={8}
      height={300}
      onClick={(ev) => {
        if (dateGranularity === 'month') {
          const dayOfMonth = ev.activeLabel;
          const date = new Date (filters.dates[0]);
          date.setDate(dayOfMonth);
          setDateGranularity('day');
          setDateNative(date);
        }
      }}
    />
  );
};

export default IncidentsByTimePeriod;
