import React, { useEffect } from 'react';
import { TextField, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';
import Check from '@mui/icons-material/Check';
import Warning from '@mui/icons-material/Warning';
import { template } from '../../data/template';

export const SingleDate = ({ value, setValue, config }) => {
    const isOptional = config.optional || !config.okay;
    const isOkay = !config.okay || config.okay(value);
    const [Adornment, adornmentColor, adornmentTitle] = isOkay ? [Check, 'success', 'okay'] : [Warning, 'warning', 'notOkay'];

    // provide blank value on first render
    useEffect(() => setValue(value), []);

    return <>
        <TextField
            sx={{ marginTop: '0.5em', marginBottom: '1em' }}
            multiline={config.input === 'textarea'}
            rows={6}
            fullWidth
            required={!isOptional}
            value={value.date}
            onChange={(e) => setValue((v) => ({ ...v, date: e.target.value }))}
            type={config.type}
            label={`${config.label}${isOptional ? " (Optional)" : ''}`}
            placeholder={template[config.field]?.placeholder || ''}
            error={!isOkay}
            InputLabelProps={{
                shrink: true
            }}
            InputProps={{
                endAdornment: <>
                    {template[config.field] && <InputAdornment position="end">
                        <Adornment title={template[config.field][adornmentTitle]} color={adornmentColor} />
                    </InputAdornment>}
                    {config.ongoing && <InputAdornment position="end">
                        <FormControlLabel labelPlacement="start" control={<Checkbox checked={Boolean(value.ongoing)} onChange={(e, checked) => setValue((v) => ({ ...v, ongoing: checked }))} />} label="Ongoing?" />
                    </InputAdornment>}
                </>
            }} />
    </>;

};
