import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Container, Step, StepButton, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useUserRole } from '../hooks/useUserRole';
import { abuseNames } from '../data/abuseNames';
import { AbuseInformation, NewReport } from '../components/NewReport';
import { Link, useParams } from 'react-router-dom';
import { RealmContext } from '../ReactRealmProvider';
import { FormElement } from '../components/FormElement';
import { okActor } from '../utils/ok';
import { fromUrl, parseDomain } from 'parse-domain';
import { DataContext } from '../contexts/DataProvider';
import { abuseSteps } from '../data/steps';
import { useEmbedded } from '../hooks/useEmbedded';
import { useHistory } from 'react-router-dom';
import { useIsAnon } from '../hooks/useIsAnon';
import { LanguageContext } from '../contexts/LanguageProvider';

const blankReport = { actor: '', actualActor: '', domain: '', ianaId: -1, registrar: null, registrarGroup: null, type: '' };

export const ReportContext = createContext({});

export const ReportRoot = ({ children }) => {
    const { mongo, user } = useContext(RealmContext);
    const embedded = useEmbedded();
    const isReporter = useUserRole('reporter');
    const [ loading, setLoading ] = useState(true);
    const [ embedConfig, setEmbedConfig ] = useState();

    useEffect(() => {
        if (embedded.isEmbedded && embedded.referrer && mongo) {
            setLoading(true);
            setEmbedConfig();
            user.functions.findRegByOrigin(embedded.referrer).then((doc) => {
                if (doc) setEmbedConfig(doc);
                setLoading(false);
            });
        }
        else {
            setLoading(false);
        }
    }, [embedded.isEmbedded, embedded.referrer, mongo]);

    const [ preReport, setPreReport ] = useState(blankReport);
    const updatePreReport = useCallback((field, value) => setPreReport((t) => ({ ...t, [field]: typeof (value) === 'function' ? value(t[field]) : value })), [setPreReport]);

    const [ report, setReport ] = useState({});
    const updateReport = useCallback((field, value) => {
        setReport((t) => ({ ...t, [field]: typeof (value) === 'function' ? value(t[field]) : value }));
    }, [setReport]);

    const [ preStep, setPreStep ] = useState(0);

    const [activeStep, setActiveStep] = useState(-1);
    const [maxStep, setMaxStep] = useState(activeStep);
    const prevStep = useCallback(() => setActiveStep((s) => s - 1), [setActiveStep]);
    const nextStep = useCallback(() => setActiveStep((s) => s + 1), [setActiveStep]);
    const [saved, setSaved] = useState();
    const [savedId, setSavedId] = useState();

    const { type } = useParams();
    useEffect(() => {
        if (!type) {
            // console.log('time to reset the report');
            setPreReport(blankReport);
            setReport({});
            setSaved();
            setSavedId();
            setPreStep(0);
            setActiveStep(-1);
            setMaxStep(-1);
        }
    }, [type]);

    const context = {
        embedded,
        embedConfig,

        preReport, setPreReport, updatePreReport,
        preStep, setPreStep,

        report, setReport, updateReport,
        activeStep, setActiveStep,
        maxStep, setMaxStep,
        prevStep,
        nextStep,
        saved, setSaved,
        savedId, setSavedId,
    };

    if (! isReporter) return null;
    if (embedded.isEmbedded && ! embedConfig) {
        return !loading && `NetBeacon is not configured to receive reports from ${embedded.referrer}.`;
    }

    return <ReportContext.Provider value={context}>
        {children}
    </ReportContext.Provider>;
};

export const MakeNewReport = () => {
    const language = useContext(LanguageContext);
    const { type } = useParams();
    const history = useHistory();

    const { mongo, user, callFunction, logout } = useContext(RealmContext);
    const { registrars, registrarGroups } = useContext(DataContext);
    const { embedded, embedConfig, preReport, updatePreReport, setReport, preStep, setPreStep, setActiveStep, setMaxStep } = useContext(ReportContext);
    const { isEmbedded } = embedded;

    const [ savedReports, setSavedReports ] = useState([]);
    const [ warning, setWarning ] = useState();
    const [ error, setError ] = useState();
    const [ checkingRdap, setCheckingRdap ] = useState(false);

    const logAttempt = useCallback(() => {
        const actor = preReport.actor;

        mongo.db('cart').collection('attemptedReports').insertOne({
            realmId: user.id,
            ts: new Date (),
            actor,
            hostname: getDomain(actor),
            domain: getDomain(actor),
            error,
        }).then((r) => {
            // console.log(r);
        }).catch((error) => {
            console.error(error);
        });
    }, [user, preReport.actor, error]);

    const isAnon = useIsAnon();

    useEffect(() => {
        if (! type) {
            setWarning();
            setError();
        }
    }, [type]);

    useEffect(() => {
        if (error) logAttempt();
    }, [error]);

    useEffect(() => {
        if (preReport.actor.match('/') && !preReport.actor.match(/^https?:/i)) {
            updatePreReport('actor', `http://${preReport.actor}`);
        }
    }, [preReport.actor]);

    useEffect(() => {
        if (preReport.actor !== preReport.actualActor) { //} && preStep > 0) {
            setError();
            setWarning();
            setPreStep(0);
        }
    }, [preReport.actor, preReport.actualActor, preStep]);

    const checkRdap = useCallback(() => {
        updatePreReport('actualActor', preReport.actor);
        const hostname = getHostname(preReport.actor);
        const domain = getDomain(preReport.actor);
        const tld = domain?.replace(/.*?\./, '').toLocaleLowerCase();
        updatePreReport('domain', domain);
        setCheckingRdap(true);
        // console.log(preReport.actor, hostname, domain)

        if (! domain) {
            updatePreReport('ianaId', -1);
            setError(`We could not find a domain name in the site you are trying to report, ${preReport.actor}.`);
            setCheckingRdap(false);
            return;
        }

        if (embedConfig && embedConfig.type === 'registry' && !embedConfig.tlds.includes(tld)) {
            setPreStep((s) => s+1+1);
            setWarning(`${embedConfig.name} is not the registry for ${domain}.`);
            // setCheckingRdap(false);
        }
        else if (!domain.match(/\.\w\w$/)) {
            const rdapUrl = domain.match(/\.help$/) ? 'https://rdap.centralnic.com/help/domain' : 'https://rdap.org/domain';
            fetch(`${rdapUrl}/${domain}`).then((r) => r.json()).then(async (r) => {
                setWarning('');

                // console.log(r);
                if (r.errorCode) {
                    setError(`We could not fetch an RDAP record for ${domain}: ${r.title}.`);
                    setPreStep((s) => s+1);
                }
                else {
                    const ianaId = parseInt(r.entities.find((e) => e.roles.includes('registrar'))?.handle) || -1;
    
                    if (ianaId > 0) {
                        let okay = true;
                        // console.log({ domain, hostname, actor: preReport.actor });

                        if (embedConfig && embedConfig.type === 'registrar' && !embedConfig.ianaIds.includes(ianaId)) {
                            okay = false;
                            const reg = registrars.find((r) => r.ianaId == ianaId);
                            const regGroup = registrarGroups.find((r) => r.registrars.find((r) => r.ianaId == ianaId));
                            const addl = reg.ianaId === regGroup.ianaId ? '' : ` (doing business as ${reg.name})`;
                            updatePreReport('registrar', registrars.find((r) => r.ianaId == ianaId));
                            updatePreReport('registrarGroup', registrarGroups.find((r) => r.registrars.find((r) => r.ianaId == ianaId)));
                            updatePreReport('ianaId', ianaId);
                            setPreStep((s) => s+1+1);
                            setWarning(`${embedConfig.name} is not the registrar for ${domain}, which is registered through ${regGroup.name}${addl}.`);
                            // setCheckingRdap(false);
                        }
                        else if (domain === preReport.actor || hostname === preReport.actor) {
                            const ok = await fetch(`https://us-east1-cart-324516.cloudfunctions.net/checkDns?actor=${preReport.actor}`, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Access-Control-Request-Headers': '*',
                                }
                            }).then((r) => r.json()).then((r) => r.ok).catch((error) => {
                                console.error(error);
                                return false;
                            });

                            // console.log(ok);
                            
                            if (! ok) {
                                okay = false;
                                setError(`The ${hostname !== domain ? 'hostname' : 'domain'} ${preReport.actor} does not have a DNS response.`)
                                setWarning();
                                setPreStep((s) => s+1);
                            }
                            else {
                                setWarning();
                            }
                        }
                        else {
                            const ok = await callFunction('checkUrl', preReport.actor).catch((error) => {
                                // console.log(error);
                                return false;
                            });
                            setWarning(ok ? '' : `The web site ${preReport.actor} did not resolve.`);
                        }

                        if (okay) {
                            setError();
                            updatePreReport('registrar', registrars.find((r) => r.ianaId == ianaId));
                            updatePreReport('registrarGroup', registrarGroups.find((r) => r.registrars.find((r) => r.ianaId == ianaId)));
                            updatePreReport('ianaId', ianaId);
                            setPreStep((s) => s+2);
                        }
                    }
                    else {
                        updatePreReport('ianaId', ianaId);
                        setPreStep((s) => s+1);
                        setError(`We could not determine the registrar for ${domain}.`);
                    }
                }
                setCheckingRdap(false);
            }).catch((error) => {
                console.error(error);
                setError(`We could not fetch an RDAP record for ${domain}.`);
                setPreStep((s) => s+1);
                setCheckingRdap(false);
            })
        }
        else if (domain.match(/\.\w\w$/)) {
            const tld = domain.replace(/.*\./, '').toLocaleLowerCase();
            mongo.db('cart').collection('registries').findOne({ tlds: tld }).then(async (ccTld) => {
                if (!ccTld || !ccTld.active) {
                    // console.log(ccTld);
                    updatePreReport('ianaId', -1);
                    setError(`The ccTLD .${tld} is not configured to receive reports through NetBeacon at this time.`);
                    setCheckingRdap(false);
                    setPreStep((s) => s+1);
                    return;
                }
                ccTld.settings = await mongo.db('cart').collection('settings').findOne({ registryId: ccTld._id });
                setError();
                setWarning();
                updatePreReport('registry', ccTld);
                setCheckingRdap(false);
                setPreStep((s) => s+2);
            });
        }
        else {
            setPreStep((s) => s+2);
            setCheckingRdap(false);
        }
    }, [preReport, embedConfig]);

    useEffect(() => {
        mongo.db('cart').collection('reports').find({ submit: false }).then((r) => {
            setSavedReports(r);
        });
    }, [type]);

    return (<Box sx={{ pt: 1.5 }}>
        {type ? <NewReport/> : <Container>
            <Typography variant='h4' mb={1}>{language.reportAbuse}</Typography>
            {embedConfig && <Typography variant='h6' mb={1}>{embedConfig.name}</Typography>}
            <Typography mb={1}>{language.reportWalk}</Typography>
            <Typography mb={1}>{language.reportStart}</Typography>

            <Stepper orientation="vertical" activeStep={preStep}>
                <Step expanded>
                    <StepLabel>{language.reportActor}</StepLabel>
                    <StepContent>
                        <Typography>{language.reportActorText}</Typography>
                        <FormElement
                            config={{
                                input: 'text',
                                type: 'text',
                                field: 'actor',
                                label: language.reportActorHelp,
                                description: "",
                                okay: (u) => okActor(u),
                            }}
                            report={preReport}
                            state={preReport.actor || ''}
                            updateState={(value) => updatePreReport('actor', value)}
                            // onEnter={() => !checkingRdap && okActor(preReport.actor) && checkRdap()}
                        />
                        {!checkingRdap && warning && <Typography>Note: {warning}</Typography>}
                        {preStep === 0 && <Box>
                            <Button
                                onClick={() => !checkingRdap && checkRdap()}
                                variant='contained'
                                color={!okActor(preReport.actor) ? 'warning' : 'primary'}
                                disabled={checkingRdap || !okActor(preReport.actor)}
                                endIcon={checkingRdap ? <CircularProgress size='1rem' /> : null}
                            >
                                {language.continue}
                            </Button>
                        </Box>}
                    </StepContent>
                </Step>
                <Step expanded={preStep > 0}>
                    <StepLabel>
                        {(preStep > 0 && error) ? language.error : language.reportRegistrarText}
                        {!checkingRdap && !error && preReport.ianaId > 0 && preReport.registrar && <>: {preReport.registrar.name} <Chip label={`IANA ${preReport.ianaId}`}/></>}
                        {!checkingRdap && !error && preReport.registry && <>: {preReport.registry.name}</>}
                    </StepLabel>
                    <StepContent>
                        {!checkingRdap && !error && preReport.ianaId > 0 && preReport.registrar && preReport.registrarGroup && <Box>
                            {/* <Typography component="div">{preReport.registrar.name} <Chip label={`IANA ${preReport.ianaId}`}/></Typography> */}
                            {(preReport.registrar.ianaId !== preReport.registrarGroup.ianaId) && <Typography component="div">Parent Registrar: {preReport.registrarGroup.name} <Chip label={`IANA ${preReport.registrarGroup.ianaId}`}/></Typography>}
                        </Box>}
                        {!checkingRdap && error && <Typography>{error}</Typography>}
                    </StepContent>
                </Step>
                <Step expanded={preStep > 1}>
                    <StepLabel>{language.reportType}{preReport.type && <>: {abuseNames[preReport.type]}</>}</StepLabel>
                    <StepContent>
                        {preStep === 2 && <Box display="flex" flexDirection={isEmbedded ? 'column' : 'row'} justifyContent="center">
                            {Object.entries(abuseNames).map(([ t, name ]) => {
                                return <Card key={t} sx={{m: 1, width: isEmbedded ? '100%' : '25%', display: 'flex', flexDirection: isEmbedded ? 'row' : 'column', justifyContent: 'space-between'}}>
                                    <CardContent>
                                        <Typography variant="h5">{language[`report${name}`]}</Typography>
                                        <Typography sx={{fontSize: 14}}>{language[`report${name}Text`]} <a href={`https://netbeacon.org/${t}`}>{language.reportMoreInfo}</a></Typography>
                                    </CardContent>
                                    <CardActions sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Button variant='contained' onClick={() => {
                                            updatePreReport('type', t);
                                            setPreStep(3);
                                        }}>{language.reportVerb} {language[`report${name}`]}</Button>
                                        </CardActions>
                                </Card>;
                            })}
                        </Box>}
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>{language.reportNeeded}</StepLabel>
                    {preReport.type && <StepContent>
                        <Box display="flex" justifyContent="center">
                            <Card sx={{m: 1, width: '50%'}}>
                                <CardContent>
                                    <AbuseInformation showStepNumber={false} mode='required' steps={abuseSteps[preReport.type]}/>
                                </CardContent>
                            </Card>
                            <Card sx={{m: 1, width: '50%'}}>
                                <CardContent>
                                    <AbuseInformation showStepNumber={false} mode='optional' steps={abuseSteps[preReport.type]}/>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Button variant='outlined' sx={{mr: 1}} onClick={() => setPreStep(2)}>Back</Button>
                            <Button variant='contained' sx={{ml: 1}} onClick={() => {
                                setReport({});
                                setActiveStep(-1);
                                setMaxStep(-1);
                                history.push(`/new-report/${preReport.type}`);
                            }} /* component={Link} to={`/new-report/${preReport.type}`} */>Continue</Button>
                        </Box>
                    </StepContent>}
                </Step>
            </Stepper>

            {!embedded.isEmbedded && savedReports.length > 0 && <Box sx={{mt: 1}}>
                You have {savedReports.length} saved abuse reports awaiting completion:
                <ul>
                    {savedReports.map((r) => <li key={r._id}>(<Link to={`/new-report/${r.type}/${r.showId}`}>{r.showId}</Link>) {r.type} report for {r.url || r.hostname || r.domain} from {new Date (r.tsSaved ? r.tsSaved[r.tsSaved.length-1] : undefined).toLocaleString()}</li>)}
                </ul>
            </Box>}
        </Container>}
    </Box>);
};


const getDomain = (actor) => {
    const parsed = parseDomain(fromUrl(actor));
    return parsed.icann && `${parsed.icann.domain}.${parsed.icann.topLevelDomains.join('.')}`;
};

const getHostname = (actor) => {
    const parsed = parseDomain(fromUrl(actor));
    return parsed?.hostname;
};
