import { useEffect, useState } from "react";

export const useEmbedded = () => {
  const isEmbedded = Boolean(window.top !== window.self);
  const [ referrer, setReferrer ] = useState('');
  
  useEffect(() => {
    if (isEmbedded && document.referrer) {
      setReferrer(document.referrer);
    }
  }, []);

  return { isEmbedded, referrer };
}

export const Embedded = ({ children }) => useEmbedded().isEmbedded && children;

export const NotEmbedded = ({ children }) => useEmbedded().isEmbedded || children;