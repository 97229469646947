import { Divider, Grid } from '@mui/material';
import { NewUsersTable } from '../../../components/NewUsersTable';
import ChartByTldAndRegistrar from './ChartByTldAndRegistrar';
import IncidentsByTimePeriod from './IncidentsByTimePeriod';
import IncidentsByRegistrar from './IncidentsByRegistrar';
import IncidentsByTld from './IncidentsByTld';
import IncidentsByUserChart from './IncidentsByUserChart';
import ReportsBySubmissionVectorChart from './ReportsBySubmissionVectorChart';
import { useUserRole } from '../../../hooks/useUserRole';
import ControlsWrapper from './ControlsWrapper';
import {AbuseTypesByTldAndRegistrar } from "./AbuseTypesByTldAndRegistrar";

export const Dashboard = ({ mode }) => {
    const isProperRole = useUserRole('viewer');

    if (!isProperRole) return null;
    if (mode === 'admin') {
        return (
        <ControlsWrapper admin>
            <Grid container spacing={4}>
                <IncidentsByTimePeriod />
                <ReportsBySubmissionVectorChart />
                <Grid item xs={12} offset={1}>
                    <Divider sx={{ marginLeft: '2rem', lmarginRight: '1rem' }} light />
                </Grid>  
                <IncidentsByUserChart />
                <Grid item xs={12} offset={1}>
                    <Divider sx={{ marginLeft: '2rem', lmarginRight: '1rem' }} light />
                </Grid>  
                <IncidentsByTld/>
                <Grid item xs={12} offset={1}>
                    <Divider sx={{ marginLeft: '2rem', lmarginRight: '1rem' }} light />
                </Grid>  
                <IncidentsByRegistrar/>
                <Grid item xs={12} offset={1}>
                    <Divider sx={{ marginLeft: '2rem', lmarginRight: '1rem' }} light />
                </Grid>  
                <ChartByTldAndRegistrar />
                <Grid item xs={12} offset={1}>
                    <Divider sx={{ marginLeft: '2rem', lmarginRight: '1rem' }} light />
                </Grid>  
                <NewUsersTable />
            </Grid>
        </ControlsWrapper>
        )
    }
    else if (mode === 'registry') {
        return (
            <ControlsWrapper>
                <Grid container spacing={4}>
                    <IncidentsByRegistrar/>
                    <Grid item xs={12} offset={1}>
                        <Divider sx={{ marginLeft: '2rem', lmarginRight: '1rem' }} light />
                    </Grid>  
                    <IncidentsByTld/>
                    <Grid item xs={12} offset={1}>
                        <Divider sx={{ marginLeft: '2rem', lmarginRight: '1rem' }} light />
                    </Grid>  
                    <ChartByTldAndRegistrar />
                    <Grid item xs={12} offset={1}>
                        <Divider sx={{ marginLeft: '2rem', lmarginRight: '1rem' }} light />
                    </Grid>
                    <AbuseTypesByTldAndRegistrar />
                </Grid>
            </ControlsWrapper>
        )
    }
    return null;
}
