export const downloadCSV = (fileName, data) => {
  const convertDataToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const body = [...data.map((d) => Object.values(d))];
    const string = [headers, ...body].map((r) => r.join(",")).join("\n");
    return string;
  };

  const string = convertDataToCSV(data);
  let csvDownload = document.createElement("a");

  csvDownload.href = "data:text/csv;charset=utf-8," + encodeURIComponent(string);
  csvDownload.setAttribute("hidden", "");
  csvDownload.setAttribute("download", fileName);
  document.body.appendChild(csvDownload);
  csvDownload.click();
  document.body.removeChild(csvDownload);
};
