export const DateTime = ({ timestamp, dateOnly }) => {
    try {
        let utc = timestamp.toISOString().replace(/T|\.\d\d\dZ/g, ' ');
        if (dateOnly) utc = utc.replace(/ .*/, '');

        let locale = dateOnly ? timestamp.toLocaleDateString() : timestamp.toLocaleString();
        return <span title={locale}>{utc}</span>;
    }
    catch { return "n/a"; };
};
