import React from 'react';
import { RealmProvider } from './ReactRealmProvider';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ResetPassword } from './routes/auth/ResetPassword';
import { createTheme, ThemeProvider } from '@mui/material';
import { ConfirmUser } from './routes/auth/ConfirmUser';
import { DataProvider } from './contexts/DataProvider';
import { useEmbedded } from './hooks/useEmbedded';
import { Template } from './Template';
import { EmbeddedForm } from './EmbeddedForm';
import { IncidentVote } from './IncidentVote';
import { LanguageProvider } from './contexts/LanguageProvider';
import './App.css';
import { NewUsersTable } from './components/NewUsersTable';

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: '#004958',
        },
        secondary: {
          main: '#00b6ce',
        },
        accent: {
            main: '#80c342',
            contrastText: '#fff',
        },  
        text: {
          primary: '#3e3e3e',
        },
    },
    typography: {
        fontFamily: '"Open Sans"',
    },
});

export const toolbarButtonsTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: '#004958',
        },
        secondary: {
          main: '#00b6ce',
        },
        accent: {
            main: '#80c342',
            contrastText: '#fff',
        },  
        text: {
          primary: '#3e3e3e',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    marginLeft: '1em',
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: 'inherit'
                }
            }
        }
    }
})

const App = () => {
    const { isEmbedded } = useEmbedded();
    const realmAppId = isEmbedded ? 'embedded-tcsij' : process.env.REACT_APP_REALM_APP_ID;

    // magic to support sav.localhost, etc.
    if (window.location.hostname.match(/\.localhost$/)) {
        return <iframe allow='geolocation' width={960} height={800} src={window.location.origin.replace(/[^/]+(?=localhost)/, '')}/>;
    }        

    return (
        <ThemeProvider theme={theme}>
            <RealmProvider id={realmAppId} remember>
                <DataProvider>
                    <BrowserRouter>
                        <LanguageProvider>
                            <Switch>
                                <Route exact path="/:vote(good|bad)/:incidentId/:settingsId" component={IncidentVote}/>
                                <Route exact path="/auth/reset" component={ResetPassword} />
                                <Route exact path="/auth/confirm" component={ConfirmUser} />
                                <Route path="/" component={isEmbedded ? EmbeddedForm : Template}/>
                            </Switch>
                        </LanguageProvider>
                    </BrowserRouter>
                </DataProvider>
            </RealmProvider>
        </ThemeProvider>
    );
};

export default App;