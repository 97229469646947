import React from 'react';
import { Box } from '@mui/material';

export const CenteredBox = ({ children, ...props }) => <Box display='flex' flexDirection='column' justifyContent='top' alignItems='center' sx={{
    position: 'fixed',
    top: '64px' || '128px' || '0px',
    bottom: '64px' || '128px' || '0px',
    left: '0px',
    right: '0px',
    paddingTop: '64px',
    paddingBottom: '64px',
    overflowY: 'auto',
}} {...props}>
    {children}
</Box>;
