import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RealmContext } from '../ReactRealmProvider';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { okEmail } from '../utils/ok';
import GoogleLogin from 'react-google-login';
import { googleSignIn } from "../assets/googleSignIn";
import * as Realm from 'realm-web';
import { RealmError } from './RealmError';
import { useEmbedded } from '../hooks/useEmbedded';
import { useHistory } from 'react-router-dom';
import { useIsAnon } from '../hooks/useIsAnon';
import { LanguageContext } from '../contexts/LanguageProvider';
import { CenteredBox } from '../components/CenteredBox';

export const Signup = () => {
    const language = useContext(LanguageContext);
    const { login, linkUser, register, user } = useContext(RealmContext);
    const { isEmbedded } = useEmbedded();
    const [google, setGoogle] = useState('normal');
    const history = useHistory();
    const params = useLocation().search;
    const query = new URLSearchParams(params);
    const isAnon = useIsAnon();
    const [error, setError] = useState();
    const onCloseNotice = useRef();
    const [ mode, setMode ] = useState(query.get('email') ? 'email' : null);
    const [ loading, setLoading ] = useState(false);

    const refGoogleCb1 = useRef();
    const refGoogleCb2 = useRef();
    const refGoogleButton = useRef();

    const refEmail = useRef();
    const refName = useRef();
    const refPass1 = useRef();
    const refPass2 = useRef();
    const refEmailCb1 = useRef();
    const refEmailCb2 = useRef();
    const refEmailButton = useRef();

    const [newUser, setNewUser] = useState({
        email: query.get('email') || '',
        name: '',
        password: '',
        password2: '',
        privacy: false,
        terms: false,
    });

    const [googleUser, setGoogleUser] = useState({
        privacy: false,
        terms: false,
    });
    const canGoogle = googleUser.privacy && googleUser.terms;

    const emailRegistration = useCallback(async (event) => {
        event.preventDefault();
        setLoading(true);

        const app = new Realm.App ({ id: process.env.REACT_APP_REALM_APP_ID });
        const u = await app.logIn(Realm.Credentials.serverApiKey(process.env.REACT_APP_REALM_API_KEY));
        const users = u.mongoClient('mongodb-atlas').db('cart').collection('users');

        const userExists = await users.findOne({ email: newUser.email, identities: { $regex: '^local-userpass' } });
        let okay = true;

        if (userExists) {
            setError('An account already exists for this email address.');
            okay = false;
        }

        if (! okay) {
            await u.logOut();
            return;
        }

        if (false && isEmbedded) {
            const reg = await register(newUser.email, newUser.password);
            const creds = Realm.Credentials.emailPassword(newUser.email, newUser.password);
            const ok = await user.linkCredentials(creds);
            console.log({ reg, creds, ok });
            return;
        }

        register(newUser.email, newUser.password)
            .then(async () => {
                const time = new Date ();

                await users.findOneAndUpdate(
                    { email: newUser.email },
                    {
                      $setOnInsert: {
                        email: newUser.email,
                        name: newUser.name,
                        tsCreated: time,
                        tsUpdated: time,
                        type: 'reporter',
                        active: true,
                        approved: false,
                        paused: false,
                        registrarName: '',
                        registrarIanaIds: [],
                        registryName: '',
                        registryTlds: [],
                      }
                    },
                    { upsert: true, returnNewDocument: true }
                ).catch((error) => {
                    console.error(error);
                });

                await u.logOut();
                
                // setPending(true);
                // if (embedded && user) {
                //     return linkUser(user, 'emailPassword', newUser.email, newUser.password);
                // }
                // else {
                //     return login('emailPassword', newUser.email, newUser.password);
                // }

                onCloseNotice.current = () => {
                    if (false && isEmbedded) {
                    }
                    else {
                        history.push(`/login?email=${newUser.email}`);
                    }
                };
                setError('Check your inbox for an email from NetBeacon asking you to confirm your email address.');
                setLoading(false);
            })
            .catch(async (error) => {
                await u.logOut();
                setError(error);
                setLoading(false);
            });
    }, [isEmbedded, newUser]);

    const emailLogin = useCallback(async (event) => {
        event.preventDefault();

        login('emailPassword', newUser.email, newUser.password)
            .catch((error) => {
                setError(error);
                return;
            });
    }, [newUser]);

    const emailOkay = okEmail(newUser.email);
    const passwordOkay = newUser.password.length >= 8;
    const password2Okay = passwordOkay && newUser.password === newUser.password2;
    const okay = emailOkay && password2Okay && newUser.privacy && newUser.terms;

    // useEffect(() => {
    //     if (okay) {
    //         refEmailButton.current.focus();
    //     }
    // }, [okay]);

    // useEffect(() => {
    //     if (canGoogle) {
    //         refGoogleButton.current.focus();
    //     }
    // }, [canGoogle]);

    useEffect(() => {
        if (mode === 'google') {
            refGoogleCb1.current.focus();
        }
        else if (mode === 'email') {
            (query.get('email') ? refName : refEmail).current.focus();
        }
    }, [mode]);

    useEffect(() => {
        if (isEmbedded && user && !isAnon) {
            history.go(-1);
        }
    }, [isEmbedded, user, isAnon]);

    if (!isEmbedded && user) {
        return <Redirect to="/account" />;
    }

    return (
        <CenteredBox>
            <Backdrop
                open={loading}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress/>
            </Backdrop>

            <Dialog open={Boolean(error)}>
                <DialogTitle>Notice</DialogTitle>
                <DialogContent>
                    <DialogContentText>{error && <RealmError error={error}/>}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setError();
                        setLoading(false);
                        if (onCloseNotice.current) {
                            onCloseNotice.current();
                            onCloseNotice.current = null;
                        }
                    }}>Okay</Button>
                </DialogActions>
            </Dialog>

            <Box display="flex" flexDirection="column" maxWidth="sm">
                <form onSubmit={emailRegistration}>
                    <Typography variant="h3" mb={2}>{language.welcome}</Typography>
                    <Typography mb={1}>{language.positionInternet}</Typography>
                    <Typography mb={2}>{language.positionIndustry}</Typography>

                    <Box display='flex' justifyContent='space-between'>
                        <Typography variant="h5">{language.createAccount} {language.withGoogle}</Typography>
                        {/* {mode === 'google' && <Button variant='outlined' onClick={() => setMode()}>Cancel</Button>} */}
                        {mode !== 'google' && <Button variant='contained' onClick={() => setMode('google')}>{language.continue}</Button>}
                    </Box>

                    {mode === 'google' && <>
                    <Box display='flex' flexDirection='column' mt={2}>
                        <Box>
                            <FormControlLabel control={<Checkbox
                                required
                                inputRef={refGoogleCb1}
                                value={googleUser.privacy}
                                onChange={(e, privacy) => {
                                    setGoogleUser((u) => ({ ...u, privacy }));
                                    // if (privacy) refGoogleCb2.current.focus();
                                }} />} label={language.iConsentPrivacy}
                            />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Checkbox
                                required
                                inputRef={refGoogleCb2}
                                value={googleUser.terms}
                                onChange={(e, terms) => setGoogleUser((u) => ({ ...u, terms }))} />} label={language.iConsentTerms}
                            />
                        </Box>
                        <Box display='flex' justifyContent='center'>
                            <GoogleLogin
                                clientId='548386455297-vqol1rf0i560kcqh4utie036cq01tek1.apps.googleusercontent.com'
                                render={(props) => <Button disabled={props.disabled} ref={refGoogleButton} onClick={(e) => {
                                    if (! canGoogle) {
                                        setError(language.iConsentYouMust);
                                        return;
                                    }
                                    else {
                                        setGoogle('disabled');
                                        setLoading(true);
                                        props.onClick(e);
                                    }
                                }}><img
                                        tabIndex={0}
                                        // style={{cursor: 'pointer'}}
                                        src={googleSignIn[canGoogle ? google : 'disabled']}
                                        alt="Google Sign In"
                                        onMouseEnter={() => setGoogle((g) => g === 'disabled' ? g : 'focus')}
                                        onMouseLeave={() => setGoogle((g) => g === 'disabled' ? g : 'normal')}
                                        onMouseDown={() => setGoogle((g) => g === 'disabled' ? g : 'pressed')}
                                    />
                                </Button>
                                }
                                buttonText='Sign in with Google'
                                onSuccess={(r) => (true && isEmbedded && user) ? (linkUser(user, 'function', {
                                    realmId: user.id,
                                    action: 'register',
                                    provider: 'google',
                                    token: r.tokenId,
                                    id: r.profileObj.googleId,
                                    email: r.profileObj.email,
                                    name: r.profileObj.name,
                                    last_name: r.profileObj.familyName,
                                    first_name: r.profileObj.givenName,
                                }).then(() => setLoading(false)).catch((error) => {
                                    setError(error);
                                    setGoogle('normal');
                                    setLoading(false);
                                })) : (login('function', {
                                    provider: 'google',
                                    action: 'register',
                                    token: r.tokenId,
                                    id: r.profileObj.googleId,
                                    email: r.profileObj.email,
                                    name: r.profileObj.name,
                                    last_name: r.profileObj.familyName,
                                    first_name: r.profileObj.givenName,
                                }).then(() => setLoading(false)).catch((error) => {
                                    setError(error);
                                    setGoogle('normal');
                                    setLoading(false);
                                }))}
                                onFailure={(failure) => {
                                    setLoading(false);
                                    setGoogle('normal');
                                    console.log({ failure });
                                }}
                                cookiePolicy='single_host_origin'
                            />
                        </Box>
                    </Box>
                    </>}

                    <Box display="flex" alignItems="center">
                        <Divider sx={{ flexGrow: 1 }} />
                        <Typography sx={{ padding: '0.5em' }}>or</Typography>
                        <Divider sx={{ flexGrow: 1 }} />
                    </Box>

                    <Box display='flex' justifyContent='space-between'>
                        <Typography variant="h5">{language.signup} {language.withEmail}</Typography>
                        {/* {mode === 'email' && <Button variant='outlined' onClick={() => setMode()}>Cancel</Button>} */}
                        {mode !== 'email' && <Button variant='contained' onClick={() => setMode('email')}>{language.continue}</Button>}
                    </Box>

                    {mode === 'email' && <>
                    <Box display='flex' flexDirection='column' mt={2}>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                inputRef={refEmail}
                                required
                                type="email"
                                value={newUser.email}
                                onChange={(e) => setNewUser((u) => ({ ...u, email: e.target.value }))}
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        if (emailOkay) refName.current.focus();
                                    }
                                }}
                                label="Email Address"
                                InputLabelProps={{ shrink: true }}
                                error={newUser.email !== '' && !emailOkay}
                                helperText={!emailOkay ? "Please enter a valid email address" : "Email address appears valid"} />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                disabled={!emailOkay}
                                inputRef={refName}
                                type="text"
                                value={newUser.name}
                                onChange={(e) => setNewUser((u) => ({ ...u, name: e.target.value }))}
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        refPass1.current.focus();
                                    }
                                }}
                                label="Name (optional)"
                                InputLabelProps={{ shrink: true }}
                                helperText="While optional, your name may be useful when processing abuse reports" />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                disabled={!emailOkay}
                                inputRef={refPass1}
                                type="password"
                                required
                                value={newUser.password}
                                onChange={(e) => setNewUser((u) => ({ ...u, password: e.target.value }))}
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        if (passwordOkay) refPass2.current.focus();
                                    }
                                }}
                                label="Password"
                                InputLabelProps={{ shrink: true }}
                                error={newUser.password !== '' && !(!emailOkay) && !passwordOkay}
                                helperText={!passwordOkay ? "Password is too short" : "Password is long enough"} />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                disabled={!emailOkay || !passwordOkay}
                                inputRef={refPass2}
                                type="password"
                                required
                                value={newUser.password2}
                                onChange={(e) => setNewUser((u) => ({ ...u, password2: e.target.value }))}
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        if (password2Okay) refEmailCb1.current.focus();
                                    }
                                }}
                                label="Verify Password"
                                InputLabelProps={{ shrink: true }}
                                error={newUser.password2 !== '' && !(!emailOkay || !passwordOkay) && !password2Okay}
                                helperText={!password2Okay ? "Passwords don't match" : "Passwords match"} />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Checkbox
                                required
                                inputRef={refEmailCb1}
                                value={newUser.privacy}
                                onChange={(e, privacy) => {
                                    setNewUser((u) => ({ ...u, privacy }));
                                    if (privacy) {
                                        // refEmailCb2.current.focus();
                                    }
                                }} />} label={language.iConsentPrivacy} />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Checkbox
                                required
                                inputRef={refEmailCb2}
                                value={newUser.terms}
                                onChange={(e, terms) => {
                                    setNewUser((u) => ({ ...u, terms }));
                                }} />} label={language.iConsentTerms} />
                        </Box>

                        <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                            <Button variant="contained" type="submit" disabled={!okay} ref={refEmailButton}>{language.signUp}</Button>
                            {!isAnon && <div>
                                {language.alreadyHave} <Link to={`/login${newUser.email && `?email=${newUser.email}`}`}>{language.signIn}</Link>
                            </div>}
                            {isAnon && <div>
                                <Button onClick={() => history.go(-1)}>{language.cancel}</Button>
                            </div>}
                        </Box>
                    </Box>
                    </>}

                    {/* {!mode && <Grid item xs={12} display="flex" alignItems="center" justifyContent="right">
                        {!isAnon && <div>
                            Already have an account? <Link to={`/login`}>{language.signIn}</Link>
                        </div>}
                        {isAnon && <div>
                            <Button onClick={() => history.go(-1)}>{language.cancel}</Button>
                        </div>}
                    </Grid>} */}
                </form>
            </Box>
        </CenteredBox>
    );
};