import { useEffect, useState, useContext, useMemo } from "react";
import { RealmContext } from "../ReactRealmProvider";
import { FilterContext } from "../routes/admin/Dashboard/ControlsWrapper";
const emptyObject = {};

export const useDashboardAggregation = ({ aggregation, options = emptyObject, callback = null }) => {
    const { filters, setLoading } = useContext(FilterContext);
    const { callFunction } = useContext(RealmContext);
    const [data, setData] = useState([]);
    const [localLoading, setLocalLoading] = useState(true);
    
    const colors = [
        // NetBeacon colors
        "#004958",
        "#00b6ce",
        "#DB7660",
        "#558FC2",
        // additional colors
        "#40948A", // light green
        "#C7C646", // mustard yellow
        "#AD5157", // light red
        "#C48D54", // orange-yellow
        "#5966B5", // purple
        "#36708F", // light blue
    ];

    useEffect(() => {
        if (localLoading) {
            setLoading((prev) => prev + 1);
        }
        else {
            setLoading((prev) => prev - 1);
        }
    }, [localLoading]);

    useEffect(() => {
        setLocalLoading(true);
        callFunction('getDashboardAggregation', { aggregation, options: filters })
            .then((res) => {
                // console.log(res);
                if (res) {
                    if (callback) {
                        setData(callback(res));
                    } else {
                        setData(res);
                    }
                    setLocalLoading(false);
                }
            })
    }, [aggregation, filters, callback]);

    return {
        loading: localLoading, setLoading: setLocalLoading, setData, data, colors
    }
}

const maxFields = {
    incidentsByRealmId: 'incidents',
    incidentsByRegistrar: 'incidents',
    incidentsByTld: 'incidents',
    incidentsByTldAndRegistrar: 'total',
    incidentsByAbuseTypeAndTldAndRegistrar: 'incidents',
    incidentsByHour: 'count',

}