import {
    Cell,
    Legend,
    Pie,
    PieChart,
    Tooltip
} from "recharts";
import { ChartWrapper } from "./ChartWrapper";

export const PChart = ({ data, fullwidth=false, nameKey="_id", title, xs = 3, height = 300, allowPagination = false, loading, colors }) => {

    return (
        <ChartWrapper fullwidth={fullwidth} title={title} xs={xs} height={height} allowPagination={allowPagination}>
            <PieChart data={data}>
                <Pie
                    dataKey="count"
                    loading={loading}
                    isAnimationActive={false}
                    data={data}
                    cx="40%"
                    cy="40%"
                    outerRadius={90}
                    fill="#8884d8"
                    label
                    nameKey={nameKey}
                >
                    {data?.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                        />
                    ))}
                    <Tooltip />
                </Pie>
                {/* copilot */}
                <Legend content={renderLegend} layout="vertical" verticalAlign="middle" align="right" />
                {/* end copilot */}
                <Tooltip />
            </PieChart>
        </ChartWrapper>
    )
}

export default PChart;


//copilot
const renderLegend = (props) => {
    const { payload } = props;
    return (
        <ul>
            {payload.map((entry, index) => (
                <li key={index} style={{ color: entry.color }}>
                    {entry?.value || 'UNKNOWN'}: {entry.payload.value}
                </li>
            ))}
        </ul>
    );
};
//end copilot
