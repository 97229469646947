import { useState, useMemo, createContext, useCallback, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { RealmContext } from "../../../ReactRealmProvider";

export const FilterContext = createContext();

const _all = { value: "", label: "(all)" };

export default function ControlsWrapper({ admin = false, children }) {
  const { callFunction } = useContext(RealmContext);
  const [dateGranularity, setDateGranularity] = useState("month");
  const [abuseType, setAbuseType] = useState("all");
  const [tld, setTld] = useState("");
  const [tldChoices, setTldChoices] = useState([_all]);
  const [date, setDate] = useState(dayjs());
  const [loading, setLoading] = useState(0);
  const setDateNative = useCallback((date) => {
    setDate(dayjs(date));
  }, []);

  useEffect(() => {
    if (admin) {
      callFunction('getTlds').then((r) => {
        setTldChoices([ _all, ...r.sort().map((tld) => ({ value: tld, label: tld })) ]);    
      });
    }
  }, [admin]);

  const dateView = useMemo(() => getDateView(dateGranularity), [
    dateGranularity,
  ]);

  const abuseTypeChoices = useMemo(
    () => [
      { value: "all", label: "All" },
      { value: "phishing", label: "Phishing" },
      { value: "malware", label: "Malware" },
      { value: "spam", label: "Spam" },
      { value: "botnets", label: "Botnets" },
    ],
    []
  );

  const exportedAbuseType = useMemo(() => {
    if (abuseType === "all") {
      return null;
    }
    return abuseType;
  }, [abuseType]);

  const exportedDates = useMemo(() => {
    switch (dateGranularity) {
      case "day":
        return [date.startOf("day").toDate(), date.endOf("day").toDate()];
      case "week":
        return [date.startOf("week").toDate(), date.endOf("week").toDate()];
      case "month":
        return [date.startOf("month").toDate(), date.endOf("month").toDate()];
      default:
        return [date.toDate(), null];
    }
  }, [date, dateGranularity]);

  const filters = useMemo(
    () => ({
      abuseType: exportedAbuseType,
      dates: exportedDates,
      tld,
    }),
    [exportedAbuseType, exportedDates, tld]
  );

  return (
    <FilterContext.Provider
      value={{
        filters,
        loading,
        setLoading,
        dateGranularity,
        setDateGranularity,
        setDateNative,
      }}
    >
      <Backdrop
        open={Boolean(loading)}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          paddingTop={5}
          display="flex"
          gap={1}
          justifyContent="flex-end"
          width="100%"
        >
          <Box minWidth={125}>
            <FormControl fullWidth>
              <InputLabel id="date-granularity-select-label">
                Date Granularity
              </InputLabel>
              <Select
                labelId="date-granularity-select-label"
                id="date-granularity-select"
                value={dateGranularity}
                label="Date Granularity"
                onChange={(e) => setDateGranularity(e.target.value)}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <DatePicker
            label="Date"
            value={date}
            onChange={(newDate) => setDate(newDate)}
            views={dateView}
          />
          <Box minWidth={125}>
            <FormControl fullWidth>
              <InputLabel id="abuse-type-select-label">Abuse Type</InputLabel>
              <Select
                labelId="abuse-type-select-label"
                id="abuse-type-select"
                value={abuseType}
                label="Abuse Type"
                onChange={(e) => setAbuseType(e.target.value)}
              >
                {abuseTypeChoices.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {admin && (
            <Box minWidth={150}>
              <FormControl fullWidth>
                <InputLabel id="tld-select-label">TLD</InputLabel>
                <Select
                  labelId="tld-select-label"
                  id="tld-select"
                  value={tld}
                  label="TLD"
                  disabled={tldChoices.length === 1}
                  onChange={(e) => setTld(e.target.value)}
                >
                  {tldChoices.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
        {children}
      </LocalizationProvider>
    </FilterContext.Provider>
  );
}

function getDateView(dateGranularity) {
  switch (dateGranularity) {
    case "day":
      return ["year", "month", "day"];
    case "month":
      return ["year", "month"];
    default:
      return ["year", "month", "day"];
  }
}
