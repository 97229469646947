import { Box } from "@mui/material";
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis, Cell } from "recharts";
import { ChartWrapper } from "../../../components/charts/ChartWrapper";
import { useDashboardAggregation } from "../../../hooks/useDashboardAggregations";

export const IncidentsByRegistrar = ({ }) => {
  const { loading, data, colors } = useDashboardAggregation({
    aggregation: "incidentsByRegistrar",
  });

  return (
    <ChartWrapper
      data={data}
      showTable={true}
      headers={[
        { name: "ID", field: "ianaId" },
        { name: "Registrar", field: "name" },
        { name: "Total", field: "incidents" },
      ]}
      needsDivider={false}
      title={"Incidents By Registrar"}
      xs={12}
      height={!data.length ? 120 : Math.min(480, 90 * data.length)}
    >
      <BarChart
        loading={loading}
        layout="vertical"
        data={data.slice(0, 10)}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <YAxis type="category" dataKey="name" width={300} interval={0} />
        <XAxis type="number" dataKey="incidents" />
        <Legend align="center" content={<div> incidents </div>} />
        <Tooltip content={<CustomTooltip data={data} colors={colors} />} />
        <Bar dataKey={"incidents"} >
          {data.map((entry, index) => {
            // console.log({entry, index})
            return (
              <Cell cursor="pointer" fill={colors[index]} key={`cell-${index}`} />

            )
          })}
        </Bar>
      </BarChart>
    </ChartWrapper>
  );
};

export default IncidentsByRegistrar;

const CustomTooltip = ({ active, payload, label, data, colors }) => {
  if (active && payload && payload.length) {
    const { name, _id } = payload[0].payload;
    const color = colors[data.findIndex((d) => d._id === _id)];
    return (
      <Box
        sx={{
          background: "#FFFFFF",
          opacity: "100",
          width: "300px",
          height: "fit-content",
          marginTop: "-60px",
          border: "1px solid grey",
          zIndex: "1500",
        }}
        padding={2}
      >
        <div>
          {name} {parseInt(label) > 0 && ` (IANA ID ${label})`}
        </div>
        {payload.map(({ fill, dataKey, value }, i) => {
          return (
            <div key={i}>
              <span
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: color,
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              />{" "}
              {dataKey} ({value})
            </div>
          );
        })}
      </Box>
    );
  }

  return null;
};
