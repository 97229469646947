import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RealmContext } from '../ReactRealmProvider';
import { Box, Button, Dialog, Stepper, Step, StepLabel, StepContent, Typography, Grid, StepButton, DialogTitle, DialogContent, DialogActions, Tooltip, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { abuseSteps } from '../data/steps';
import { abuseNames } from '../data/abuseNames';
import { FormElement } from './FormElement';
import { uploadEvidence } from '../utils/uploadEvidence';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { ReportContext } from '../routes/MakeNewReport';
import { useParams } from 'react-router-dom';
import { useIsAnon } from '../hooks/useIsAnon';
import { LanguageContext } from '../contexts/LanguageProvider';
import { downloadEvidence } from '../utils/downloadEvidence';
import { createFileFromUrl, readFile } from 'mui-file-dropzone';

export const NewReport = () => {
    const realm = useContext(RealmContext);
    const history = useHistory();
    const { type, id } = useParams();
    const {
        embedded: { isEmbedded }, preReport, report, setReport, updateReport,
        activeStep, setActiveStep, maxStep, setMaxStep, prevStep, nextStep,
        saved, setSaved, savedId, setSavedId,
    } = useContext(ReportContext);

    const [reportId, setReportId] = useState();
    const steps = abuseSteps[type];
    const isAnon = useIsAnon();
    const paused = /* isAnon  || */ realm.customData.paused;
    const [ notice, setNotice ] = useState();
    const [ emailVerified, setEmailVerified ] = useState(false);
    const via = useMemo(() => isEmbedded ? 'embedded' : 'ui', [isEmbedded]);

    const saveReport = useCallback((opts = {}) => {
        const { quiet = false } = opts;

        if (savedId) {
            realm.mongo.db('cart').collection('reports').updateOne({
                _id: savedId,
            }, {
                $set: {
                    ...report,
                    via,
                    date: { ...report.date, date: new Date (report.date.date) },
                },
                $push: { tsSaved: new Date () }
            }).then(() => {
                const showId = savedId.toHexString().substr(-6);

                if (!report.attachments || report.attachments.length === 0) {
                    realm.mongo.db('cart').collection('reports').updateOne(
                        { _id: savedId },
                        { $set: { showId } }
                    ).then(() => {
                        if (!quiet) setSaved(showId);
                    });
                }
                else {
                    uploadEvidence(savedId, report.attachments).then((attachments) => {
                        realm.mongo.db('cart').collection('reports').updateOne(
                            { _id: savedId },
                            { $set: { showId, attachments } }
                        ).then(() => {
                            if (!quiet) setSaved(showId);
                        });
                    });
                }
            });
        }
        else {
            realm.mongo.db('cart').collection('reports').insertOne({
                realmId: realm.user.id,
                ...report,
                via,
                date: { ...report.date, date: new Date (report.date.date) },
                type,
                submit: false,
                tsSaved: [ new Date () ],
            }).then(({ insertedId }) => {
                const showId = insertedId.toString().substr(-6);
    
                if (!report.attachments || report.attachments.length === 0) {
                    realm.mongo.db('cart').collection('reports').updateOne(
                        { _id: insertedId },
                        { $set: { showId } }
                    ).then(() => {
                        setSavedId(insertedId);
                        if (!quiet) setSaved(showId);
                    });
                }
                else {
                    uploadEvidence(insertedId.toHexString(), report.attachments).then((attachments) => {
                        realm.mongo.db('cart').collection('reports').updateOne(
                            { _id: insertedId },
                            { $set: { showId, attachments }, $push: { tsSaved: new Date () } }
                        ).then(() => {
                            setSavedId(insertedId);
                            if (!quiet) setSaved(showId);
                        });
                    });
                }
            });
        }
    }, [type, report, savedId, via]);

    const submitReport = useCallback(() => {
        if (savedId) {
            realm.mongo.db('cart').collection('reports').updateOne({
                _id: savedId,
            }, { $set: {
                ...report,
                via,
                date: { ...report.date, date: new Date (report.date.date) },
                tsReported: new Date (),
            } }).then(() => {
                const showId = savedId.toString().substr(-6);
    
                if (!report.attachments || report.attachments.length === 0) {
                    realm.mongo.db('cart').collection('reports').updateOne(
                        { _id: savedId },
                        { $set: { paused, submit: true } }
                    ).then(() => setReportId(showId));
                }
                else {
                    uploadEvidence(showId, report.attachments).then((attachments) => {
                        realm.mongo.db('cart').collection('reports').updateOne(
                            { _id: savedId },
                            { $set: { paused, submit: true, attachments } }
                        ).then(() => setReportId(showId));
                    });
                }
            });    
        }
        else {
            realm.mongo.db('cart').collection('reports').insertOne({
                realmId: realm.user.id,
                ...report,
                via,
                date: { ...report.date, date: new Date (report.date.date) },
                type,
                tsReported: new Date (),
            }).then(({ insertedId }) => {
                const showId = insertedId.toString().substr(-6);
    
                if (!report.attachments || report.attachments.length === 0) {
                    realm.mongo.db('cart').collection('reports').updateOne(
                        { _id: insertedId },
                        { $set: { showId, paused, submit: true } }
                    ).then(() => setReportId(showId));
                }
                else {
                    uploadEvidence(showId, report.attachments).then((attachments) => {
                        realm.mongo.db('cart').collection('reports').updateOne(
                            { _id: insertedId },
                            { $set: { showId, paused, submit: true, attachments } }
                        ).then(() => setReportId(showId));
                    });
                }
            });
        }
    }, [savedId, type, report, via]);

    useEffect(() => {
        setMaxStep((s) => s < activeStep ? activeStep : s);
    }, [activeStep]);

    useEffect(() => {
        let active = true;
        setReport((report) => ({ ...report, reporterUserAgent: navigator.userAgent, date: { date: (new Date()).toISOString().substr(0, 10), ongoing: true } }));
        if (preReport?.actor) {
            setReport((report) => ({ ...report, url: preReport.actor, domain: preReport.domain, registrar: preReport.registrar, registrarGroup: preReport.registrarGroup }));
        }

        fetch('https://api.ipify.org').then((r) => r.text()).then((ip) => {
            active && updateReport('reporterIp', ip);
        });

        return () => active = false;
    }, [preReport, type]);

    useEffect(() => {
        if (id) {
            console.log(`loading report id ${id}`);
            realm.mongo.db('cart').collection('reports').findOne({ showId: id }).then((r) => {
                if (! r) return;
                const { _id, realmId, showId, type, tsReported, tsSaved, submit, ...report } = r;
                r.date.date = r.date.date.toISOString().substr(0, 10);

                if (r.attachments?.length) {
                    downloadEvidence(r.attachments).then(async (files) => {
                        const attachments = [];
                        for (const f of files) {
                            const file = await createFileFromUrl(f.url);
                            const data = await readFile(file);
                            attachments.push({ file, data });
                        }
                        setReport({...report, attachments });
                        setSavedId(_id);        
                    });
                }
                else {
                    setReport(report);
                    setSavedId(_id);
                }
                // setSaved(showId);
            });
        }
    }, [id]);

    const canSave = useCallback((steps) => steps.every((step) => step.fields.every(
        (f) => (f.input === 'file' ? (f.optional || report[f.field]?.length) : (!f.okay || (f.multi ?
            (report[f.field]?.length && (report[f.field] || []).every((v) => f.okay(v || '', report))) :
            f.okay(report[f.field] || '', report))))
    )), [type, report]);

    const isDisabled = useCallback((step) => !step.fields.every(
        (f) => (f.input === 'file' ? (f.optional || report[f.field]?.length) : (!f.okay || (f.multi ?
            (report[f.field]?.length && (report[f.field] || []).every((v) => f.okay(v || '', report))) :
            f.okay(report[f.field] || '', report))))
    ), [type, report]);

    const sendVerifyEmail = useCallback(() => {
        realm.user.functions.verificationCode({ step: 'generate', id: savedId, email: report.reporterEmail }).then((r) => {
            // console.log(r);
            setNotice({ title: "Email Sent", body: "We have sent a six-digit code to your email address. Please enter the code in the space provided."});
        });
    }, [savedId, report]);

    useEffect(() => {
        setEmailVerified(false);
        if (report.reportVerified?.length === 6) {
            realm.user.functions.verificationCode({ step: 'check', id: savedId, code: report.reportVerified }).then((r) => {
                if (r) {
                    console.log('code matches');
                    setEmailVerified(true);    
                }
                else {
                    console.log('code mismatch');
                    setNotice({ title: "Email Verification Failed", body: "The six-digit code you entered does not match the code we sent to your email address. Please try again."});    
                }
            });
        }
    }, [savedId, report.reportVerified]);

    if (!id && !preReport?.actor) {
        return <Redirect to={'/new-report'} />;
    }

    return (<>
        <Typography mt={1} variant="h4">Submit a New {abuseNames[type]} Abuse Report</Typography>
        <Typography mt={1} variant="h5">For {report.url}</Typography>

        <Dialog open={Boolean(reportId)} onClose={() => history.push(`/new-report`)}>
            <DialogTitle>Report Received</DialogTitle>
            <DialogContent>
                {isEmbedded && <>
                    We have received your completed domain abuse report. Your report ID is {reportId}.
                </>}
                {!isEmbedded && <>
                    We have received your completed domain abuse report. Your report ID is {reportId}, and the status of your report can be found <Link to={`/account/reports/view/${reportId}`}>here</Link>.
                </>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => history.push(`/new-report`)} autoFocus>Close</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={Boolean(saved)} onClose={() => setSaved()}>
            <DialogTitle>Report Saved</DialogTitle>
            <DialogContent>
                Your incomplete report has been saved; this report ID is {saved}.
                You can return at any time to update or complete it{!isEmbedded && <> by going to <Link to={`/account/reports/edit/${saved}`}>Settings &gt; Reports</Link>,
                or by clicking on the <Link to={`/new-report`}>Report Abuse</Link> button at the top of the screen</>}.
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSaved()} autoFocus>Close</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={Boolean(notice)} onClose={() => setNotice()}>
            <DialogTitle>{notice && (notice.title || "Notice")}</DialogTitle>
            <DialogContent>
                {notice && (notice.body || notice)}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setNotice()} autoFocus>Close</Button>
            </DialogActions>
        </Dialog>

        <form encType="multipart/form-data" onSubmit={(e) => {
            e.preventDefault();
        }}>

            <Grid container spacing={4}>
                <Grid item xs={4} mt={2}>
                    {activeStep === -1 && <div>
                        <AbuseDefinition type={type} />
                        <AbuseInformation mode='required' steps={steps}/>
                        <AbuseInformation mode='optional' steps={steps}/>
                        <Box display="flex" justifyContent="center">
                            <Button sx={{mr: 1}} variant='outlined' onClick={() => history.go(-1)}>Back</Button>
                            <Button variant="contained" onClick={nextStep}>{savedId ? 'Resume' : 'Begin'}</Button>
                        </Box>
                    </div>}
                    {activeStep >= 0 && steps[activeStep] && <>
                        <Typography variant="h6">Definitions for fields in step {activeStep + 1}:</Typography>
                        <HelperText step={steps[activeStep]}/>
                    </>}
                </Grid>

                <Grid item xs={8}>
                    <Stepper orientation="vertical" activeStep={activeStep} nonLinear>
                        {(steps || []).map((step, index) => {
                            return <Step key={step.id}>
                                <StepButton disabled={false && index > maxStep} onClick={() => setActiveStep(index)} optional={<Typography variant="caption">{step.title}</Typography>}>
                                    {step.label}
                                </StepButton>
                                <StepContent>
                                    {/* <Typography>{step.title}</Typography> */}
                                    {step.fields.map((config) => {
                                        return <FormElement
                                            key={config.field}
                                            report={report}
                                            state={report[config.field]}
                                            updateState={(value) => updateReport(config.field, value)}
                                            onEnter={() => {
                                                saveReport({ quiet: true });
                                                nextStep();
                                            }}
                                            onRejectedImage={() => {
                                                setNotice({ title: "Adult Image Detected", body: "It looks like you tried to upload an image with adult content. It is against our policy to accept any such content."})
                                            }}
                                            config={config}
                                        />;
                                    })}
                                    <Box display='flex'>
                                        <Tooltip arrow title='Return to previous step'>
                                            {index === 0 ? <Button onClick={() => { setActiveStep((s) => s-1); history.go(-1);}}>Back</Button> : <Button onClick={prevStep}>Back</Button>}
                                        </Tooltip>
                                        {!isAnon && <Tooltip arrow title='Save report to complete later'><Button onClick={() => saveReport()} variant='outlined'>Save</Button></Tooltip>}
                                        <Box flexGrow={1}/>
                                        <Tooltip disabled={false && isDisabled(step)} arrow title='Proceed to next step'><Button onClick={() => {
                                            saveReport({ quiet: true });
                                            nextStep();
                                        }} variant='contained' color={isDisabled(step) ? 'warning' : 'primary'}>Continue {isDisabled(step) && " (incomplete)"}</Button></Tooltip>
                                    </Box>
                                </StepContent>
                            </Step>;
                        })}
                        {isEmbedded && <Step>
                            <StepLabel>Verify Your Email Address</StepLabel>
                            <StepContent>
                                <Typography>Provide your email address here, then click "Confirm". You will receive an email with a six-digit code. Enter the six-digit code in the space provided. This will verify your email address.</Typography>
                                <Box display='flex' mt={1} alignItems='center'>
                                    <FormElement
                                        report={report}
                                        state={report.reporterEmail}
                                        updateState={(value) => updateReport('reporterEmail', value)}
                                        onEnter={() => {
                                            saveReport({ quiet: true });
                                            sendVerifyEmail();
                                        }}
                                        config={{input: 'text', label: 'Email Address', optional: false, okay: (v) => v.match(/^[^@]+@(?:[\w-]+\.)+[\w-]{2,}$/)}}
                                        type="email"
                                    />
                                    <Box ml={1} mt={-1}>
                                        <Tooltip disabled={! (report.reporterEmail?.match(/^[^@]+@(?:[\w-]+\.)+[\w-]{2,}$/))} arrow title='Get confirmation code'><Button onClick={() => {
                                            saveReport({ quiet: true });
                                            sendVerifyEmail();
                                        }} variant='contained' color={'primary'}>Confirm</Button></Tooltip>
                                    </Box>
                                </Box>

                                <Box display='flex' width={'150px'}>
                                    <FormElement
                                        report={report}
                                        state={report.reportVerified}
                                        updateState={(value) => {
                                            updateReport('reportVerified', value.slice(0,6).replace(/[^0-9]/g, ''));
                                        }}
                                        onEnter={() => {
                                        }}
                                        config={{input: 'text', label: 'Verification Code', optional: false, okay: (v) => v.match(/^\d{6}$/)}}
                                        type="text"
                                    />
                                </Box>

                                <Box mt={1} display='flex'>
                                    <Tooltip arrow title='Return to previous step'>
                                        <Button onClick={prevStep}>Back</Button>
                                    </Tooltip>
                                    <Box flexGrow={1}/>
                                    <Tooltip disabled={!emailVerified} arrow title='Proceed to next step'><Button onClick={() => {
                                        saveReport({ quiet: true });
                                        nextStep();
                                    }} variant='contained' color={'primary'}>Continue</Button></Tooltip>
                                </Box>
                            </StepContent>
                        </Step>}
                        <Step>
                            <StepLabel>Submission Receipt</StepLabel>
                            <StepContent>
                                NetBeacon automatically emails a summary of your report to your account email address. If you do not wish to receive this summary, please uncheck this box.
                                <Box mt={1}>
                                    <FormControlLabel control={<Checkbox
                                        checked={!Boolean(report.noReceipt)}
                                        onChange={(e, receipt) => {
                                            updateReport('noReceipt', !receipt)
                                        }} />} label={"Email me a receipt"}
                                    />
                                </Box>

                                <Box mt={1} display='flex'>
                                    <Tooltip arrow title='Return to previous step'>
                                        <Button onClick={prevStep}>Back</Button>
                                    </Tooltip>
                                    {!isAnon && <Tooltip arrow title='Save report to complete later'><Button onClick={() => saveReport()} variant='outlined'>Save</Button></Tooltip>}
                                    <Box flexGrow={1}/>
                                    <Tooltip arrow title='Proceed to next step'><Button onClick={() => {
                                        saveReport({ quiet: true });
                                        nextStep();
                                    }} variant='contained' color={'primary'}>Continue</Button></Tooltip>
                                </Box>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Submit Report</StepLabel>
                            <StepContent>
                                {!canSave(steps) ?
                                    <>
                                        Your report is missing required information, or contains errors. Please go back and correct your report{!isAnon && ', or save it and return later to complete it'}.
                                        <Box mt={2} display='flex'>
                                            <Button onClick={prevStep}>Back</Button>
                                            {!isAnon && <Button onClick={() => saveReport()} variant='contained'>Save</Button>}
                                            <Box flexGrow={1}/>
                                            <Button onClick={submitReport} disabled variant='contained'>Submit Report</Button>
                                        </Box>
                                    </>
                                    :
                                    <>
                                        Your report is complete and can be submitted.
                                        By submitting this report, you consent to being contacted by a registry or registrar in the event that additional information is needed to act upon your abuse report.
                                        <Box mt={2} display='flex'>
                                            <Button onClick={prevStep}>Back</Button>
                                            <Box flexGrow={1}/>
                                            <Button onClick={submitReport} variant='contained'>Submit Report</Button>
                                        </Box>
                                    </>
                                }
                            </StepContent>
                        </Step>
                    </Stepper>

                </Grid>
            </Grid>

        </form>
    </>);
};

export const AbuseDefinition = ({ type }) => {
    const language = useContext(LanguageContext);
    const name = abuseNames[type];

    return (<>
        <Typography variant="h6">{language[`report${name}`]} Abuse:</Typography>
        <Typography>{language[`report${name}Text`]}</Typography>
    </>);
};

export const AbuseInformation = ({ steps, mode, showStepNumber=true }) => {
    const language = useContext(LanguageContext);
    const fields = steps.map(({ fields }, idx) => fields.filter(({ optional }) => mode === 'optional' ? optional : !optional ).map(({ description, extra }) => ({ description, extra, step: idx }))).flat().map(({ description, extra, step }, idx) => <li key={idx}>{description} <i>{extra}</i> {showStepNumber && <>(step {step+1})</>}</li>);

    return fields.length ? <>
        <Typography mt={2} variant="h6">{mode === 'optional' ? language.reportHelpful : language.reportRequired}:</Typography>
        <Typography>{mode === 'optional' ? language.reportHelpfulText : language.reportRequiredText}</Typography>
        <ul>{fields}</ul>
    </> : null;
};

export const HelperText = ({ step }) => {
    const fields = step.fields.map(({ label, description }, idx) => <Box mb={1} key={idx}><dt>{label}</dt><dd>{description}</dd></Box>);
    return <dl>{fields}</dl>;
}