export const enrichments = [
    { name: 'rdap', primary: 'RDAP', secondary: 'Domain registration date, status, nameservers' },
    { name: 'dns', primary: 'DNS Lookup', secondary: 'Domain nameservers, IP addresses' },
    { name: 'cleanDns', primary: 'CleanDNS', secondary: 'Known abuse activity' },
    { name: 'safeBrowsing', primary: 'Google SafeBrowsing', secondary: 'Known abuse activity' },
    { name: 'abuseCh', primary: 'Abuse.ch', secondary: 'Known abuse activity' },
    { name: 'phishTank', primary: 'PhishTank', secondary: 'Known phishing activity' },
    { name: 'urlScan', primary: 'URLScan', secondary: 'Known abuse activity' },
    // { name: 'virusTotal', primary: 'Virus Total', secondary: 'Known abuse activity' },
    { name: 'hybridAnalysis', primary: 'Hybrid Analysis', secondary: 'Known abuse activity' },
    { name: 'spamhaus', primary: 'Spamhaus', secondary: 'Known abuse activity' },
    { name: 'surbl', primary: 'SURBL', secondary: 'Known abuse activity' },
];

export const freqLabels = {
    // [1 * 1]: 'minute',
    // [ 5 * 1]: '5 minutes',
    // [10 * 1]: '10 minutes',
    // [15 * 1]: '15 minutes',
    // [20 * 1]: '20 minutes',
    [30 * 1]: '30 minutes',
    [60 * 1]: 'hour',
    [60 * 2]: '2 hours',
    [60 * 3]: '3 hours',
    [60 * 4]: '4 hours',
    [60 * 6]: '6 hours',
    [60 * 8]: '8 hours',
    [60 * 12]: '12 hours',
    [60 * 24]: '24 hours',
};

export const freqMarks = [
    // { value: 1 },
    // { value: 5 },
    // { value: 10 },
    // { value: 15 },
    // { value: 20 },
    { value: 30 },
    { value: 60 * 1 },
    { value: 60 * 2 },
    { value: 60 * 3 },
    { value: 60 * 4, label: '4 hours' },
    { value: 60 * 6 },
    { value: 60 * 8 },
    { value: 60 * 12, label: '12 hours' },
    { value: 60 * 24, label: '24 hours' },
];