import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RealmContext } from '../../../ReactRealmProvider';
import { Autocomplete, Checkbox, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, Switch, TextField, Typography, Tooltip, TextareaAutosize } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DataContext } from '../../../contexts/DataProvider';
import { accountTypes } from '../../../data/accountTypes';
import { Link } from 'react-router-dom';
import { Add, CheckBox, CheckBoxOutlineBlank, Edit, Email, EmailOutlined, Groups, InsertLink, LinkOff } from '@mui/icons-material';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
const pageSizes = [ 20, 50, 100 ];

export const RegistrarsAdmin = () => {
    const { mongo } = useContext(RealmContext);
    const data = useContext(DataContext);
    const [ registrars, setRegistrars ] = useState(data.registrarGroups.map((group) => group.registrars.map((reg) => ({
        ...reg,
        id: reg._id,
        group: { size: group.registrars.length, name: group.name, ianaId: group.ianaId, abuseEmail: group.abuseEmail },
    }))).flat());
    const [ pageSize, setPageSize ] = useState(pageSizes[0]);
    const [ loading, setLoading ] = useState(false);
    const [ group, setGroup ] = useState();
    const groupModal = Boolean(group);

    const updateGroups = useCallback(async (group) => {
        // group.grouped [ { _id, id, ianaId, name, ... }]
        // group.main { _id, id, ianaId, name }
        if (! group.grouped.find((g) => g.ianaId === group.main.ianaId)) group.grouped.push(group.main);
        const ianaIds = group.grouped.map((g) => g.ianaId);

        // update primary registrar
        await mongo.db('cart').collection('registrars').updateOne({
            ianaId: group.main.ianaId,
        }, {
            $set: { name: group.main.name, abuseEmail: group.main.abuseEmail }
        });

        await data.updateData();

        // remove these registrars from any existing groups
        await mongo.db('cart').collection('groupedRegistrars').updateMany({
            'registrars.ianaId': { $in: ianaIds },
        }, {
            $pull: {
                registrars: { ianaId: { $in: ianaIds } },
            }
        });

        // remove any groups headed by these registrars
        await mongo.db('cart').collection('groupedRegistrars').deleteMany({ ianaId: { $in: ianaIds }});

        // create a new group for these registrars
        await mongo.db('cart').collection('groupedRegistrars').insertOne({
            ianaId: group.main.ianaId,
            name: group.main.name,
            registrars: group.grouped,
            abuseEmail: group.main.abuseEmail,
            origins: group.origins || [],
        });

        await mongo.db('cart').collection('groupedRegistrars').find({}, { sort: { name: 1 } }).then((r) => setRegistrars(
            r.map((group) => group.registrars.map((reg) => ({
                ...reg,
                id: reg._id,
                group: { size: group.registrars.length, name: group.name, ianaId: group.ianaId, abuseEmail: group.abuseEmail },
            }))).flat()
        ));

        await data.updateData();
        setGroup();
    }, [data.updateDate, mongo, setGroup]);

    const columns = useMemo(() => ([
        { field: 'ianaId', headerName: 'IANA ID', width: 80 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'groupId', headerName: 'Group ID', width: 80, valueGetter: (params) => params.row.group.size > 1 ? params.row.group.ianaId : '' },
        { field: 'groupEmail', headerName: 'Email', width: 60, renderCell: (params) => params.row.group.abuseEmail ? <Tooltip title={`${params.row.group.abuseEmail} (click to copy)`}><Email onClick={() => navigator.clipboard.writeText(params.row.group.abuseEmail)} color='primary' /></Tooltip> : null },
        { field: 'groupName', headerName: 'Group Name', flex: 1, valueGetter: (params) => params.row.group.size > 1 ? params.row.group.name : '' },
        { field: 'action', headerName: '', width: 40, renderCell: (params) => <IconButton onClick={() => setGroup({
            mainInput: null,
            groupInput: '',
            main: data.registrars.find((r) => r.ianaId === params.row.group.ianaId),
            grouped: data.registrarGroups.find((r) => r.ianaId === params.row.group.ianaId)?.registrars || [],
            origins: data.registrarGroups.find((r) => r.ianaId === params.row.group.ianaId)?.origins || [],
            originsText: data.registrarGroups.find((r) => r.ianaId === params.row.group.ianaId)?.origins?.join("\n") || '',
        })}><Edit/></IconButton> }
    ]), [setGroup, data]);

    return (<>
        <Typography mt={1} variant="h4">Administration: Registrars</Typography>
        {!loading && <DataGrid
            autoHeight
            rows={registrars}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={pageSizes}
            onPageSizeChange={(n) => setPageSize(n)}
            disableSelectionOnClick
            // checkboxSelection
        />}
        <Dialog open={groupModal} onClose={() => setGroup()} fullWidth maxWidth='md'>
            <DialogTitle>Modify Registrar Group</DialogTitle>
            {group && <DialogContent>
                <Autocomplete
                    options={group.mainInput?.length >= 3 ? data.registrars : []}
                    sx={{mt: 1}}
                    fullWidth

                    value={group.main || null}
                    onChange={(event, opt, reason) => setGroup((group) => ({ ...group, main: opt || null, grouped: group.grouped || data.registrarGroups.find((r) => r.ianaId === opt?.ianaId) || [], mainInput: null }))}

                    inputValue={group.mainInput !== null ? group.mainInput : (group.main?.name || '')}
                    onInputChange={(event, value) => setGroup((group) => ({ ...group, mainInput: value }))}

                    getOptionLabel={(opt) => `${opt.name} (IANA ${opt.ianaId})`}
                    renderInput={(params) => <TextField {...params} label="Main Registrar"/>}
                />

                <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    value={group.main.abuseEmail || ''}
                    onChange={(e) => setGroup((group) => ({ ...group, main: { ...group.main, abuseEmail: e.target.value } }))}
                    label="Abuse Email Address"
                    InputLabelProps={{
                        shrink: true
                    }}
                />

                <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    multiline
                    value={group.originsText || ''}
                    onChange={(e) => setGroup((group) => ({ ...group, originsText: e.target.value, origins: e.target.value.trim().split(/\s+/) }))}
                    label="Authorized Embedding Origins"
                    InputLabelProps={{
                        shrink: true
                    }}
                    placeholder="Enter one hostname, domain name, or URL per line."
                    minRows={Math.max(group.origins.length || 0, 3)}
                />

                <Autocomplete
                    options={data.registrars}
                    filterOptions={(options, { inputValue, getOptionLabel }) => {
                        const input = inputValue.toLowerCase();
                        return (inputValue.length < 3 ? options : options.filter((opt) => `${opt.ianaId} ${opt.name}`.toLowerCase().includes(input))).slice(0, 50);
                    }}
                    sx={{mt: 2}}
                    fullWidth
                    multiple

                    value={data.registrars.filter(({ ianaId }) => group.grouped.find((r) => r.ianaId === ianaId))}
                    onChange={(event, opt, reason) => setGroup((group) => ({ ...group, grouped: opt }))}

                    getOptionLabel={(opt) => `${opt.name} (IANA ${opt.ianaId})`}
                    renderInput={(params) => <TextField {...params} label="Grouped Registrars"/>}
                    disableCloseOnSelect={true}

                    renderOption={(props, opt, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {opt.name} (IANA {opt.ianaId})
                        </li>
                    )}
                />
            </DialogContent>}
            <DialogActions>
                <Button onClick={() => setGroup()}>Cancel</Button>
                <Button onClick={() => updateGroups(group)}>Save</Button>
            </DialogActions>
        </Dialog>

    </>);
};
