import {
    Bar,
    BarChart,
    Cell,
    Legend,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import { ChartWrapper } from "./ChartWrapper";

export const BChart = ({ data, truncate = 10, xs, title, height = 300, allowPagination = false, showTable = false, headers, needsDivider, countsField = 'count', xAxisFieldName = '_id', layout = 'horizontal', colors, CustomTooltip = null }) => {

    return (
        <ChartWrapper idField={xAxisFieldName} title={title} xs={xs} height={height} allowPagination={allowPagination} showTable={showTable} data={data} headers={headers} needsDivider={needsDivider}>
            <BarChart
                layout={layout}
                data={truncate ? data.slice(0, truncate) : data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                {layout === 'horizontal' && <XAxis type="category" dataKey={xAxisFieldName} />}
                {layout === 'horizontal' && <YAxis type="number" interval={0} />}
                {layout === 'vertical' && <YAxis type="category" dataKey={xAxisFieldName} width={250} interval={0} />}
                {layout === 'vertical' && <XAxis type="number" interval={0} />}
                {CustomTooltip ? <Tooltip content={<CustomTooltip data={data} colors={colors} />} /> : <Tooltip />}
                <Legend align="center" content={<div> {countsField} </div>}/>
                <Bar dataKey={"incidents"} >
                    {data.map((entry, index) => <Cell cursor="pointer" fill={colors[index]} key={`cell-${index}`} />)}
                </Bar>
            </BarChart>
        </ChartWrapper>
    )
};

export default BChart;