import { useState, useMemo, useContext, useEffect } from "react";
import {
  Box,
  LinearProgress,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { RealmContext } from "../ReactRealmProvider";

/**
 * Component to display all new users in a table.
 * By default, if a start date and end date are not provided,
 * only new users from the last week will be displayed.
 * @param {String[]} additionalFields - Additional fields to display in the table.
 * @param {Date} startDate - Start date for the query.
 * @param {Date} endDate - End date for the query.
 * @returns {Object[]} users - Array of user objects.
 */
export const NewUsersTable = ({
  additionalFields = [],
  startDate = undefined,
  endDate = undefined,
}) => {
  const { callFunction } = useContext(RealmContext);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [headers, setHeaders] = useState([]);

  // [[copilot]]
  useEffect(() => {
    setLoading(true);
    callFunction("getNewUsers", {
      fields: additionalFields,
      startDate,
      endDate,
    })
      .then((r) => {
        setUsers(r);
        // detect header fields from data
        // use a Set to produce a deduplicated array of keys
        // from objects in data
        // this might be a bit excessive if we can guarantee the shape
        // of the user objects, but if a field might be omitted by chance
        // from the first user object, we cannot rely on a simple `Object.keys(users[0])`
        const keys = new Set();
        r.forEach((user) => {
          Object.keys(user).forEach((key) => {
            keys.add(key);
          });
        });
        const h = Array.from(keys);
        setHeaders(h);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  // [[/copilot]]

  const columns = useMemo(() => {
    return headers.map((header) => {
      return {
        field: header,
        flex: 1,
        minWidth: calculateWidth(header),
        sortable: false,
      };
    });
  }, [headers, users]);

  // page size state for pagination
  const [pageSize, setPageSize] = useState(10);
  // page state for pagination
  const [page, setPage] = useState(0);

  return (
      <DataGrid
        autoHeight
        style={{ width: "100%", marginTop: '1rem' , marginLeft: '2rem' }}
        columns={columns}
        page={page}
        rows={users}
        components={{Toolbar: GridToolbar, LoadingOverlay: LinearProgress}}
        loading={loading}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPage(newPage)}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        getRowId={(row) => row["_id"]?.toString()}
        autosizeOptions={{
          columns,
          includeOutliers: true,
          includeHeaders: true,
        }}
      />
  );

  /**
   * Used to calculate the minimum width of a column based on the data and header
   * @param {string} field
   * @returns
   */
  function calculateWidth(field) {
    // for the field, find the longest value
    // in the data and calculate the width
    // based on the length of the string of that value
    const values = users.map((user) => String(user[field] || ""));
    if(values.length === 0) {
      return null;
    }

    const longestValue = values.reduce((a, b) => {
      return a.length > b.length ? a : b;
    });

    // if the header is longer than the longest value
    // use the header length instead
    const longest = longestValue.length > field.length ? longestValue : field;

    return String(longest).length * 10 + 25;
  }
};
