import { useState, useContext, useMemo } from "react";
import { FilterContext } from "./ControlsWrapper";
import {
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  Menu,
} from "@mui/material";
import { useDashboardAggregation } from "../../../hooks/useDashboardAggregations";
import { PieChart } from "../../../components/charts";


export const AbuseTypesByTldAndRegistrar = () => {
  const [selectedRegistrar, setSelectedRegistrar] = useState();
  const [selectedTld, setSelectedTld] = useState();

  // const options = useContext(FilterContext);
  const { data, loading, colors } = useDashboardAggregation({
    aggregation: "incidentsByAbuseTypeAndTldAndRegistrar",
  });

  const chartData = useMemo(() => {
    // data is actually an array of one object
    const dataSources = data[0];
    if (!dataSources) {
      return [];
    }

    const {
      byAbuseType,
      byAbuseTypeAndRegistrar,
      byAbuseTypeAndTld,
      byAbuseTypeAndTldAndRegistrar,
      registrars,
      tlds,
    } = dataSources;

    if (selectedRegistrar && selectedTld) {
      return byAbuseTypeAndTldAndRegistrar.filter(doc => doc.tld === selectedTld && doc.registrar === selectedRegistrar);
    } else if (selectedRegistrar) {
      return byAbuseTypeAndRegistrar.filter(doc => doc.registrar === selectedRegistrar);
    } else if (selectedTld) {
      return byAbuseTypeAndTld.filter(doc => doc.tld === selectedTld);
    } else {
      return byAbuseType;
    }
  });

  const selectOptions = useMemo(() => {
    const dataSources = data[0];
    if (!dataSources) {
      return [];
    }

    const {
      registrars,
      tlds,
    } = dataSources;

    return {
        registrars: registrars || [],
        tlds: tlds || [],
    }
  });

  if (loading) {
    return null;
  }

  return (
    <Box width="100%" padding={3} minWidth={400}>
        <Box
          paddingTop={5}
          display="flex"
          gap={1}
          justifyContent="flex-start"
          width="100%"
        >
        <Box minWidth={320}>
            <SelectField
                id="registry-registrar"
                label={"Registrar"}
                options={selectOptions.registrars}
                value={selectedRegistrar}
                onChange={(e) => setSelectedRegistrar(e.target.value)}
            />
        </Box>
        <Box minWidth={180}>
            <SelectField
                id="registry-tld"
                label={"TLD"}
                options={selectOptions.tlds}
                value={selectedTld}
                onChange={(e) => setSelectedTld(e.target.value)}
            />
        </Box>
      </Box>
      <Box fullWidth justifyContent="flex-end">
        <PieChart
            colors={colors}
            loading={loading}
            data={chartData}
            title={"Abuse Types by TLD and Registrar"}
            xs={12}
            height={400}
        />
      </Box>
    </Box>
  );
};

/**
 * Select component to choose from TLDs or registrars in data
 */
const SelectField = ({ id, options, value, onChange, label }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select id={id} labelId={`${id}-label`} value={value} onChange={onChange} label={label}>
        <MenuItem value={null}>All</MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
