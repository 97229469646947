import React, { useCallback, useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton, Autocomplete } from '@mui/material';
import { template } from '../../data/template';
import { MyLocation, Pending } from '@mui/icons-material';

const googleGeocodingApiKey = process.env.REACT_APP_GOOGLE_GEOCODING_APIKEY;

const geoOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

export const LocationText = ({ value, setValue, config, report, onEnter }) => {
    const isOptional = config.optional || !config.okay;
    const isConditional = config.optionalWhen;
    const isOkay = !config.okay || config.okay(value);
    const [ geoRunning, setGeoRunning ] = useState(false);
    const [ options, setOptions ] = useState([]);
    // const [ displayValue, setDisplayValue ] = useState('');
    const Adornment = geoRunning ? Pending : MyLocation;

    const onGeo = useCallback(({ coords: { latitude, longitude } }) => {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleGeocodingApiKey}`).then((r) => r.json()).then((r) => {
            const places = r.results.filter((p) => p.types.includes('political'));
            setOptions(places.map(({ formatted_address }) => formatted_address));
            setValue(places[0].formatted_address);
            setGeoRunning(false);
        })
    }, []);

    // useEffect(() => {
    //     setDisplayValue(value)
    // }, [value]);

    const onGeoFail = useCallback((error) => {
        console.error(error);
        setGeoRunning(false);
    }, []);

    // provide blank value on first render
    useEffect(() => setValue(value), []);

    return <>
        <Autocomplete
            freeSolo
            sx={{ marginTop: '0.5em', marginBottom: '1em' }}
            fullWidth
            required={isConditional ? isConditional(report) : !isOptional}
            options={options}
            disabled={geoRunning}
            value={value}
            onChange={(e, value) => setValue(value)}
            inputValue={value}
            onInputChange={(e, value) => setValue(value)}
            // inputValue={displayValue}
            // onInputChange={(e, value) => setDisplayValue(value)}
            error={!isOkay ? 'error' : ''}
            renderInput={(params) => <TextField
                {...params}
                onKeyDown={(e) => e.code === 'Enter' && onEnter?.()}
                label={`${config.label}${isOptional ? " (Optional)" : ''}`}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: <>
                        <InputAdornment position="end">
                            <IconButton edge="end" onClick={geoRunning ? () => null : () => {
                                setGeoRunning(true);
                                navigator.geolocation.getCurrentPosition(onGeo, onGeoFail, geoOptions);
                            }}><Adornment/></IconButton>
                        </InputAdornment>
                        {params.InputProps.endAdornment}
                    </>
                }}
            />}
        />
    </>;
};
