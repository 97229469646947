import React, { useContext, useEffect, useState } from 'react';
import { RealmContext } from '../../ReactRealmProvider';
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Button, Grid, IconButton, ImageList, ImageListItem, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BreakPointCard } from '../../components/BreakPointCard';
import { AbuseReportSummary } from '../../components/AbuseReportSummary';
import { useUserRole } from '../../hooks/useUserRole';
import { injectBreakPoints } from '../../utils/injectBreakPoints';
import { sorters } from '../../data/sorters';
import { downloadEvidence } from '../../utils/downloadEvidence';
import { DataGrid } from '@mui/x-data-grid';
import { BSON } from 'realm-web';
import { abuseNames } from '../../data/abuseNames';
import { Check, Edit } from '@mui/icons-material';
import { DateTime } from '../../components/DateTime';

export const ReportHistory = () => {
    const { user, mongo } = useContext(RealmContext);
    const isViewer = useUserRole('viewer');
    const history = useHistory();

    const columns = [
        { field: 'submit', headerName: '', width: 60, renderCell: (params) => <IconButton>{params.row.submit ? <Check/> : <Edit/>}</IconButton> },
        { field: 'id', headerName: 'ID', width: 80, valueGetter: (params) => params.row.id.toHexString().substr(-6) },
        { field: 'type', headerName: 'Abuse Type', width: 120, valueGetter: (params) => abuseNames[params.row.type] },
        { field: 'tsReported', headerName: 'Date Reported', width: 200, renderCell: (params) => {
            return <DateTime timestamp={params.row.tsReported || (params.row.tsSaved && params.row.tsSaved[params.row.tsSaved.length - 1])}/>;
        } },
        { field: 'date.date', headerName: 'Abuse Date', width: 200, valueGetter: (params) => params.row.date.date, renderCell: (params) => <DateTime timestamp={params.row.date.date} dateOnly /> },
        { field: 'url', headerName: 'URL(s)', width: 480 },
    ];    
    
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortHow, setSortHow] = useState('byDateByDomain');

    const pageSizes = [ 20, 50, 100 ];
    const [ pageSize, setPageSize ] = useState(pageSizes[0]);

    const orderedReports = reports; // injectBreakPoints(reports.sort(sorters[sortHow].func), sorters[sortHow].field);

    useEffect(() => {
        if (!isViewer) {
            return;
        }

        // Realm rules take care of this :)
        mongo.db('cart').collection('reports').find().then((r) => {
            setReports(r.map((el) => ({ id: el._id, ...el })));
            setLoading(false);
        });

    }, [isViewer]);

    if (loading) {
        return "...";
    }

    return (<>
        <Switch>
            <Route path="/account/reports/view/:id" component={AbuseReport}/>
            <Route path="/account/reports/edit/:id" component={EditAbuseReport}/>
            <Route>
                <Typography variant="h4" mb={1}>My Reports</Typography>

                <DataGrid
                    autoHeight
                    rows={reports}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={pageSizes}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    disableSelectionOnClick
                    
                    onRowClick={(params) => {
                        history.push(`/account/reports/${params.row.submit ? 'view' : 'edit'}/${params.row.showId}`)
                    }}
                />

                {/*<Grid container spacing={2} alignItems="stretch">
                    {isViewer && !loading && reports.length === 0 && "No abuse reports found."}
                    {isViewer && !loading && reports.length > 0 && orderedReports.map((r) => <Grid key={r.label || r._id} item xs={6}>{r.label ? <BreakPointCard breakpoint={r} /> : <AbuseReportSummary report={r} />}</Grid>)}
                </Grid>*/}

            </Route>
        </Switch>
    </>);
};


const AbuseReport = ({ history, location, match }) => {
    const { user, mongo } = useContext(RealmContext);
    const [report, setReport] = useState();
    const [evidence, setEvidence] = useState();
    const id = match.params.id;

    useEffect(() => {
        mongo.db('cart').collection('reports').findOne({ showId: id }).then((r) => {
            setReport(r);
            downloadEvidence(r.attachments || []).then((files) => setEvidence(files));
        });
    }, [id]);

    return (<>
        <div><Button component={Link} to="/account/reports">&lt; Back to My Reports</Button></div>
        {report && <>
            <Grid container spacing={2} columns={16}>
                <Grid item xs>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abusive URL</Typography>
                    <Typography variant='h5'>{report.url}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Type</Typography>
                            <Typography variant='h6'>{abuseNames[report.type]}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Date</Typography>
                            <Typography variant='h6'>{report.date.date.toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Ongoing</Typography>
                            <Typography variant='h6'>{report.date.ongoing ? 'Yes' : 'No'}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Report ID</Typography>
                    <Typography fontSize='smaller'>{report.showId}</Typography>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Reported At</Typography>
                    <Typography fontSize='smaller'>{report.tsReported.toLocaleString()}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Description</Typography>
                    <Typography>{report.description}</Typography>
                </Grid>

                {report.type === 'phishing' && <>
                    <Grid item xs>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Phishing Target</Typography>
                        <Typography>{report.target}</Typography>
                    </Grid>

                    {report.email && <Grid item xs>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Received From</Typography>
                        <Typography>{report.email}</Typography>
                    </Grid>}
                </>}

                {report.type === 'malware' && <>
                    <Grid item xs={3}>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Suspicious Filename</Typography>
                        <Typography>{report.filename}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Malware Name</Typography>
                        <Typography>{report.malware}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>MD5 Checksum</Typography>
                        <Typography>{report.md5}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>SHA1 Checksum</Typography>
                        <Typography>{report.sha1}</Typography>
                    </Grid>
                </>}

                {report.type === 'spam' && <>
                    <Grid item xs>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Received From</Typography>
                        <Typography>{report.email}</Typography>
                    </Grid>
                </>}

                {report.type === 'botnets' && <>
                    <Grid item xs>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Botnet Name</Typography>
                        <Typography>{report.botnet}</Typography>
                    </Grid>
                </>}
            </Grid>

            {(report.headers || report.body) && <Grid container spacing={2}>
                <Grid item xs>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Message Headers</Typography>
                    <TextField multiline readonly fullWidth inputProps={{style: { fontSize: '10pt'}}} rows={10} value={report.headers}/>
                </Grid>
                <Grid item xs>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Message Body</Typography>
                    <TextField multiline readonly fullWidth inputProps={{style: { fontSize: '10pt'}}} rows={10} value={report.body}/>
                </Grid>
            </Grid>}

            {evidence && <Grid container spacing={2}>
                <Grid item xs>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Supporting Evidence ({evidence.length} file{evidence.length === 1 ? '' : 's'})</Typography>
                    <ImageList cols={4} rowHeight={256}>
                        {evidence.map((file) => <ImageListItem key={file.key}><img loading="lazy" src={file.url} /></ImageListItem>)}
                    </ImageList>
                </Grid>
            </Grid>}
        </>}
        {/* <xmp>{JSON.stringify(report, null, 4)}</xmp> */}
    </>);
};

const EditAbuseReport = ({ match }) => {
    const [ response, setResponse ] = useState('Loading report...');
    const { mongo } = useContext(RealmContext);

    useEffect(() => {
        mongo.db('cart').collection('reports').findOne({ showId: match.params.id }).then((r) => {
            setResponse(r ? <Redirect to={`/new-report/${r.type}/${r.showId}`}/> : <>Report {match.params.id} not found.</>);
        });    
    }, [match.params?.id]);

    return response;
}