export const template = {
    actor: {
        placeholder: "http://www.example.com/path/to/page.html",
    },
    url: {
        placeholder: "http://www.example.com/path/to/page.html",
        okay: "This looks like a valid URL",
        notOkay: "This does not look like a valid URL",
    },
    urls: {
        placeholder: "http://www.example.com/path/to/page.html",
        okay: "This looks like a valid URL",
        notOkay: "This does not look like a valid URL",
    },
    email: {
        placeholder: "username@emailservice.com",
        okay: "This looks like a valid email address",
        notOkay: "This does not look like a valid email address",
    },
};
