import { s3init } from "./storage";
import { promisify } from 'util';

export const uploadEvidence = async (reportId, evidence) => {
    const accessKeyId = process.env.REACT_APP_HMAC_KEY;
    const secretAccessKey = process.env.REACT_APP_HMAC_SECRET;
    const storage = s3init({ accessKeyId, secretAccessKey });
    const putObject = promisify(storage.putObject.bind(storage));

    const ids = [];

    for (const { file } of evidence) {
        const { lastModified, name, type, size } = file;
        const Key = `${reportId}/${name}`;

        await putObject({ Bucket: 'cart-evidence', Key, Body: file }).then(() => {
            ids.push({ name, type, size, lastModified, key: Key });
        }).catch((error) => {
            console.error(error);
        });
    }

    return ids;
};