export const apiSpecs = {
    openapi: "3.0.0",
    servers: [
        process.env.REACT_APP_ENVIRONMENT === 'development' ?
            { url: 'https://api-dev.netbeacon.org', description: 'NetBeacon Development API' } :
            { url: 'https://api.netbeacon.org', description: 'NetBeacon Production API' }
    ],
    components: {
        schemas: {
            Reports: {
                oneOf: [
                    {
                        type: 'array',
                        items: {
                            $ref: '#/components/schemas/Report',
                        },
                        minItems: 1,
                    },
                    {
                        $ref: '#/components/schemas/Report',
                    },
                ],
            },
            Report: {
                allOf: [
                    {
                        required: [
                            'type',
                            'date',
                            'url',
                            // 'geo',
                            'description',
                            'attachments',
                        ],
                        properties: {
                            type: {
                                type: 'string',
                                enum: [ 'spam', 'malware', 'botnets', 'phishing' ],
                                description: 'The type of abuse being reported',
                            },
                            date: {
                                type: 'string',
                                format: 'date-time',
                                description: 'The date on which the abuse was detected',
                            },
                            ongoing: {
                                type: 'boolean',
                                default: true,
                                description: 'Whether the abuse is still ongoing',
                            },
                            url: {
                                type: 'string',
                                format: 'uri',
                                description: 'The web site where the abuse is occurring',
                            },
                            geo: {
                                type: 'string',
                                description: 'Your geographical location at the time',
                            },
                            description: {
                                type: 'string',
                                description: 'A brief description of the abusive activity',
                            },
                            attachments: {
                                type: 'array',
                                description: 'Any image files (e.g. screenshots) that can serve as additional evidence of the abuse',
                                items: {
                                    $ref: '#/components/schemas/Attachment',
                                },
                            },
                        },
                    },
                    {
                        oneOf: [
                            { $ref: '#/components/schemas/SpamReport' },
                            { $ref: '#/components/schemas/MalwareReport' },
                            { $ref: '#/components/schemas/PhishingReport' },
                            { $ref: '#/components/schemas/BotnetsReport' },
                        ]
                    },
                ],
                type: 'object',
            },
            SpamReport: {
                type: 'object',
                required: [
                    'email',
                    // 'headers',
                    // 'body',
                ],
                properties: {
                    email: {
                        type: 'string',
                        format: 'email',
                        description: 'The email address that sent the spam message',
                    },
                    headers: {
                        type: 'string',
                        description: 'The complete email headers',
                    },
                    body: {
                        type: 'string',
                        description: 'The complete email message body',
                    },
                },
            },
            MalwareReport: {
                type: 'object',
                required: [
                    'filename',
                ],
                properties: {
                    filename: {
                        type: 'string',
                        format: 'filenmae',
                        description: 'The filename of the malware or the file containing the virus',
                    },
                    malware: {
                        type: 'string',
                        description: 'The name of the malware or virus',
                    },
                    md5: {
                        type: 'string',
                        description: 'The MD5 checksum of the malware file',
                    },
                    sha1: {
                        type: 'string',
                        description: 'The SHA1 checksum of the malware file',
                    },
                },
            },
            PhishingReport: {
                type: 'object',
                required: [
                    'target',
                    // 'email',
                    // 'headers',
                    // 'body',
                ],
                properties: {
                    target: {
                        type: 'string',
                        description: 'The name of the company being impersonated',
                    },
                    email: {
                        type: 'string',
                        format: 'email',
                        description: 'The email address that sent the phishing message',
                    },
                    headers: {
                        type: 'string',
                        description: 'The complete email headers',
                    },
                    body: {
                        type: 'string',
                        description: 'The complete email message body',
                    },
                },
            },
            BotnetsReport: {
                type: 'object',
                properties: {
                    botnet: {
                        type: 'string',
                        description: 'The name of the botnet',
                        example: 'WireX',
                    },
                },
            },
            Attachment: {
                type: 'object',
                required: [
                    'data',
                    'name',
                ],
                properties: {
                    data: {
                        type: 'string',
                        format: 'byte',
                        description: 'The contents of the attachment, base64-encoded (note: currently, only images may be attachments)',
                    },
                    name: {
                        type: 'string',
                        format: 'filename',
                        description: 'The filename of the attachment',
                    },
                },
            },
        },
    },
    paths: {
        '/submit/reports': {
            post: {
                tags: [ 'provider' ],
                summmary: 'Submits one or more abuse reports for processing',
                operationId: 'postSubmitReports',
                requestBody: {
                    required: true,
                    content: {
                        'application/json': {
                            schema: {
                                $ref: '#/components/schemas/Reports',
                            },
                        },
                    },
                },
                responses: {
                    '200': {
                        description: 'OK',
                        content: {
                            'application/json': {
                                schema: {
                                    type: 'object',
                                },
                            },
                        },
                    },
                },
            },
        },
        '/receive/incidents': {
            get: {
                tags: [ 'consumer' ],
                summmary: 'Gets abuse incidents for the authenticated client',
                operationId: 'getReceiveIncidents',
                parameters: [
                    {
                        in: 'query',
                        name: 'limit',
                        schema: {
                            type: 'integer',
                            minimum: 1,
                            maximum: 25,
                        },
                        required: false,
                        description: 'The maximum number of abuse incidents to return',
                        example: 5,
                        default: 25,
                    },
                    {
                        in: 'query',
                        name: 'since',
                        schema: {
                            type: 'string',
                            format: 'date-time',
                        },
                        required: false,
                        description: 'The earliest date (inclusive) for a returned abuse incident',
                        example: '2022-06-01T12:00:00Z',
                    },
                    {
                        in: 'query',
                        name: 'until',
                        schema: {
                            type: 'string',
                            format: 'date-time',
                        },
                        required: false,
                        description: 'The most recent date (inclusive) for a returned abuse incident',
                        example: '2022-06-02T12:00:00Z',
                    },
                ],
                responses: {
                    '200': {
                        description: 'OK',
                        content: {
                            'application/json': {
                                schema: {
                                    type: 'object',
                                },
                            }
                        }
                    }
                }
            }
        }
    },
};