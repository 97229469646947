import React, { useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import Check from '@mui/icons-material/Check';
import Warning from '@mui/icons-material/Warning';
import { template } from '../../data/template';

export const SingleText = ({ value, setValue, config, report, onEnter, ...props }) => {
    const isOptional = config.optional || !config.okay;
    const isConditional = config.optionalWhen;
    const isOkay = !config.okay || config.okay(value);
    const [Adornment, adornmentColor, adornmentTitle] = isOkay ? [Check, 'success', 'okay'] : [Warning, 'warning', 'notOkay'];

    // provide blank value on first render
    useEffect(() => setValue(value), []);

    return <>
        <TextField
            sx={{ marginTop: '0.5em', marginBottom: '1em' }}
            multiline={config.input === 'textarea'}
            rows={6}
            fullWidth
            required={isConditional ? isConditional(report) : !isOptional}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => config.input !== 'textarea' && e.code === 'Enter' && onEnter?.(value)}
            type={config.type}
            label={`${config.label}${isOptional ? " (Optional)" : ''}`}
            placeholder={template[config.field]?.placeholder || ''}
            error={!isOkay}
            InputLabelProps={{
                shrink: true
            }}
            InputProps={{
                endAdornment: <>
                    {template[config.field] && (!isOptional || value !== '') && <InputAdornment position="end">
                        <Adornment title={template[config.field][adornmentTitle]} color={adornmentColor} />
                    </InputAdornment>}
                </>,
            }}
            {...props}
        />
    </>;
};
