import { useContext, useEffect } from 'react';
import { RealmContext } from '../ReactRealmProvider';
import { useEmbedded } from './useEmbedded';

export const useUserRole = (role) => {
    const { isEmbedded } = useEmbedded();
    const { user, mongo, customData, refreshCustomData } = useContext(RealmContext);
    const okay = isEmbedded || (customData.role === role);

    useEffect(() => {
        if (! okay) {
            console.log(`switching from ${customData.role} to ${role}`);
            mongo.db('cart').collection('users').updateOne({ realmId: user.id }, { $set: { role } }).then(() => {
                console.log('role updated');
                refreshCustomData();
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [okay, isEmbedded]);

    return okay;
};
