import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RealmContext } from "../ReactRealmProvider";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Stack,
  Switch as Toggle,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  FormControlLabel,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import { useUserRole } from "../hooks/useUserRole";
import { DataGridPro as DGP, GridToolbar } from "@mui/x-data-grid-pro";
import { DataGrid as DG } from "@mui/x-data-grid";
import { downloadEvidence } from "../utils/downloadEvidence";
import { BSON } from "realm-web";
import { abuseNames } from "../data/abuseNames";
import { spamhaus } from "../data/spamhaus";
import { surbl } from "../data/surbl";
import { DateTime } from "../components/DateTime";
import {
  ThumbUp,
  ThumbDown,
  Done,
  Pending,
  HelpOutline,
  Help,
} from "@mui/icons-material";
import { LanguageContext } from "../contexts/LanguageProvider";
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('3b9359f42c368a627706a740023dfbedTz04OTE3OSxFPTE3NDU2OTE5ODIwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

const DataGrid = DGP;

// const virusTotal = <svg xmlns="http://www.w3.org/2000/svg"  version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" ><polygon points="80.2,94 98.7,75.1 98.7,6 89.3,22.3 89.3,84.6 23.5,84.6 58.3,50 23.5,15.4 82.3,15.4 98.7,6 98.7,6 1.3,6 45.4,50  1.3,94 " fill="#394EFF"></polygon></svg>;

export const Incidents = () => {
  const language = useContext(LanguageContext);
  const { mongo, customData } = useContext(RealmContext);
  const isViewer = useUserRole(
    customData.type === "admin" ? "manager" : "viewer"
  );

  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);

  const pageSizes = [20, 50, 100];
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [total, setTotal] = useState(0);

  // const orderedIncidents = incidents; // injectBreakPoints(incidents.sort(sorters[sortHow].func), sorters[sortHow].field);

  // calculate the total once
  useEffect(() => {
    let total = 0;
    let active = true;

    if (!isViewer) {
      return;
    }

    if (total === 0) {
      mongo
        .db("cart")
        .collection("incidents")
        .count()
        .then((r) => setTotal(total = r));
    }

    function daysByTotal (n) {
      if (! n) return 5;
      if (n < 100) return 30;
      if (n < 1000) return 20;
      if (n < 10000) return 10;
      return 5;
    }

    async function loadIncidents() {
      setLoading(true);
      setIncidents([]);

      let found = 0;
      let maxId = new BSON.ObjectId();
      const minId = await mongo
        .db("cart")
        .collection("incidents")
        .findOne({}, { projection: { _id: 1 }, sort: { _id: 1 } })
        .then((r) => (r ? r._id : new BSON.ObjectId(maxId)));


      while (active && maxId > minId) {
        const localMinId = BSON.ObjectId.createFromTime(
          maxId.getTimestamp() / 1000 - 86400 * daysByTotal(total)
        );

        // Realm rules take care of this :)
        await mongo
          .db("cart")
          .collection("incidents")
          .aggregate([
            {
              $match: {
                _id: { $lt: maxId, $gte: localMinId },
              },
            },
            {
              $project: {
                id: { $toString: "$_id" },
                closed: 1,
                _enhanced: 1,
                abuse: 1,
                tsReported: 1,
                reporter: 1,
                "details.rdap": 1,
                "details.tld": 1,
                "details.domain": 1,
                _sent: 1,
                externalTicketId: 1,
                _downloaded: 1,
              },
            },
            { $lookup: {
              from: "incidentScore",
              localField: "id",
              foreignField: "incidentId",
              as: "score"
            } },
            { $addFields: {
              score: { $arrayElemAt: ["$score.score", 0] },
              closed: { $arrayElemAt: ["$score.closed", 0] }
            } },
            { $sort: { _id: -1 } },
            { $limit: 100 },
          ]).then((r) => {
            if (! active) return;

            found += r.length;
            if (r.length) {
              setIncidents((prev) => ([ ...prev, ...r ]));
              setLoading(false);

              // if (! document.getElementById("incidents-progress")) {
              //   const parent = document.getElementsByClassName("MuiDataGrid-footerContainer")[0];
              //   parent.prepend(<div id="incidents-progress" style={{position: 'absolute', top: 0, left: 0, right: 0, height: '2px', backgroundColor: 'rgba(0, 0, 0, 0.1)'}}></div>);
              // }
            }
            maxId = localMinId;
            active = false;
          });      
      }
    }

    loadIncidents();

    return () => {
      active = false;
    };
  }, [isViewer]);

  const columns = useMemo(
    () =>
      [
        // { field: 'id', headerName: 'ID', width: 240 },
        {
          field: "score",
          renderHeader: () => (
            <DataGridHeader tooltip={language.tooltipIncidentScore}>
              {language.incidentScore}
            </DataGridHeader>
          ),
          width: 105,
          align: "center",
          valueGetter: (cell, params) => (params.score > 0 ? "Good" : (params.score < 0 ? "Bad" : "Neutral")),
          renderCell: (params) => {
            if (params.row.score === 1) return <ThumbUp color="success" />;
            else if (params.row.score === -1)
              return <ThumbDown color="error" />;
            return null;
          },
        },
        {
          field: "closed",
          renderHeader: () => (
            <DataGridHeader tooltip={language.tooltipIncidentStatus}>
              {language.incidentStatus}
            </DataGridHeader>
          ),
          width: 115,
          align: "center",
          valueGetter: (cell, params) => params.closed ? "Closed" : (!params._enhanced ? "Pending" : "Open"),
          renderCell: (params) => {
            return params.row.closed ? (
              <Done color="success" />
            ) : !params.row._enhanced ? (
              <Pending />
            ) : (
              ""
            );
          },
        },
        {
          field: "type",
          renderHeader: () => (
            <DataGridHeader tooltip={language.tooltipIncidentType}>
              {language.incidentType}
            </DataGridHeader>
          ),
          width: 145,
          valueGetter: (cell, params) => abuseNames[params.abuse.type],
        },
        {
          field: "tsReported",
          renderHeader: () => language.incidentReported,
          width: 165,
          valueGetter: (cell, params) => params.tsReported,
          renderCell: (params) => (
            <DateTime timestamp={params.row.tsReported} />
          ),
        },
        {
          field: "reporter",
          renderHeader: () => (
            <DataGridHeader tooltip={language.tooltipIncidentReporter}>
              {language.incidentReporter}
            </DataGridHeader>
          ),
          width: 225,
          valueGetter: (cell, params) => params.reporter.email,
        },
        {
          field: "registrar",
          excludeFor: ["registrar"],
          renderHeader: () => (
            <DataGridHeader tooltip={language.tooltipIncidentRegistrar}>
              {language.incidentRegistrar}
            </DataGridHeader>
          ),
          width: 225,
          valueGetter: (cell, params) => params.details?.rdap?.registrar?.name,
        },
        {
          field: "tld",
          renderHeader: () => (
            <DataGridHeader tooltip={language.tooltipIncidentTLD}>
              {language.incidentTLD}
            </DataGridHeader>
          ),
          width: 105,
          valueGetter: (cell, params) => params.details?.tld,
        },
        {
          field: "domain",
          renderHeader: () => (
            <DataGridHeader tooltip={language.tooltipIncidentDomain}>
              {language.incidentDomain}
            </DataGridHeader>
          ),
          width: 225,
          valueGetter: (cell, params) => params.details?.domain,
          renderCell: (params) => {
            return (
              <Link to={`/incidents/view/${params.row.id.toString()}`}>
                {params.row.details?.domain}
              </Link>
            );
          },
        },
        {
          field: "sent",
          renderHeader: () => (
            <DataGridHeader tooltip={language.tooltipIncidentSent}>
              {language.incidentSent}
            </DataGridHeader>
          ),
          with: 150,
          valueGetter: (cell, params) => (params._sent === true && "Emailed") ||
            (params.externalTicketId && "Ticketed") ||
            (params._downloaded && "Via API") ||
            "No",
        },
      ].filter((c) => !c.excludeFor || !c.excludeFor.includes(customData.type)),
    [customData.type, language]
  );

  return (
    <>
      <Switch>
        <Route path="/incidents/view/:id" component={AbuseIncident} />
        <Route>
          <Typography variant="h4" mb={1}>
            {
              { registrar: "Registrar", registry: "Registry", admin: "All" }[
                customData.type
              ]
            }{" "}
            {language.incidents}
          </Typography>
          <Typography mb={2}>{language.incidentsText}</Typography>
          {(total > 0 ? (incidents.length < total) : (incidents.length > 0)) && <LinearProgress variant={total > 0 ? "determinate" : "indeterminate"} value={100 * (incidents.length / (total || 1))} />}
          {!loading && (
            <DataGrid
              // slots={{ toolbar: GridToolbar }}
              autoHeight
              page={page}
              onPageChange={(page, details) => {
                setPage((p) => {
                  console.log(`page: ${p} -> ${page}`);
                  return page;
                });
              }}
              rowCount={total || incidents.length}
              rows={incidents}
              columns={columns}
              loading={loading}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={pageSizes}
              disableSelectionOnClick
              // onRowClick={(params) => {
              //     if (!params._enhanced) {
              //         // no-op
              //     }
              //     else {
              //         history.push(`/incidents/view/${params.id.toString()}`)
              //     }
              // }}
            />
          )}
          {/* <Grid container spacing={2} alignItems="stretch">
                    {isViewer && !loading && incidents.length === 0 && "No abuse incidents found."}
                    {isViewer && !loading && incidents.length > 0 && orderedIncidents.map((r) => <Grid key={r.label || r._id} item xs={3}>{r.label ? <BreakPointCard breakpoint={r} /> : <AbuseIncidentSummary incident={r} />}</Grid>)}
                </Grid> */}
        </Route>
      </Switch>
    </>
  );
};

const userOrg = ({ type, reporterOrg, registrarName, registryName }) => {
  if (type === "admin" || type === "reporter") return reporterOrg;
  if (type === "registrar") return registrarName;
  if (type === "registry") return registryName;
};

const AbuseIncident = ({ history, location, match }) => {
  const { user, callFunction, mongo } = useContext(RealmContext);
  const [settingsId, setSettingsId] = useState();
  const [incident, setIncident] = useState();
  const [evidence, setEvidence] = useState();
  const [score, setScore] = useState(0);
  const [closed, setClosed] = useState(false);
  const id = match.params.id;

  const [reportReferredBy, setReportReferredBy] = useState("");
  const [reportHeader, setReportHeader] = useState("");

  useEffect(() => {
    mongo
      .db("cart")
      .collection("settings")
      .findOne({ type: user.customData.type, name: userOrg(user.customData) })
      .then((r) => {
        setSettingsId(r ? r._id.toString() : "admin");
      });
  }, []);

  // const virusTotalWidget = useCallback(() => {
  //     callFunction('virustotalWidget', incident.actor.url).then((r) => {
  //         window.virusTotalAugmenter.load(r.data.url).openDrawer();
  //     });
  // }, [incident]);

  const updateScore = (score) => {
    setScore(score);
    mongo
      .db("cart")
      .collection("incidentScore")
      .updateOne(
        {
          incidentId: id,
          settingsId,
          type: user.customData.type,
          name: userOrg(user.customData),
        },
        { $set: { score } },
        { upsert: true }
      );
  };

  const updateResolution = (closed) => {
    setClosed(closed);
    mongo
      .db("cart")
      .collection("incidentScore")
      .updateOne(
        {
          incidentId: id,
          settingsId,
          type: user.customData.type,
          name: userOrg(user.customData),
        },
        { $set: { closed } },
        { upsert: true }
      );
  };

  useEffect(() => {
    mongo
      .db("cart")
      .collection("incidents")
      .findOne({ _id: new BSON.ObjectID(id) })
      .then((res) => {
        mongo
          .db("cart")
          .collection("incidentScore")
          .findOne({ incidentId: id })
          .then(async (r) => {
            const reporter = await mongo
              .db("cart")
              .collection("users")
              .findOne({ realmId: res.realmId });
            if (reporter) {
              const accountOrg =
                {
                  admin: reporter.reporterOrg,
                  reporter: reporter.reporterOrg,
                  registrar: reporter.registrarName,
                  registry: reporter.registryName,
                }[reporter.type] || "";
              const settings = await mongo
                .db("cart")
                .collection("settings")
                .findOne({ type: reporter.type, name: accountOrg });
              setReportReferredBy(settings?.reportReferredBy);
              setReportHeader(settings?.reportHeader);
            }
            setScore(r?.score || 0);
            setClosed(r?.closed || false);
            setIncident(res);
          });
        downloadEvidence(
          res.details.screenshot
            ? [res.details.screenshot, ...(res.evidence || [])]
            : res.evidence || []
        ).then((files) => setEvidence(files));
      });
  }, [id]);

  return (
    <>
      <div>
        <Button component={Link} to="/incidents">
          &lt; Back to All Incidents
        </Button>
      </div>
      {incident && (
        <Container>
          <Grid container spacing={2} columns={16}>
            <Grid item xs>
              <Typography
                color="gray"
                variant="h6"
                sx={{ fontVariant: "all-small-caps" }}
              >
                Abusive URL
              </Typography>
              <Typography variant="h5">
                {/* <span style={{cursor: 'pointer', display: 'inline-block', width: '1rem', height: '1rem'}} onClick={() => virusTotalWidget()}>{virusTotal}</span> */}
                {incident.actor.url}
              </Typography>
              {incident.details.rdap?.registrar && (
                <Grid container>
                  <Grid item xs={2}>
                    <Typography
                      color="gray"
                      variant="h6"
                      sx={{ fontVariant: "all-small-caps" }}
                    >
                      IANA ID
                    </Typography>
                    <Typography variant="h6">
                      {incident.details.rdap?.registrar?.id || "n/a"}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      color="gray"
                      variant="h6"
                      sx={{ fontVariant: "all-small-caps" }}
                    >
                      Registrar
                    </Typography>
                    <Typography variant="h6">
                      {incident.details.rdap?.registrar?.name || "n/a"}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Abuse Type
                  </Typography>
                  <Typography variant="h6">
                    {abuseNames[incident.abuse.type]}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Abuse Date
                  </Typography>
                  <Typography variant="h6">
                    {incident.abuse.date.toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Abuse Ongoing
                  </Typography>
                  <Typography variant="h6">
                    {incident.abuse.ongoing ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography
                color="gray"
                variant="h6"
                sx={{ fontVariant: "all-small-caps" }}
              >
                Incident ID
              </Typography>
              <Typography fontSize="smaller">
                {incident._id.toString()}
              </Typography>
              {incident.externalTicketId && (
                <>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    External Ticket ID
                  </Typography>
                  <Typography fontSize="smaller">
                    {incident.externalTicketId}
                  </Typography>
                </>
              )}
              <Typography
                color="gray"
                variant="h6"
                sx={{ fontVariant: "all-small-caps" }}
              >
                Report ID
              </Typography>
              <Typography fontSize="smaller">
                <Link to={`/reports/view/${incident.reportId.toString()}`}>
                  {incident.reportId.toString()}
                </Link>
              </Typography>
              <Typography
                color="gray"
                variant="h6"
                sx={{ fontVariant: "all-small-caps" }}
              >
                Reported By
              </Typography>
              <Typography fontSize="smaller">
                {incident.reporter.name}
              </Typography>
              <Typography fontSize="smaller">
                {incident.reporter.email}
              </Typography>
              {reportReferredBy && (
                <Typography fontSize="smaller">{reportReferredBy}</Typography>
              )}
              <Typography
                color="gray"
                variant="h6"
                sx={{ fontVariant: "all-small-caps" }}
              >
                Reported At
              </Typography>
              <Typography fontSize="smaller">
                {incident.tsReported.toLocaleString()}
              </Typography>
              <div>
                <ToggleButtonGroup
                  exclusive
                  value={score}
                  onChange={(event, score) => updateScore(score)}
                >
                  <ToggleButton value={-1}>
                    <ThumbDown color={score === -1 ? "error" : "default"} />
                  </ToggleButton>
                  <ToggleButton value={1}>
                    <ThumbUp color={score === 1 ? "success" : "default"} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Toggle
                      checked={closed}
                      onChange={() => updateResolution(!closed)}
                    />
                  }
                  label="Closed"
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            {reportHeader && (
              <Grid item xs={12}>
                <Typography
                  color="gray"
                  variant="h6"
                  sx={{ fontVariant: "all-small-caps" }}
                >
                  Reporter Header
                </Typography>
                <Typography sx={{ whiteSpace: "pre-line" }}>
                  {reportHeader}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography
                color="gray"
                variant="h6"
                sx={{ fontVariant: "all-small-caps" }}
              >
                Abuse Description
              </Typography>
              <Typography>{incident.abuse.description}</Typography>
            </Grid>

            {incident.abuse.type === "phishing" && (
              <>
                <Grid item xs>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Phishing Target
                  </Typography>
                  <Typography>{incident.abuse.target}</Typography>
                </Grid>

                {incident.abuse.email && (
                  <Grid item xs>
                    <Typography
                      color="gray"
                      variant="h6"
                      sx={{ fontVariant: "all-small-caps" }}
                    >
                      Received From
                    </Typography>
                    <Typography>{incident.abuse.email}</Typography>
                  </Grid>
                )}
              </>
            )}

            {incident.abuse.type === "malware" && (
              <>
                <Grid item xs={3}>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Suspicious Filename
                  </Typography>
                  <Typography>{incident.abuse.filename}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Malware Name
                  </Typography>
                  <Typography>{incident.abuse.malware}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    MD5 Checksum
                  </Typography>
                  <Typography>{incident.abuse.md5}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    SHA1 Checksum
                  </Typography>
                  <Typography>{incident.abuse.sha1}</Typography>
                </Grid>
              </>
            )}

            {incident.abuse.type === "spam" && (
              <>
                <Grid item xs>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Received From
                  </Typography>
                  <Typography>{incident.abuse.email}</Typography>
                </Grid>
              </>
            )}

            {incident.abuse.type === "botnets" && (
              <>
                <Grid item xs>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Botnet Name
                  </Typography>
                  <Typography>{incident.abuse.botnet}</Typography>
                </Grid>
              </>
            )}
          </Grid>

          {(incident.abuse.headers || incident.abuse.body) && (
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography
                  color="gray"
                  variant="h6"
                  sx={{ fontVariant: "all-small-caps" }}
                >
                  Message Headers
                </Typography>
                <TextField
                  multiline
                  readOnly
                  fullWidth
                  inputProps={{ style: { fontSize: "10pt" } }}
                  rows={10}
                  value={incident.abuse.headers}
                />
              </Grid>
              <Grid item xs>
                <Typography
                  color="gray"
                  variant="h6"
                  sx={{ fontVariant: "all-small-caps" }}
                >
                  Message Body
                </Typography>
                <TextField
                  multiline
                  readOnly
                  fullWidth
                  inputProps={{ style: { fontSize: "10pt" } }}
                  rows={10}
                  value={incident.abuse.body}
                />
              </Grid>
            </Grid>
          )}

          {evidence && (
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography
                  color="gray"
                  variant="h6"
                  sx={{ fontVariant: "all-small-caps" }}
                >
                  Supporting Evidence ({evidence.length} file
                  {evidence.length === 1 ? "" : "s"})
                </Typography>
                <ImageList cols={4} rowHeight={256}>
                  {evidence.map((file) => (
                    <ImageListItem key={file._id || file.key}>
                      <img loading="lazy" src={file.url} />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2} mt={1}>
            <Grid item xs={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    RDAP
                  </Typography>

                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Typography
                        color="gray"
                        variant="h6"
                        sx={{ fontVariant: "all-small-caps" }}
                      >
                        Registered
                      </Typography>
                      <Typography>
                        {new Date(
                          incident.details.rdap?.ts?.created
                        ).toLocaleString()}
                      </Typography>
                      <Typography
                        color="gray"
                        variant="h6"
                        sx={{ fontVariant: "all-small-caps" }}
                      >
                        Updated
                      </Typography>
                      <Typography>
                        {new Date(
                          incident.details.rdap?.ts?.updated
                        ).toLocaleString()}
                      </Typography>
                      <Typography
                        color="gray"
                        variant="h6"
                        sx={{ fontVariant: "all-small-caps" }}
                      >
                        Expires
                      </Typography>
                      <Typography>
                        {new Date(
                          incident.details.rdap?.ts?.expires
                        ).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box flexGrow={1}>
                      <Typography
                        color="gray"
                        variant="h6"
                        sx={{ fontVariant: "all-small-caps" }}
                      >
                        Nameservers
                      </Typography>
                      <Stack>
                        {incident.details.rdap?.nameservers?.map((ns) => (
                          <Typography key={ns}>{ns}</Typography>
                        ))}
                      </Stack>
                    </Box>
                  </Box>

                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Status
                  </Typography>
                  {incident.details.rdap?.status?.map((status) => (
                    <Chip key={status} sx={{ mr: 1 }} label={status} />
                  ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    DNS
                  </Typography>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      {incident.details.dns.A && (
                        <>
                          <Typography
                            color="gray"
                            variant="h6"
                            sx={{ fontVariant: "all-small-caps" }}
                          >
                            IPv4 Address (A)
                          </Typography>
                          <Stack>
                            {incident.details.dns.A.map((v) => (
                              <Typography key={v}>{v}</Typography>
                            ))}
                          </Stack>
                        </>
                      )}
                      {incident.details.dns.AAAA && (
                        <>
                          <Typography
                            color="gray"
                            variant="h6"
                            sx={{ fontVariant: "all-small-caps" }}
                          >
                            IPv6 Address (AAAA)
                          </Typography>
                          <Stack>
                            {incident.details.dns.AAAA.map((v) => (
                              <Typography key={v}>{v}</Typography>
                            ))}
                          </Stack>
                        </>
                      )}
                      {incident.details.dns.MX && (
                        <>
                          <Typography
                            color="gray"
                            variant="h6"
                            sx={{ fontVariant: "all-small-caps" }}
                          >
                            Mail Exchange (MX)
                          </Typography>
                          <Stack>
                            {incident.details.dns.MX.map((v) => (
                              <span key={v.exchange}>
                                <Chip label={`Priority ${v.priority}`} />{" "}
                                {v.exchange}
                              </span>
                            ))}
                          </Stack>
                        </>
                      )}
                    </Box>
                    <Box flexGrow={1}>
                      {incident.details.dns.NS && (
                        <>
                          <Typography
                            color="gray"
                            variant="h6"
                            sx={{ fontVariant: "all-small-caps" }}
                          >
                            Nameservers (NS)
                          </Typography>
                          <Stack>
                            {incident.details.dns.NS.map((ns) => (
                              <Typography key={ns}>{ns}</Typography>
                            ))}
                          </Stack>
                        </>
                      )}
                    </Box>
                  </Box>

                  {/* {Object.entries(incident.details.dns).map(([ field, values ]) => <React.Fragment key={field}>
                                <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>{field}</Typography>
                                {field === 'MX' && <Stack>{values.map((v) => <span key={v.exchange}><Chip label={`Priority ${v.priority}`}/> {v.exchange}</span>)}</Stack>}
                                {field === 'A' && <Stack>{values.map((v) => <Typography key={v}>{v}</Typography>)}</Stack>}
                                {field === 'NS' && <Stack>{values.map((v) => <Typography key={v}>{v}</Typography>)}</Stack>}
                                {field === 'SOA' && <Stack>{Object.entries(values).map(([ f, v ]) => <Typography key={f}>{f}: {v}</Typography>)}</Stack>}
                            </React.Fragment>)} */}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    Google Safe Browsing
                  </Typography>

                  {incident.details.googleSafeBrowsing?.matches?.map(
                    ({ threatType, platformType, threat }, idx) => (
                      <Box key={idx}>
                        <Typography
                          color="gray"
                          variant="h6"
                          sx={{ fontVariant: "all-small-caps" }}
                        >
                          Threat Type
                        </Typography>
                        <Typography>{threatType}</Typography>
                        <Typography
                          color="gray"
                          variant="h6"
                          sx={{ fontVariant: "all-small-caps" }}
                        >
                          Platform
                        </Typography>
                        <Typography>{platformType}</Typography>
                        <Typography
                          color="gray"
                          variant="h6"
                          sx={{ fontVariant: "all-small-caps" }}
                        >
                          URL
                        </Typography>
                        <Typography>{threat.url}</Typography>
                      </Box>
                    )
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    CleanDNS
                  </Typography>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Abuse Reports
                  </Typography>
                  <Chip
                    sx={{ mr: 1 }}
                    label={`Open: ${
                      incident.details.cleanDns?.open >= 0
                        ? incident.details.cleanDns?.open
                        : "n/a"
                    }`}
                  />
                  <Chip
                    label={`Closed: ${
                      incident.details.cleanDns?.closed >= 0
                        ? incident.details.cleanDns?.closed
                        : "n/a"
                    }`}
                  />
                  {/* <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Sources</Typography> */}
                  {/* {(incident.details.cleanDns?.sources || []).map((source) => <Chip key={source} sx={{mr: 1}} label={source.replace(/_/g, ' ')}/>)} */}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    Spamhaus
                  </Typography>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Hostname Abuse Status
                  </Typography>
                  {(incident.details.spamhaus?.hostname || [""]).map((v) => (
                    <Chip
                      sx={{ mr: 1 }}
                      label={v ? `${spamhaus[v]} (${v})` : "n/a"}
                    />
                  ))}
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Domain Abuse Status
                  </Typography>
                  {(incident.details.spamhaus?.domain || [""]).map((v) => (
                    <Chip
                      sx={{ mr: 1 }}
                      label={v ? `${spamhaus[v]} (${v})` : "n/a"}
                    />
                  ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    SURBL
                  </Typography>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Hostname Abuse Status
                  </Typography>
                  {(incident.details.surbl?.hostname || [""]).map((v) => (
                    <Chip
                      sx={{ mr: 1 }}
                      label={v ? `${surbl(v).join(", ")} (${v})` : "n/a"}
                    />
                  ))}
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Domain Abuse Status
                  </Typography>
                  {(incident.details.surbl?.domain || [""]).map((v) => (
                    <Chip
                      sx={{ mr: 1 }}
                      label={v ? `${surbl(v).join(", ")} (${v})` : "n/a"}
                    />
                  ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    HybridAnalysis
                  </Typography>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Abuse Reports
                  </Typography>
                  <Chip
                    sx={{ mr: 1 }}
                    label={`Reported: ${
                      (incident.details.hybridAnalysis?.domain?.result || [])
                        .length
                    }`}
                  />
                  {(incident.details.hybridAnalysis?.domain?.result || []).map(
                    (r, idx) => (
                      <Paper key={idx}>
                        <Typography color="gray" variant="h6" sx={{}}>
                          Threat Score
                        </Typography>
                        {r.threat_score}
                        <Typography color="gray" variant="h6" sx={{}}>
                          Classification
                        </Typography>
                        {r.vx_family}
                        <Typography color="gray" variant="h6" sx={{}}>
                          Verdict
                        </Typography>
                        {r.verdict}
                      </Paper>
                    )
                  )}
                </CardContent>
              </Card>
            </Grid>
            {/* PhishTank: {
                    Reports: (incident.details.phishTank || []).map((r) => ({ ReportType: 'Phishing', Date: r.Report.Date, Ongoing: r.Report.Ongoing, DateEnd: r.Report.Custom.DateEnd, SourceUrl: r.Report.SourceUrl, Target: r.Report.Custom.Target })),
                }, */}
            <Grid item xs={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    PhishTank
                  </Typography>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Abuse Reports
                  </Typography>
                  <Chip
                    sx={{ mr: 1 }}
                    label={`Open: ${
                      (incident.details.phishTank || []).filter(
                        (r) => r.Report.Ongoing
                      ).length
                    }`}
                  />
                  <Chip
                    label={`Closed: ${
                      (incident.details.phishTank || []).filter(
                        (r) => !r.Report.Ongoing
                      ).length
                    }`}
                  />
                </CardContent>
              </Card>
            </Grid>
            {/* AbuseCh: {
                    OpenReports: (incident.details.abuseCh || []).filter((r) => r.id ? true : r.Report.Ongoing)?.length,
                    ClosedReports: (incident.details.abuseCh || []).filter((r) => r.id ? false : !r.Report.Ongoing)?.length,
                    TotalReports: (incident.details.abuseCh || []).length,
                    Reports: (incident.details.abuseCh || []).map((r) => r.id ?
                        ({ ReportType: 'Malware', Date: r.first_seen, Ongoing: true, DateEnd: null, SourceUrl: r.ioc, }) :
                        ({ ReportType: r.Report.ReportType, Date: r.Report.Date, Ongoing: r.Report.Ongoing, DateEnd: r.Report.Custom.DateEnd, SourceUrl: r.Report.SourceUrl, Target: r.Report.Custom.Target })
                    ),
                }, */}
            <Grid item xs={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="gray" variant="h5">
                    Abuse.ch
                  </Typography>
                  <Typography
                    color="gray"
                    variant="h6"
                    sx={{ fontVariant: "all-small-caps" }}
                  >
                    Abuse Reports
                  </Typography>
                  <Chip
                    sx={{ mr: 1 }}
                    label={`Open: ${
                      (incident.details.abuseCh || []).filter(
                        (r) => r.Report.Ongoing
                      ).length
                    }`}
                  />
                  <Chip
                    label={`Closed: ${
                      (incident.details.abuseCh || []).filter(
                        (r) => !r.Report.Ongoing
                      ).length
                    }`}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {false && incident && evidence && (
        <XARF incident={incident} evidence={evidence} />
      )}
    </>
  );
};

const incidentToXarf = ({
  incident,
  evidence,
  version: Version = 2,
  disclosure: Disclosure = true,
}) => {
  const xarf = {
    Version,
    ReporterInfo: {
      ReporterOrg: incident.reporter.organization || "NetBeacon",
      ReporterOrgDomain: incident.reporter.organization
        ? null
        : "netbeacon.org",
      ReporterContactEmail: incident.reporter.email,
      ReporterContactName: incident.reporter.name,
    },
    Disclosure,
    Report: {
      ReportClass: incident.type === "Spam" ? "Activity" : "Content",
      ReportType: abuseNames[incident.abuse.type],
      ThreatActor: incident.details.domain,
      SourceUrl: incident.actor.url,
      Date: incident.abuse.date,
      Ongoing: Boolean(incident.abuse.ongoing),
      ReporterNotes: incident.abuse.description,
      Custom: {
        NetBeaconIncidentId: incident._id.toString(),
        // Description: incident.abuse.description,
        Evidence: evidence.map((e) => ({
          FileName: e.name,
          ContentType: e.type,
          FileSize: e.size,
          PayloadUrl: e.url,
        })),
        Enrichments: {
          CleanDNS: {
            OpenReports: incident.details.cleanDns?.open,
            ClosedReports: incident.details.cleanDns?.closed,
            TotalReports: incident.details.cleanDns?.total,
            Sources: incident.details.cleanDns?.sources,
          },
          Rdap: Object.keys(incident.details.rdap || {}).length
            ? incident.details.domain
              ? {
                  Status: incident.details.rdap.status || [],
                  NameServers: incident.details.rdap.nameservers || [],
                  DateCreated: incident.details.rdap.ts.created,
                  DateUpdated: incident.details.rdap.ts.updated,
                  DateExpires: incident.details.rdap.ts.expires,
                  RegistrarName: incident.details.rdap.registrar.name,
                  RegistrarIanaId: incident.details.rdap.registrar.id,
                }
              : {
                  Name: incident.details.rdap.name,
                  Handle: incident.details.rdap.handle,
                  Status: incident.details.rdap.status,
                  ParentHandle: incident.details.rdap.parent,
                  AddressStart: incident.details.rdap.address.start,
                  AddressEnd: incident.details.rdap.address.end,
                  DateCreated: incident.details.rdap.ts.created,
                  DateUpdated: incident.details.rdap.ts.updated,
                }
            : {},
          Dns: incident.details.dns || {},
          HybridAnalysis: {
            TotalReports: (
              incident.details.hybridAnalysis?.domain?.result || []
            ).length,
            Reports: (
              incident.details.hybridAnalysis?.domain?.result || []
            ).map((r) => ({
              ThreatScore: r.threat_score,
              Classification: r.vx_family,
              Verdict: r.verdict,
            })),
          },
          PhishTank: {
            OpenReports: (incident.details.phishTank || []).filter(
              (r) => r.Report.Ongoing
            ).length,
            ClosedReports: (incident.details.phishTank || []).filter(
              (r) => !r.Report.Ongoing
            ).length,
            TotalReports: (incident.details.phishTank || []).length,
            Reports: (incident.details.phishTank || []).map((r) => ({
              ReportType: "Phishing",
              Date: r.Report.Date,
              Ongoing: r.Report.Ongoing,
              DateEnd: r.Report.Custom.DateEnd,
              SourceUrl: r.Report.SourceUrl,
              Target: r.Report.Custom.Target,
            })),
          },
          AbuseCh: {
            OpenReports: (incident.details.abuseCh || []).filter((r) =>
              r.id ? true : r.Report.Ongoing
            )?.length,
            ClosedReports: (incident.details.abuseCh || []).filter((r) =>
              r.id ? false : !r.Report.Ongoing
            )?.length,
            TotalReports: (incident.details.abuseCh || []).length,
            Reports: (incident.details.abuseCh || []).map((r) =>
              r.id
                ? {
                    ReportType: "Malware",
                    Date: r.first_seen,
                    Ongoing: true,
                    DateEnd: null,
                    SourceUrl: r.ioc,
                  }
                : {
                    ReportType: r.Report.ReportType,
                    Date: r.Report.Date,
                    Ongoing: r.Report.Ongoing,
                    DateEnd: r.Report.Custom.DateEnd,
                    SourceUrl: r.Report.SourceUrl,
                    Target: r.Report.Custom.Target,
                  }
            ),
          },
        },
      },
    },
  };

  if (incident.details.domain) {
    xarf.Report.Custom.Domain = incident.details.domain;
    xarf.Report.Custom.Tld = incident.details.tld;
  } else if (incident.details.ip) {
    xarf.Report.Custom.Ip = incident.details.ip;
  }

  if (incident.abuse.type === "botnets") {
    if (incident.abuse.ip) {
      xarf.Report.SourceIp = incident.abuse.ip;
      xarf.Report.SourcePort = incident.abuse.port;
    }
    xarf.Report.BotnetName = incident.abuse.botnet;
  } else if (incident.abuse.type === "phishing") {
    xarf.Report.Custom.Target = incident.abuse.target;
    if (incident.abuse.email) {
      xarf.Report.SmtpMailFromAddress = incident.abuse.email;
      xarf.Report.SmtpMailToAddress = incident.reporter.email;
    }
    if (incident.abuse.headers || incident.abuse.body) xarf.Report.Samples = [];
    if (incident.abuse.headers)
      xarf.Report.Samples.push({
        ContentType: "text/plain",
        Description: "email headers",
        Payload: incident.abuse.headers,
      });
    if (incident.abuse.body)
      xarf.Report.Samples.push({
        ContentType: "text/plain",
        Description: "email body",
        Payload: incident.abuse.body,
      });
  } else if (incident.abuse.type === "malware") {
    xarf.Report.MalwareName = incident.abuse.malware;
    xarf.Report.Custom.Samples = [
      {
        FileName: incident.abuse.filename,
        HashMd5: incident.abuse.md5,
        HashSha1: incident.abuse.sha1,
      },
    ];
  } else if (incident.abuse.type === "spam") {
    xarf.Report.SmtpMailFromAddress = incident.abuse.email;
    xarf.Report.SmtpMailToAddress = incident.reporter.email;
    xarf.Report.Samples = [
      {
        ContentType: "text/plain",
        Description: "email headers",
        Payload: incident.abuse.headers,
      },
      {
        ContentType: "text/plain",
        Description: "email body",
        Payload: incident.abuse.body,
      },
    ];
  }

  return xarf;
};

const XARF = ({ incident, evidence }) => {
  return (
    <Paper elevation={6} sx={{ mt: 1, p: 1, overflow: "auto" }}>
      <xmp>
        {JSON.stringify(incidentToXarf({ incident, evidence }), null, 4)}
      </xmp>
    </Paper>
  );
};

const DataGridHeader = ({ tooltip, children, ...props }) => {
  return (
    <>
      <Tooltip title={tooltip} {...props}>
        <TooltipChild>{children}</TooltipChild>
      </Tooltip>
    </>
  );
};

const TooltipChild = forwardRef(({ children, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
});
