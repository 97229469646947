import React, { useContext } from 'react';
import { RealmContext } from '../ReactRealmProvider';
import { Redirect } from 'react-router-dom';
import { Typography } from '@mui/material';


export const MainScreen = () => {
    const { user, customData } = useContext(RealmContext);

    if (!customData.type) {
        return <Redirect to="/account" />;
    }

    if (customData.type === 'admin') return <Redirect to="/admin/users" />;
    if (customData.type === 'reporter') return <Redirect to="/new-report" />;
    return <Redirect to="/incidents" />;

    /*
    useEffect(() => {
        mongo.db('cart').collection('tlds').insertMany(tlds);
    }, []);
    */
    /*
    useEffect(() => {
        mongo.db('cart').collection('registrars').insertMany(
            Object.entries(registrarRdap.data).map(([ id, { name, status, rdap_server: rdapServer } ]) => ({ ianaId: parseInt(id), name, status, rdapServer }))
        ).then(() => console.log('registrars'));
    }, []);
    */
    /*
    useEffect(() => {
        const byRdapServer = {};
        Object.entries(tldRdap.data).forEach(([ tld, rdapServer ]) => { byRdapServer[rdapServer] = byRdapServer[rdapServer] || []; byRdapServer[rdapServer].push(tld); });
        Object.entries(byRdapServer).forEach(([ rdapServer, tlds ]) => {
            mongo.db('cart').collection('tlds').updateMany({ tld: { $in: tlds } }, { $set: { rdapServer } }).then(() => console.log({ rdapServer, tlds }));
        });
    }, []);
    */
    return (<>
        <Typography mt={1}>
            Welcome, {customData.name} ({customData.email}).
        </Typography>
        {/* <xmp>{JSON.stringify(customData, null, 2)}</xmp> */}
    </>);
};
