import React, { useContext, useEffect } from 'react';
import { RealmContext } from '../ReactRealmProvider';
import { Redirect } from 'react-router-dom';

export const Logout = () => {
    const { logout, user } = useContext(RealmContext);

    useEffect(() => user && logout(), []);

    return user ? null : <Redirect to="/" />;
};
