import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

export const Terms = () => {
    return <Box>
        <Typography variant='h4'>DNS Abuse Institute</Typography>
        <Typography variant='h4'>NetBeacon Service Terms of Use</Typography>

        <Typography mt={2} mb={2} variant='h5'>Last Updated Date: February 13, 2023</Typography>

        <Typography mb={1}>
            These terms and conditions (the “Terms”) apply to your access to and use of the Public Interest Registry NetBeacon Institute’s NetBeacon Reporter Service (the “NetBeacon Reporter Service”) as further set forth herein. By accessing or using the NetBeacon Reporter Service (whether directly through our site or through a third-party’s site), you agree that you are bound by these Terms. As used in these Terms, the phrases "you", "your" and "yours" refer to the individual agreeing to these Terms; if you are acting on behalf of an entity (e.g., a company, organization, etc.), then those phrases refer to the entity on whose behalf you are acting. As used in these Terms, the phrases "the Institute”, "we", "us" or "ours" refer collectively to Public Interest Registry and its NetBeacon Institute. If you do not agree to be bound by these Terms, you are not authorized to use the NetBeacon Reporter Service. We may update these Terms from time to time, and post the updated version of these Terms <Link to="/terms">here</Link>. We'll include a "Last Updated Date" at the beginning of these Terms so that you can see when we last made changes. Your continued use of any aspect of the NetBeacon Reporter Service after we post updated Terms will constitute your agreement to the updated Terms.
        </Typography>
        <Typography variant='h5'>Privacy</Typography>
        <Typography mb={1}>
            Our <Link to="/privacy">Privacy Notice</Link> forms a part of, and is hereby incorporated into these Terms. Without limiting our other rights hereunder or elsewhere, you agree that we may make available information you provide or that we maintain about you to third parties as applicable laws require or permit, including, without limitation, as provided in our Privacy Notice. We may also report to appropriate law enforcement agencies any activity that we believe to be potentially criminal. This paragraph will survive the termination of these Terms.
        </Typography>

        <Typography mt={2} mb={2} variant='h5'>NetBeacon Reporter Service Access &amp; Use</Typography>

        <Typography mb={1}>
            Purpose of NetBeacon Reporter Service. Our NetBeacon Reporter Service is intended to provide a centralized, structured, and efficient method for users (“Abuse Reporters”) to submit abuse complaints (each, a “Notification”) related to Internet infrastructure providers that can be appropriately acted upon by Notification recipients, such as the relevant registrar, provider, or party (“Abuse Report Recipients”).
        </Typography>
        <Typography mb={1}>
            <b>Your Access.</b> Subject to these Terms, you may access and use the NetBeacon Reporter Service solely to submit abuse Notifications and take such other actions as expressly permitted within NetBeacon Reporter Service’s features and functionalities made available to you (directly or through third parties). If you are managing the access of other users within your organization, those users must also agree to be bound by these Terms. User IDs are granted to individual persons and may not be shared. You must follow all instructions provided for using the NetBeacon Reporter Service. You are not authorized to, and agree that you will not, use the NetBeacon Reporter Service other than as expressly permitted in these Terms. Without limiting the foregoing, you agree that you will not do any of the following in connection with the NetBeacon Reporter Service:
            <ul>
                <li>Use the NetBeacon Reporter Service for any unlawful, nefarious, malicious, abusive, or inappropriate purpose, or to wrongfully attempt to interrupt or disrupt products, services, or business of another person or entity.</li>
                <li>Introduce into the NetBeacon Reporter Service any malicious or otherwise harmful code or computer virus.</li>
                <li>Introduce into the NetBeacon Reporter Service, or post, upload, transmit or link to, any Child Sexual Abuse Materials (“CSAM”) or any image depicting child pornography.</li>
                <li>Introduce into the NetBeacon Reporter Service, or post, upload, transmit or link to, any illegal, sexually explicit images or content.</li>
                <li>Attempt to access or obtain information from another person or entity’s account with us.</li>
                <li>Engage in any activity that could disrupt or interfere with any other person or entity’s use of the NetBeacon Reporter Service.</li>
                <li>Attempt to advertise or solicit business for a commercial service or product.</li>
                <li>Impersonate another person or entity or otherwise misrepresent you or your organizations’ identities.</li>
                <li>Submit more than one Notification for a particular abuse incident.</li>
                <li>Engage in any activity that is abusive or could be harmful to the Institute or its vendors or licensors, or that could subject any of them or us to potential litigation or other legal action by another party.</li>
            </ul>
        </Typography>
        <Typography mb={1}>
            <b>Your Online Account.</b> We may require that you create an online account as a condition of using the NetBeacon Reporter Service. You agree to provide accurate and complete information in connection with any account you establish, and agree to keep that information accurate and complete for as long as you have an online account with us. You are responsible for safeguarding any authentication credentials (e.g., passwords) issued or created for your account, and you are responsible for all actions taken through the use of your account—whether authorized by you or not. We may reset your password, suspend your access to and interactions with the NetBeacon Reporter Service, either temporarily or permanently, or take other similar actions for the purpose of NetBeacon Reporter Service security. You agree to immediately notify us upon discovering any compromise, misuse, or unauthorized access to your authentication credentials or account.
        </Typography>
        <Typography mb={1}>
            In the event we, in our sole discretion, determine you have violated these Terms, you understand and agree that we reserve the right to sever your access to the NetBeacon Reporter Service and/or terminate your account.
        </Typography>
        <Typography mb={1}>
            <b>Notification Submission.</b> When you submit a Notification through the NetBeacon Reporter Service:
            <ul>
                <li>You will be required to submit login credentials yourself;</li>
                <li>You must provide all information requested in connection with the Notification (including without limitation, all requested evidence of the abuse about which you are submitting a Notification).</li>
                <li>We will attempt to share your Notification with Abuse Report Recipients we deem appropriate based on their association with DNS-related products or services and their ability to act on the Notification.</li>
                <li>In order to process your Notification, your information and any email address or other communication identifier you provide will be provided to each Abuse Report Recipient (we do not permit anonymous Notifications). This information may be used by Abuse Report Recipients to provide information to you or request additional information from you concerning the Notification.</li>
                <li>We may append to your Notification additional information pertaining to any domain name(s) or other DNS-related resources related to your Notification where we believe, in our sole discretion, that the information might be helpful to the Abuse Report Recipient in processing the Notification.</li>
            </ul>
        </Typography>
        <Typography mb={1}>
            <b>Your Activity and Statistics.</b> We may provide you with the ability to view and share certain information concerning your activity within the NetBeacon Reporter Service, such as, statistics regarding your Notification submissions (collectively, “Activity Information”). While we try to provide Activity Information that is accurate, you acknowledge and agree that we cannot and do not promise that it will be accurate, and you assume all risks that your Activity Information might be inaccurate. You agree that we have the right to use and publish for our purposes any Activity Information that you have published, and the right to use and publish all Activity Information that is non-personal information in any manner and for any purposes that does not reasonably allow another person to identify you. This paragraph will survive the termination of these Terms.
        </Typography>
        <Typography mb={1}>
            <b>No Guaranty of Submission or Action.</b> We may, in our sole discretion, refuse to process or forward your Notification, and we cannot and do not promise that your Notification will reach or be acted upon by any Abuse Report Recipients.
        </Typography>
        <Typography mb={1}>
            <b>Your equipment.</b> You are responsible for acquiring and maintaining all equipment and services (such as, without limitation, Internet connectivity) required to use the NetBeacon Reporter Service as made available by us.
        </Typography>
        <Typography mb={1}>
            <b>Your Representations and Warranties.</b> You represent and warrant to us the following:
            <ul>
                <li>You are at least 18 years of age.</li>
                <li>You will only submit Notifications based upon a good faith belief by you that each such Notification pertains to abusive, unauthorized, or unlawful activity falling within the Notification categories made available to you within the NetBeacon Reporter Service.</li>
                <li>You are not and will not use the NetBeacon Reporter Service for any unlawful, nefarious, or malicious purpose.</li>
                <li>All of the information you provide to, within or through the NetBeacon Reporter Service is and will, to the best of your knowledge and belief, be accurate and complete, and in compliance with these Terms.</li>
                <li>If you are acting on behalf of another person or entity, you have all necessary authorizations and permissions to legally act on that person’s or entity’s behalf and to provide the information you provide or make available through the NetBeacon Reporter Service.</li>
            </ul>
            <b>This paragraph will survive the termination of these Terms.</b>
        </Typography>

        <Typography variant='h5'>Intellectual Property Notices &amp; Protections.</Typography>
        <Typography mb={1}>
            You agree that we and our licensors own all right, title and interest, including all intellectual property rights, in and to the NetBeacon Reporter Service, and all software, algorithms, code, methodologies, content, trademarks, service marks, logos, images, data, and forms related thereto, and all modifications to any of the foregoing (the “Institute IP”). You may from time-to-time provide suggestions or ideas concerning the Institute IP (“Feedback”). We shall have the unrestricted right, and you hereby grant to us a non-exclusive, worldwide, perpetual, irrevocable, sublicensable, transferable, and fully paid right and license to use and fully exploit any and all Feedback for any purposes, commercial or otherwise, without any obligation of compensation or attribution to you or any third party. You agree not to remove or attempt to use technological means to remove or bypass any intellectual property notices or technological safeguards included in any Institute IP. All rights not expressly granted under these Terms are hereby reserved by the Institute and its licensors. You will not use any Institute IP in any manner or for any purpose not expressly permitted in these Terms. Without limiting the foregoing, you will not, either directly or through a third party: (i) copy, modify, adapt, alter, translate, or create derivative works of any Institute IP; (ii) distribute, sublicense, lease, rent, loan, or transfer any aspect of the Institute IP to any third party; or (iii) reverse engineer, decompile, disassemble, or attempt to derive source code for any aspect of the Institute IP. This paragraph will survive the termination of these Terms.
        </Typography>

        <Typography variant='h5'>Termination &amp; Effect of Termination.</Typography>
        <Typography mb={1}>
            You and we each may terminate these Terms at any time, with or without cause, by providing written notice to the other party (and our written notice to you may be via email or through any account you have created with us). Upon termination of these Terms, all access and use rights granted will cease, access or use of the NetBeacon Reporter Service will no longer be authorized, and we will have no obligation to provide any information concerning your account, including, and without limitation to, any Activity Information. We may, at any time, in our sole discretion, modify or cease providing the NetBeacon Reporter Service or any aspect of it.
        </Typography>

        <Typography variant='h5'>Limitation of Liability</Typography>
        <Typography mb={1}>
            YOU AGREE TO THE FULLEST EXTENT PERMITTED BY LAW, NETBEACON INSTITUTE AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND SERVICE PROVIDERS SHALL NOT HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, SPECIAL, EXEMPLARY OR INDIRECT DAMAGES, OR LOST PROFITS OR REVENUES, ARISING OUT OF OR RELATING TO ANY ACCESS  OR USE OF THE NETBEACON REPORTER SERVICE, OR THESE TERMS, WHETHER ARISING IN TORT, CONTRACT, OR OTHERWISE, AND EVEN IF WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES.
        </Typography>
        <Typography mb={1}>
            IN THE EVENT (AND ONLY IN THE EVENT) THE LIMITATIONS OF LIABILITY SET FORTH ABOVE ARE DEEMED BY A COURT OF COMPETENT JURISDICTION TO BE UNENFORCEABLE WITH RESPECT TO ANY CLAIM BY YOU OR SOMEONE ACTING ON YOUR BEHALF ARISING OUT OF OR RELATING TO ANY ACCESS OR USE OF THE NETBEACON REPORTER SERVICE, OR THESE TERMS, YOU AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY LAW, OUR TOTAL AGGREGATE LIABILITY FOR ALL SUCH CLAIMS WILL NOT EXCEED ONE HUNDRED DOLLARS ($100 USD).
        </Typography>
        <Typography mb={1}>
            YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT CHARGING YOU A FEE FOR THE ACCESS AND USE OF THE NETBEACON REPORTER SERVICE GRANTED IN THESE TERMS, AND THEREFORE THE LIMITATION OF OUR LIABILITY ABOVE IS ACCEPTABLE, AND WITHOUT THE LIMITATION OF LIABILITY STATED HEREIN, WE WOULD NOT MAKE THE NETBEACON REPORTER SERVICE AVAILABLE TO YOU. YOU ACKNOWLEDGE AND AGREE IT IS NOT NECESSARY TO USE THE NETBEACON REPORTER SERVICE TO SUBMIT ABUSE NOTIFICATIONS DIRECTLY TO THIRD PARTIES.
        </Typography>
        <Typography mb={1}>
            The provisions in this “Limitation of Liability” section will survive the termination of these Terms.
        </Typography>

        <Typography variant='h5'>Indemnification.</Typography>
        <Typography mb={1}>
            You agree to indemnify, defend, and hold the NetBeacon Institute and Public Interest Registry and its affiliates, and each of their officers, directors, employees, agents, and representatives, harmless from and against all claims, losses, liabilities, or damages arising from or related to your use of the NetBeacon Reporter Service.
        </Typography>

        <Typography variant='h5'>Disclaimer; No Warranties</Typography>
        <Typography mb={1}>
            YOU ACKNOWLEDGE AND AGREE YOUR USE OF THE NETBEACON REPORTER SERVICE IS AT YOUR OWN RISK, AND THE NETBEACON REPORTER SERVICE IS PROVIDED ON AN "AS IS" BASIS. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE NETBEACON REPORTER SERVICE  OR ITS CONTENT WILL BE COMPLETE, ACCURATE, UNINTERRUPTED, OR ERROR FREE, OR THAT ANY OF THEM WILL MEET YOUR REQUIREMENTS. ADDITIONALLY, YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR THE CONTENT OR QUALITY OF ANY THIRD-PARTY WEBSITES OR RESOURCES THROUGH WHICH YOU MIGHT ACCESS, USE OR RECEIVE MESSAGES CONCERNING THE NETBEACON REPORTER SERVICE. THE INCLUSION OF A LINK OR FORM PERTAINING TO THE NETBEACON REPORTER SERVICE ON A THIRD PARTY WEBSITE DOES NOT IMPLY THAT WE ENDORSE THE OTHER PARTY OR THEIR SERVICES, WEBSITES, OR CONTENT. YOU AGREE THAT YOUR USE OF ANY LINKS, FORMS, OR OTHER ACCESS TO THE NETBEACON REPORTER SERVICE ON A THIRD-PARTY SITE OR THROUGH A THIRD-PARTY SYSTEM IS AT YOUR OWN RISK.
        </Typography>

        <Typography variant='h5'>Legal Action; Governing Law and Jurisdiction; No Class Actions.</Typography>
        <Typography mb={1}>
            You and we agree these Terms shall be governed by and interpreted under the laws of the Commonwealth of Virginia, without regard to its conflicts of laws rules, and any dispute arising out of or related to the NetBeacon Reporter Service or these Terms must be brought exclusively in the state and federal courts for Fairfax County, Virginia, USA. You and we each agree to the exclusive personal jurisdiction and venue of such courts for the resolution of all such disputes. To the fullest extent permitted by law, neither the Uniform Computer Information Transactions Act (as enacted in any jurisdiction) nor the United Nations Convention on Contracts for the International Sale of Goods shall apply to these Terms, and you and we hereby exclude, reject, and waive the application of the same to these Terms. You further agree that any dispute relating to or arising out of these Terms or the NetBeacon Reporter Service shall be resolved on an individual basis. Consequently, you agree that you may not bring a claim that relates to or arises out of these Terms or your use of or access to the NetBeacon Reporter Service as a plaintiff in, or a class member in, a class action or similar type of group or consolidated action. You agree that the limitations contained in this paragraph are an essential element of these Terms and without such limitations, we would not make the NetBeacon Reporter Service available to you. This paragraph will survive the termination of these Terms.
        </Typography>

        <Typography variant='h5'>General Information</Typography>
        <Typography mb={1}>
            These Terms constitute the full and complete agreement between you and us concerning the NetBeacon Reporter Service and any access or use, and these Terms supersede all prior communications or agreements between you and us pertaining to the subject matter of these Terms. You may not modify these Terms in any way except as agreed to by us in a written document signed by both you and us. If any provision is declared invalid or unenforceable, all remaining provisions will nevertheless remain in effect. Any provision of these Terms that requires or reasonably contemplates the performance or existence of obligations by either you or us after termination of these Terms will survive such termination, including, without limitation, any paragraphs and sections expressly referred to herein as surviving such termination. If you have any questions regarding these Terms, please contact us via e-mail at <a href="mailto:info@netbeacon.org">info@netbeacon.org</a>.
        </Typography>
    </Box>;
};
