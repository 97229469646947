import { PieChart } from "../../../components/charts";
import { useDashboardAggregation } from "../../../hooks/useDashboardAggregations";

export const ReportsBySubmissionVectorChart = () => {
  const { loading, data, colors } = useDashboardAggregation({
    aggregation: "reportsBySource",
  });

  return (
    <PieChart
      loading={loading}
      fullwidth
      data={data}
      title={"Reports by submission vector"}
      nameKey="via"
      xs={4}
      height={300}
      colors={colors}
    />
  );
};

export default ReportsBySubmissionVectorChart;
