import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { toolbarButtonsTheme } from "./App";
import { RealmContext } from "./ReactRealmProvider";
import NetBeacon from "./assets/NetB-2022-Logo_rgb_horizontal_KO.png";
import { MakeNewReport, ReportRoot } from "./routes/MakeNewReport";

export const EmbeddedForm = () => {
  const realm = useContext(RealmContext);

  useEffect(() => {
    if (! realm.user) {
        realm.login("anonymous");
    }
  }, [realm.user]);

  return (
    realm.user && (
      <>
        <ThemeProvider theme={toolbarButtonsTheme}>
          <AppBar position="sticky">
            <Toolbar>
              <Box>
                <img src={NetBeacon} height={30} />
              </Box>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <Container>
          <Switch>
            <Route path="/new-report/:type?/:id?">
              <ReportRoot>
                <MakeNewReport/>
              </ReportRoot>
            </Route>
            <Redirect to="/new-report" />
          </Switch>
        </Container>
      </>
    )
  );
};
