import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RealmContext } from '../../../ReactRealmProvider';
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, Switch, Tab, Tabs, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DataContext } from '../../../contexts/DataProvider';
import { accountTypes } from '../../../data/accountTypes';
import { Add, Pause, PlayArrow, Pending, Verified } from '@mui/icons-material';
import { DateTime } from '../../../components/DateTime';

const COLUMNS = {
    APPROVED: { field: 'approved', headerName: 'Verified', width: 90, renderCell: (params) => {
        return <>
            <IconButton onClick={() => params.row.showModal(`Do you want to ${params.row.approved ? 'unapprove' : 'approve'} this user?`, () => {
                params.row.updateUser(params.row._id, 'approved', !params.row.approved);
                if (!params.row.approved) {
                    params.row.updateUser(params.row._id, 'active', true);
                    params.row.updateUser(params.row._id, 'pending', false);
                }
            })} title={params.row.approved ? "User is a verified Registrar or Registry" : "User is not a verified Registrar or Registry"}>
                {params.row.approved ? <Verified color='success' /> : <Pending color='error'/> }
            </IconButton>
        </>;
    } },
    ACTIVE: { field: 'active', headerName: 'Active', width: 70, renderCell: (params) => {
        return <>
            <Switch key="active" size="small" checked={Boolean(params.row.active)} onChange={(ev, toggle) => params.row.showModal(`Do you want to ${toggle ? 'activate' : 'deactivate'} this user?`, () => params.row.updateUser(params.row._id, 'active', toggle))}/>
        </>;
    } },
    QUEUED: { field: 'paused', headerName: 'Queued', width: 80, renderCell: (params) => {
        return <>
            <IconButton onClick={() => params.row.showModal(`Do you want to ${params.row.paused ? 'un-pause this user (automatically process their abuse reports)' : 'pause this user (place their abuse reports in a queue)'}?`, () => params.row.updateUser(params.row._id, 'paused', !params.row.paused))} title={params.row.paused ? "Reports are queued" : "Put reporter in hold queue"}>
                {params.row.paused ? <Pause color='error'/> : <PlayArrow color='success' />}
            </IconButton>
        </>;
    } },
    NAME: { field: 'name', headerName: 'Name', width: 200 },
    EMAIL: { field: 'email', headerName: 'Email Address', width: 280 },
    TYPE: { field: 'type', headerName: 'Type', width: 120, valueGetter: (params) => {
        return accountTypes.find((t) => t.name === params.row.type).label;
    } },
    ORG: { field: 'org', headerName: 'Organization', width: 230, valueGetter: (params) => {
        const { type, reporterOrg: reporter, registryName: registry, registrarName: registrar } = params.row;
        return { admin: reporter, reporter, registry, registrar }[type] || 'n/a';
    } },
    REPORTS: { field: 'reports', headerName: 'Reports', width: 120, valueGetter: (params) => params.row.count },
    LOGIN: { field: 'tsLastLogin', headerName: 'Last Login', width: 160, renderCell: (params) => {
        return <DateTime timestamp={params.row.tsLastLogin}/>;
    } },
};

const columns = [ COLUMNS.APPROVED, COLUMNS.ACTIVE, COLUMNS.QUEUED, COLUMNS.NAME, COLUMNS.EMAIL, COLUMNS.TYPE, COLUMNS.ORG, COLUMNS.REPORTS, COLUMNS.LOGIN ];
const registryColumns = [
    { field: 'tlds', headerName: 'TLDs', width: 100, valueGetter: (params) => params.row.tlds.length },
    { field: 'name', headerName: 'Name', width: 500 },
    { field: 'users', headerName: 'Users', width: 100, valueGetter: (params) => params.row.users.length },
];
const registrarColumns = [
    { field: 'ianaId', headerName: 'IANA ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 500 },
    { field: 'users', headerName: 'Users', width: 100, valueGetter: (params) => params.row.users.length },
    { field: 'ianaIds', headerName: 'Grouped IANA IDs', width: 300, valueGetter: (params) => params.row.ianaIds.length },
];

export const UsersAdmin = () => {
    const { app, user, mongo, refreshCustomData } = useContext(RealmContext);
    const data = useContext(DataContext);

    const tabs = [ 'Reporters', 'Registrars', 'Registries', 'Administrators', 'Pending Verification' ];
    const [ tab, setTab ] = useState(0);
    const tabFilters = [
        (u) => !u.pending && u.type === 'reporter',
        (u) => u.approved && u.type === 'registrar',
        (u) => u.approved && u.type === 'registry',
        (u) => u.approved && u.type === 'admin',
        (u) => u.pending, // !u.approved && u.type !== 'reporter',
    ];

    // const [ prompt, setPrompt ] = useState('');
    // const [ onYes, setOnYes ] = useState(() => null);
    const [ verifyModal, setVerifyModal ] = useState({ show: false, prompt: '', onYes: () => null });

    const registryGroups = useMemo(() => data.registryGroups.map((_) => ({ ..._, lc: _.name.toLocaleLowerCase() })), [data.registryGroups]);
    const registrarGroups = useMemo(() => data.registrarGroups.map((_) => ({ ..._, lc: _.name.toLocaleLowerCase() })), [data.registrarGroups]);
    const groups = { registry: registryGroups, registrar: registrarGroups };

    const [ allUsers, setAllUsers ] = useState([]);
    const [ createdRegistrars, setCreatedRegistrars ] = useState([]);
    const [ createdRegistries, setCreatedRegistries ] = useState([]);
    const [ showAddUserModal, setShowAddUserModal ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const showModal = useCallback((prompt, onYes) => {
        setVerifyModal({
            show: true,
            prompt,
            onYes,
        });
    }, []);

    const pending = useMemo(() => allUsers.filter(tabFilters[4]).length, [allUsers]);

    const updateUser = (_id, field, value) => {
        mongo.db('cart').collection('users').updateOne({ _id }, { $set: { [field]: value } }).then((r) => {
            setAllUsers((users) => users.map((u) => u.id === `${_id}` ? ({ ...u, [field]: value }) : u));
        });
    };

    useEffect(() => {
        if (!showAddUserModal) mongo.db('cart').collection('users').find({ email: { $exists: true } }, { sort: { email: 1 } }).then(async (r) => {            
            console.log(`got the users`);
            r = r.filter(({ email }) => !email.match(/@anonymous.user.com$/));

            setAllUsers(r.map((el) => ({ id: el._id.toString(), ...el, updateUser, showModal, count: '--' })));

            const registrars = r.reduce((_, u) => {
                if (u.type === 'registrar' && u.registrarName) {
                    _[u.registrarName] = _[u.registrarName] || { users: [], ianaId: groups.registrar.find((r) => r.name === u.registrarName).ianaId, ianaIds: groups.registrar.find((r) => r.name === u.registrarName).registrars.map(({ ianaId }) => ianaId) || [] };
                    _[u.registrarName].users.push(u);
                }
                return _;
            }, {});
            const registries = r.reduce((_, u) => {
                if (u.type === 'registry' && u.registryName) {
                    _[u.registryName] = _[u.registryName] || { users: [], tlds: groups.registry.find((r) => r.name === u.registryName)?.tlds || [] };
                    _[u.registryName].users.push(u);
                }
                return _;
            }, {});

            setCreatedRegistrars(Object.entries(registrars).map(([ name, { ianaId, ianaIds, users } ]) => ({ id: name, name, ianaId, ianaIds, users })));
            setCreatedRegistries(Object.entries(registries).map(([ name, { tlds, users } ]) => ({ id: name, name, tlds, users })));
            setLoading(false);

            mongo.db('cart').collection('reports').aggregate([
                // { $match: { realmId: { $in: r.map(({ realmId }) => realmId) } } },
                { $group: { _id: '$realmId', count: { $sum: 1 } } },
            ]).then((r) => {
                console.log(`got the reports`);
                const reports = new Map (r.map(({ _id, count }) => ([ _id, count ])));

                setAllUsers((users) => users.map((el) => ({ ...el, count: reports.get(el.realmId) || 0 })));
            });
        });
    }, [showAddUserModal]);

    const filteredUsers = useMemo(() => allUsers.filter(tabFilters[tab]), [allUsers, tab]);

    const [ newUser, setNewUser ] = useState({
        email: '',
        name: '',
        type: 'registry',
        org: null,
    });

    const [ orgName, setOrgName ] = useState('');

    useEffect(() => {
        setNewUser({
            email: '',
            name: '',
            type: ['reporter', 'registrar', 'registry', 'reporter', 'reporter'][tab],
            org: null,
        });
        setOrgName('');
    }, [ showAddUserModal, tab ]);

    useEffect(() => {
        setNewUser((user) => ({ ...user, org: null }));
        setOrgName('');
    }, [newUser.type]);

    const addUser = useCallback((user) => {
        const now = new Date ();
        const fields = {};

        if (user.type === 'registry') {
            fields.registryName = user.org.name;
            fields.registryTlds = user.org.tlds;
        }
        else if (user.type === 'registrar') {
            fields.registrarName = user.org.name;
            fields.registrarIanaIds = user.org.registrars.map(({ ianaId }) => ianaId);
        }

        mongo.db('cart').collection('users').insertOne({
            email: user.email,
            name: user.name,
            type: user.type,
            reporterOrg: user.org?.name,
            tsCreated: now,
            tsUpdated: now,
            approved: true,
            active: true,
            registrarName: '',
            registrarIanaIds: [],
            registryName: '',
            registryTlds: [],
            ...fields,
        }).then((res) => {
            setShowAddUserModal(false);
            return app.emailPasswordAuth.registerUser(user.email, res.insertedId.toString());
            /* console.log(res);
            if (user.type === 'registrar') {
                mongo.db('cart').collection('registrars').updateMany({ ianaId: { $in: fields.registrarIanaIds } }, { $addToSet: { userEmails: user.email } });
            }
            else if (user.type === 'registry') {
                mongo.db('cart').collection('tlds').updateMany({ tld: { $in: fields.registryTlds } }, { $addToSet: { userEmails: user.email } });
            } */
        }).catch((error) => {
            console.error(error);
        })

    }, [setShowAddUserModal, mongo]);

    const pageSizes = [ 20, 50, 100 ];
    const [ pageSize, setPageSize ] = useState(pageSizes[0]);

    return (<>
        <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
                <Typography mt={1} variant="h4">Administration: Users &gt; {tabs[tab]}</Typography>
            </Grid>
            <Grid item>
                {tabs.length > 1 && <Tabs value={tab} onChange={(ev, tab) => setTab(tab)}>
                    {tabs.map((tab) => <Tab key={tab} icon={(tab === 'Pending Verification' && pending && <Chip label={pending}/>) || null} iconPosition='end' label={tab} />)}
                </Tabs>}
            </Grid>
        </Grid>

        {!loading && <DataGrid
            autoHeight
            rows={filteredUsers}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={pageSizes}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            // checkboxSelection
        />}

        {!loading && (tab === 1 || tab === 2) && <>
            <Box mt={3}>
                <DataGrid
                    autoHeight
                    rows={tab === 1 ? createdRegistrars : createdRegistries}
                    columns={tab === 1 ? registrarColumns : registryColumns}
                    pageSize={pageSize}
                    rowsPerPageOptions={pageSizes}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    disableSelectionOnClick
                    // checkboxSelection
                />
            </Box>
        </>}

        <Dialog open={verifyModal.show} onClose={() => setVerifyModal({ show: false })}>
            <DialogTitle>Are You Sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>{verifyModal.prompt}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setVerifyModal({ show: false })}>No</Button>
                <Button onClick={() => {
                    verifyModal.onYes();
                    setVerifyModal({ show: false });
                }}>Yes</Button>
            </DialogActions>
        </Dialog>

        <Fab sx={{ position: 'absolute', bottom: 'calc(4rem + 32px)', right: 32 }} color="primary" onClick={() => setShowAddUserModal(true)}><Add/></Fab>
        <Dialog open={showAddUserModal} onClose={() => setShowAddUserModal(false)}>
            <DialogTitle>Add User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To onboard a registry or registrar user, provide their email address, name (optional), account type, and the organization to which they belong. 
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    value={newUser.email}
                    onChange={(ev) => setNewUser((user) => ({ ...user, email: ev.target.value }))}
                    fullWidth
                    variant="standard"
                    required
                />
                <TextField
                    margin="dense"
                    id="name"
                    label="Name (optional)"
                    type="text"
                    value={newUser.name}
                    onChange={(ev) => setNewUser((user) => ({ ...user, name: ev.target.value }))}
                    fullWidth
                    variant="standard"
                />
                <FormControl component="fieldset" required>
                    <FormLabel component="legend">Account Type</FormLabel>
                    <RadioGroup row value={newUser.type} onChange={(ev) => setNewUser((user) => ({ ...user, type: ev.target.value }))}>
                        {accountTypes.filter(({ name }) => name !== 'admin').map(({ name, label }) => <FormControlLabel key={name} value={name} control={<Radio />} label={label}/>)}
                    </RadioGroup>
                </FormControl>
                {(newUser.type === 'registry' || newUser.type === 'registrar') && <Autocomplete
                    disablePortal
                    options={groups[newUser.type] || []}
                    filterOptions={(opts, state) => {
                        if (state.inputValue.length < 2)
                            return [];
                        const lc = state.inputValue.toLocaleLowerCase();
                        return opts
                            .filter((o) => o.lc.includes(lc))
                            .map((_) => ({ ..._, start: _.lc.startsWith(lc) }))
                            .sort((a, b) => b.start - a.start || a.name.localeCompare(b.name))
                            .slice(0, 5);
                    }}
                    getOptionLabel={(opt) => opt && opt.name}
                    renderOption={(props, opt) => <MenuItem {...props}>{newUser.type === 'registrar' ? (`${opt.name}${opt.registrars.length > 1 ? ` (${opt.registrars.length} linked)` : ''}`) : opt.name}</MenuItem>}
                    value={newUser.org}
                    onChange={(e, value) => setNewUser((user) => ({ ...user, org: value }))}
                    inputValue={orgName}
                    onInputChange={(e, value) => setOrgName(value)}
                    renderInput={(params) => <TextField {...params} required label={`${accountTypes.find((t) => t.name === newUser.type).label} Name`} InputLabelProps={{ shrink: true }} error={!newUser.org} />}
                />}
                {(newUser.type === 'reporter' || newUser.type === 'admin') && <TextField
                    autoFocus
                    margin="dense"
                    id="organization"
                    label={`Organization${newUser.type === 'reporter' ? ' (optional)' : ''}`}
                    type="text"
                    value={newUser.org ? newUser.org.name : ''}
                    onChange={(ev) => setNewUser((user) => ({ ...user, org: { name: ev.target.value } }))}
                    fullWidth
                    variant="standard"
                    required={newUser.type === 'admin'}
                />}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowAddUserModal(false)}>Cancel</Button>
                <Button onClick={() => addUser(newUser)}>Create</Button>
            </DialogActions>
        </Dialog>
    </>);
};

