import { s3init } from "./storage";

export const downloadEvidence = async (evidence) => {
    const accessKeyId = process.env.REACT_APP_HMAC_KEY;
    const secretAccessKey = process.env.REACT_APP_HMAC_SECRET;
    const storage = s3init({ accessKeyId, secretAccessKey });

    const files = [];

    for (const file of evidence) {
        await storage.getSignedUrlPromise('getObject', { Bucket: 'cart-evidence', Key: file.key }).then((url) => {
            files.push({ ...file, url });
        }).catch((error) => {
            console.error(error);
        });
    }

    return files;
};