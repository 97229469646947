import { useContext, useEffect, useState } from 'react';
import { RealmContext } from './ReactRealmProvider';
import { useParams, Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';
import { toolbarButtonsTheme } from './App';
import { AppBar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Toolbar } from '@mui/material';
import NetBeacon from './assets/NetB-2022-Logo_rgb_horizontal_KO.png'
import { BSON } from 'realm-web';

export const IncidentVote = () => {
    const { user, callFunction, login } = useContext(RealmContext);
    const { vote, incidentId, settingsId } = useParams();
    const score = vote === 'good' ? 1 : -1;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
        login('anonymous').then((u) => {
            console.log({ logIn: u });
            u.functions['setIncidentScore'](settingsId, incidentId, score).then((r) => {
                console.log({ setIncidentScore: r });
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                u.logOut().then((r) => {
                    console.log({ logOut: r });
                    setLoading(false);
                });
            });
        });
    }, []);

    if (! loading) {
        console.log(`${settingsId} voted ${vote} (${score}) for ${incidentId}`);
    }

    return (
        <>
        <ThemeProvider theme={toolbarButtonsTheme}>
            <AppBar position="sticky">
                <Toolbar>
                    <Box sx={{ mr: 4, flexGrow: 1 }}>
                        <Link to='/'><img src={NetBeacon} height={30} /></Link>
                    </Box>
                    {/* <Button component={Link} to="/privacy">Privacy Notice</Button> */}
                    {/* <Button component={Link} to="/terms">Terms of Use</Button> */}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
        <Container>
            <Dialog open={!Boolean(loading)}>
                <DialogTitle>Notice</DialogTitle>
                <DialogContent>
                    <DialogContentText>Your incident score has been tallied. You may close this window.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setLoading(true);
                    }}>Okay</Button>
                </DialogActions>
            </Dialog>
        </Container>
        </>
    );
};
