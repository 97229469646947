import {
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { ChartWrapper } from "./ChartWrapper";

export const LChart = ({ data, fullwidth = false, dataKey="_id", title, xs, height = 300, loading, colors, onClick = null }) => {
    return (
        <ChartWrapper fullwidth={fullwidth} idField={dataKey} title={title} xs={xs} height={height}>
            <LineChart data={data} loading={loading} onClick={onClick}>
                <Line type="monotone" dataKey="count" stroke={colors[0]} />
                <XAxis dataKey={dataKey} />
                <YAxis />
                <Tooltip />
                <Legend />
            </LineChart>
        </ChartWrapper>
    );
};

export default LChart;  