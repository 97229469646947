import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RealmContext } from '../../ReactRealmProvider';
import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, Slider, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@mui/material';
import { DataContext } from '../../contexts/DataProvider';
import { accountTypes } from '../../data/accountTypes';
import { Add, Delete, Password, Pending, Verified, Visibility, VisibilityOff, WarningAmber } from '@mui/icons-material';
import { abuseNames } from '../../data/abuseNames';
import { enrichments, freqLabels, freqMarks } from './constants';
import { useUserRole } from '../../hooks/useUserRole';
import { apiSpecs } from '../../data/apiSpecs';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const TABS = {
    IDENTITY: 'Identity',
    INBOUND_ALERTS: 'Inbound Reports',
    OUTBOUND_ALERTS: 'Outbound Reports',
    PASSWORD: 'Password',
    API: 'API',
    REPORTERS: 'Reporters',
};

export const Account = () => {
    const { user, callFunction, customData, Realm, app, mongo, refreshCustomData } = useContext(RealmContext);
    const data = useContext(DataContext);
    useUserRole('viewer');

    useEffect(() => {
        refreshCustomData();
    }, []);

    const [ notice, setNotice ] = useState();

    const initialAccount = useRef({
        name: customData.name || '',
        email: customData.email || '',
        approved: customData.approved || false,
        pending: customData.pending || false,
        type: customData.type || 'reporter',
        api: customData.api || {},
        reporterOrg: customData.reporterOrg || '',
        registryName: customData.registryName || '',
        registryTlds: customData.registryTlds || [],
        registrarName: customData.registrarName || '',
        registrarIanaIds: customData.registrarIanaIds || [],

        // abuseEmails: customData.abuseEmails || [],
        // enrichments: customData.enrichments || enrichments.map(({ name }) => name),
        // reportFrequency: customData.reportFrequency || 'realtime',
        // reportFormat: customData.reportFormat || 'xarf',
        // reporters: customData.reporters || [],
        // labels: customData.labels || [],
    }).current;

    const [account, setAccount] = useState(initialAccount);
    const [settings, setSettings] = useState({
        abuseEmails: [],
        enrichments: enrichments.map(({ name }) => name),
        reportFrequency: 'realtime',
        reportFormat: 'pretty',
        reportPeriod: 60,
        reporters: [],
        labels: [],
        reportReferredBy: '',
        reportHeader: '',
    });

    const [ reportReferredBy, setReportReferredBy ] = useState(settings.reportReferredBy);
    const [ reportHeader, setReportHeader ] = useState(settings.reportHeader);

    useEffect(() => {
        setReportReferredBy(settings.reportReferredBy);
    }, [settings.reportReferredBy]);

    useEffect(() => {
        setReportHeader(settings.reportHeader);
    }, [settings.reportHeader]);

    const accountOrg = useMemo(() => ({
        admin: account.reporterOrg,
        reporter: account.reporterOrg,
        registrar: account.registrarName,
        registry: account.registryName,
    }[account.type] || ''), [account]);

    const [ verify, setVerify ] = useState({
        type: account.type === 'admin' ? 'reporter' : account.type,
        name: accountOrg,
        org: { name: '' },
        terms: Boolean(account.type === 'reporter'),
    });
    const [ showVerifyModal, setShowVerifyModal ] = useState(false);

    useEffect(() => {
        if (account.type !== 'admin') {
            setVerify((verify) => ({ ...verify, type: account.type, name: accountOrg, org: { name: account.type === 'reporter' ? accountOrg : '' }, terms: Boolean(account.type === 'reporter') }));
        }
    }, [account.type, accountOrg]);

    const myApiSpecs = useMemo(() => {
        if (account.type !== 'reporter') return apiSpecs;

        const { paths, ...specs } = apiSpecs;
        return { ...specs, paths: { '/submit/reports': paths['/submit/reports'] } };
    }, [apiSpecs, account.type]);

    const noAlertSettings = !settings.abuseEmails?.find((s) => s.types.length);

    const [ password, setPassword ] = useState({ current: '', new1: '', new2: '' });
    const changingPassword = password.current.length >= 8;
    const passwordOkay = password.new1.length >= 8;
    const password2Okay = passwordOkay && password.new1 === password.new2;

    const [ initialSettings, setInitialSettings ] = useState(settings);

    const [showApiKey, setShowApiKey] = useState(false);
    const [tab, setTab] = useState(0);
    const tabs = useMemo(() => getTabs(account, user.providerType), [account]);

    useEffect(() => {
        if (true || account.type === 'reporter' || account.type === 'registrar' || account.type === 'registry') {
            mongo.db('cart').collection('settings').findOne({ type: account.type, name: accountOrg }).then((r) => {
                if (r) {
                    setSettings(r);
                    setInitialSettings(r);
                }
            });
        }
    }, [account.type]);

    const registryGroups = useMemo(() => data.registryGroups.map((_) => ({ ..._, lc: _.name.toLocaleLowerCase() })), [data]);
    const registrarGroups = useMemo(() => data.registrarGroups.map((_) => ({ ..._, lc: _.name.toLocaleLowerCase() })), [data]);
    const groups = { registry: registryGroups, registrar: registrarGroups };

    const [registryName, setRegistryName] = useState(account.registryName || '');
    const [registrarName, setRegistrarName] = useState(account.registrarName || '');

    const [selectedRegistry, setSelectedRegistry] = useState(registryGroups.find(({ name }) => name === account.registryName) || null);
    const [selectedRegistrar, setSelectedRegistrar] = useState(registrarGroups.find(({ name }) => name === account.registrarName) || null);

    useEffect(() => {
        if (tabs[tab] === TABS.REPORTERS) {
            // console.log(settings.reporters);
            const details = Object.fromEntries(settings.reporters.map(({ email, label, reputation }) => ([ email, { label, reputation } ])));
            mongo.db('cart').collection('incidents').aggregate([
                { $group: { _id: '$reporter.email', name: { $first: '$reporter.name' }, count: { $sum: 1 } } },
                { $sort: { _id: 1 } }
            ]).then((r) => setSettings((a) => ({ ...a, reporters: r.map(({ _id: email, ...rest }) => ({ email, ...rest, label: details[email]?.label || '', reputation: details[email]?.reputation || false })) })));
        }
    }, [tab]);

    const ready = (account.type === 'reporter' || account.type === 'admin') || (account.type === 'registrar' && selectedRegistrar) || (account.type === 'registry' && selectedRegistry);

    // const emailOkay = email.match(/^[^@\s]+@(?:[\w-]+\.)+(?:xn--)?\w\w+$/) && okDomain(email.replace(/^[^@]+@/, ''));
    // const endpointOkay = 

    const updateUserAccount = useCallback(async ({ approved, ...account }) => {
        mongo.db('cart').collection('users').updateOne({ realmId: user.id }, { $set: account }).then(() => refreshCustomData());
    }, [mongo, user, refreshCustomData]);

    const updateSettings = useCallback(async (type, name, settings) => {
        mongo.db('cart').collection('settings').updateOne({ type, name }, { $set: settings }, { upsert: true }).then((r) => console.log(r));
    }, [mongo, user]);

    const updatePassword = useCallback(async (password) => {
        app.logIn(Realm.Credentials.emailPassword(account.email, password.current)).then(() => {
            app.emailPasswordAuth.callResetPasswordFunction({ email: customData.email, password: password.new1 }).then(() => {
                setNotice("Your password has been successfully updated.");
                setPassword({ current: '', new1: '', new2: '' });
            }).catch((error) => {
                setNotice("We could not update your password. Please try again later.");
                console.log(error);
            })
        }).catch((error) => {
            setNotice("You have not entered your current password.");
            console.log(error);
            setPassword((p) => ({ ...p, current: '' }));
        })
    }, [app, Realm, account]);

    const requestVerification = useCallback(({ type, org }) => {
        if (type === 'registrar') {
            setSelectedRegistrar(org);
            callFunction('requestVerification', { type, registrarName: org.name, registrarIanaIds: org.registrars.map((r) => r.ianaId) }).then(() => {
                setAccount((a) => ({ ...a, pending: true, approved: false, type, registrarName: org.name, registrarIanaIds: org.registrars.map((r) => r.ianaId) }));
                refreshCustomData();
            });
        }
        else if (type === 'registry') {
            setSelectedRegistry(org);
            callFunction('requestVerification', { type, registryName: org.name, registryTlds: org.tlds }).then(() => {
                setAccount((a) => ({ ...a, pending: true, approved: false, type, registryName: org.name, registryTlds: org.tlds }));
                refreshCustomData();
            });
        }
        else if (type === 'reporter') {
            callFunction('requestVerification', { type, reporterOrg: org.name }).then(() => {
                setAccount((a) => ({ ...a, pending: true, approved: false, type, reporterOrg: org.name }));
                refreshCustomData();
            });
        }
        setShowVerifyModal(false);
    }, [updateUserAccount]);

    const newApiKey = async () => {
        await refreshCustomData();

        // check realm for api key
        const hasToken = (await user.apiKeys.fetchAll()).find((k) => k.name === 'token');

        // if realm has the token but the user doc doesn't, delete the realm key
        if (hasToken) await user.apiKeys.delete(hasToken._id);

        // create api key
        const api = await user.apiKeys.create('token');
        await mongo.db('cart').collection('users').updateOne({ email: account.email }, { $set: { api } })
        .then(() => {
            setAccount((account) => ({ ...account, api }));
        })
        .catch((error) => {
            user.apiKeys.delete(api.id);
            console.error(error);
        });
    }

    useEffect(() => {
        if (account.type === 'reporter') {
            // setAccount((a) => ({ ...a, registrarName: '', registrarIanaIds: [], registryName: '', registryTlds: [] }));
        }
        else if (account.type === 'registrar') {
            // setAccount((a) => ({ ...a, reporterOrg: '', registryName: '', registryTlds: [] }));
        }
        else if (account.type === 'registry') {
            // setAccount((a) => ({ ...a, reporterOrg: '', registrarName: '', registrarIanaIds: [] }));
        }
    }, [account.type]);

    useEffect(() => {
        if (selectedRegistry) {
            setAccount((a) => ({ ...a, registryName: selectedRegistry.name, registryTlds: selectedRegistry.tlds }));
        }
    }, [selectedRegistry]);

    useEffect(() => {
        if (selectedRegistrar) {
            setAccount((a) => ({ ...a, registrarName: selectedRegistrar.name, registrarIanaIds: selectedRegistrar.registrars.map(({ ianaId }) => ianaId) }));
        }
    }, [selectedRegistrar]);

    const now = useMemo(() => new Date (), []); // [ now ] = useState(new Date ());

    const [ apiArgs, setApiArgs ] = useState({
        // since: new Date (now.getTime() - 60000 * now.getTimezoneOffset()).toISOString().substr(0, 16),
        // until: new Date (now.getTime() - 60000 * now.getTimezoneOffset()).toISOString().substr(0, 16),
    });

    const [ apiResponse, setApiResponse ] = useState({});

    const apiSend = useCallback((endpoint, args) => {
        const query = new URLSearchParams (args);

        console.log({ endpoint, query: query.toString() });

        fetch(`${process.env.REACT_APP_NETBEACON_API}/${endpoint}?${query.toString()}`, {
            headers: {
                Authorization: `Bearer ${account.api.key}`
            },
        }).then((r) => r.json()).then((r) => {
            console.log(r);
            setApiResponse(r);
        })
    }, [account]);

    return (<>
        <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
                <Typography mt={1} variant="h4">Settings &gt; {tabs[tab]}</Typography>
            </Grid>
            <Grid item>
                {tabs.length > 1 && <Tabs value={tab} onChange={(ev, tab) => setTab(tab)}>
                    {tabs.map((tab) => <Tooltip key={tab} arrow title={(tab === TABS.INBOUND_ALERTS && noAlertSettings) ? `You have not set up any email addresses to receive abuse incidents` : ''}>
                        <Tab label={tab} iconPosition='start' icon={(tab === TABS.INBOUND_ALERTS && noAlertSettings) ? <WarningAmber/> : null} />
                    </Tooltip>)}
                </Tabs>}
            </Grid>
        </Grid>

        <Dialog open={Boolean(notice)}>
            <DialogTitle>Notice</DialogTitle>
            <DialogContent>
                <DialogContentText>{notice}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setNotice()}>Okay</Button>
            </DialogActions>
        </Dialog>

        {/* <Button sx={{mr:1}} variant='outlined' component={Link} to="/account/api">Test API</Button>
        <Button variant='outlined' component={Link} to="/account/reports">My Reports</Button> */}

        <Grid container spacing={2} mt={2} alignItems='flex-start'>
            {tabs[tab] === TABS.IDENTITY && <>
            <Grid item xs={8}>
                <Box>
                    <TextField
                        fullWidth
                        // variant='standard'
                        type="text"
                        value={account.email}
                        disabled={true}
                        // onChange={(e) => setAccount((a) => ({ ...a, reporterOrg: e.target.value }))}
                        label="Your Email Address"
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
            </Grid>
            <Grid item xs={4}/>
            <Grid item xs={8}>
                {(true || account.type === 'admin' || account.type === 'reporter') && <>
                    <Box>
                        <TextField
                            fullWidth
                            type="text"
                            value={account.name}
                            // disabled={account.type === 'registrar' || account.type === 'registry'}
                            onChange={(e) => setAccount((a) => ({ ...a, name: e.target.value }))}
                            label="Your Name"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </>}
                {false && account.type === 'registry' && <>
                    <Box>
                        <Autocomplete
                            disablePortal
                            options={registryGroups}
                            filterOptions={(opts, state) => {
                                if (state.inputValue.length < 2)
                                    return [];
                                const lc = state.inputValue.toLocaleLowerCase();
                                return opts
                                    .filter((o) => o.lc.includes(lc))
                                    .map((_) => ({ ..._, start: _.lc.startsWith(lc) }))
                                    .sort((a, b) => b.start - a.start || a.name.localeCompare(b.name))
                                    .slice(0, 25);
                            }}
                            getOptionLabel={(opt) => opt && opt.name}
                            value={selectedRegistry}
                            onChange={(e, value) => setSelectedRegistry(value)}
                            inputValue={registryName}
                            onInputChange={(e, value) => setRegistryName(value)}
                            renderInput={(params) => <TextField {...params} required label="Registry Name" InputLabelProps={{ shrink: true }} error={!account.registryName} />} />
                    </Box>
                </>}
                {false && account.type === 'registrar' && <>
                    <Box>
                        <Autocomplete
                            disablePortal
                            options={registrarGroups}
                            filterOptions={(opts, state) => {
                                if (state.inputValue.length < 2)
                                    return [];
                                const lc = state.inputValue.toLocaleLowerCase();
                                return opts
                                    .filter((o) => o.lc.includes(lc))
                                    .map((_) => ({ ..._, start: _.lc.startsWith(lc) }))
                                    .sort((a, b) => b.start - a.start || a.name.localeCompare(b.name))
                                    .slice(0, 25);
                            }}
                            getOptionLabel={(opt) => opt && opt.name}
                            renderOption={(props, opt) => <MenuItem {...props}>{`${opt.name}${opt.registrars.length > 1 ? ` (${opt.registrars.length} linked)` : ''}`}</MenuItem>}
                            value={selectedRegistrar}
                            onChange={(e, value) => setSelectedRegistrar(value)}
                            inputValue={registrarName}
                            onInputChange={(e, value) => setRegistrarName(value)}
                            renderInput={(params) => <TextField {...params} required label="Registrar Name" InputLabelProps={{ shrink: true }} error={!account.registrarName} />} />
                    </Box>
                </>}
            </Grid>
            <Grid item xs={4}>
                <Box sx={{height: '56px'}} display='flex' alignItems='center'>
                {!(account.pending || account.approved) && <>
                    <Button variant='outlined' onClick={() => setShowVerifyModal(true)}>
                        Verify as a Reporter, Registrar, or Registry
                    </Button>

                        {/* <InputLabel id="account-type-select-label">Account Type</InputLabel>
                        <Select
                        labelId="account-type-select-label"
                        label="Account Type"
                        value={account.type}
                        onChange={(e) => setAccount((u) => ({ ...u, type: e.target.value }))}
                        >
                        {accountTypes.map(({ name, label }) => <MenuItem key={name} value={name}>{label}</MenuItem>)}
                        </Select> */}
                </>}
                {(account.pending || account.approved) && <>
                    <Chip color={account.approved ? 'success' : 'error'} label={account.approved ? 'Verified' : 'Pending Verification'} icon={account.approved ? <Verified/> : <Pending/> } />
                </>}
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Box>
                    <TextField
                        fullWidth
                        // variant='standard'
                        type="text"
                        value={account[{registrar: 'registrarName', registry: 'registryName'}[account.type] || 'reporterOrg']}
                        disabled={account.type === 'registrar' || account.type === 'registry'}
                        onChange={(e) => setAccount((a) => ({ ...a, reporterOrg: e.target.value }))}
                        label="Your Organization (optional)"
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{height: '56px'}} display='flex' alignItems='center'>
                    <Chip color={account.approved ? 'success' : 'error'} label={account.type.replace(/^(.)/, (c) => c.toLocaleUpperCase())}/>
                </Box>
            </Grid>
            {account.verified && <Grid item xs={12}>
                {account.type === 'registrar' && selectedRegistrar && (<>
                    <Typography variant="h6">Linked Registrars:</Typography>
                    {selectedRegistrar.registrars.map((reg) => <Chip sx={{margin: 0.25}} key={reg.ianaId} label={`${reg.name} (IANA ${reg.ianaId})`}/>)}
                </>)}
                {account.type === 'registry' && account.registryTlds?.length > 0 && (<>
                    <Typography variant="h6">Registry TLDs:</Typography>
                    {account.registryTlds.sort().map((id) => <Chip sx={{margin: 0.25}} key={id} label={id}/>)}
                </>)}
            </Grid>}
            </>}

            {tabs[tab] === TABS.PASSWORD && <>
            <Grid item xs={8}>
                <Box mb={4}>
                    <TextField
                        fullWidth
                        type="password"
                        value={password.current}
                        onChange={(e) => setPassword((p) => ({ ...p, current: e.target.value }))}
                        label="Your Current Password"
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box mb={1}>
                    <TextField
                        fullWidth
                        type="password"
                        disabled={!changingPassword}
                        value={password.new1}
                        onChange={(e) => setPassword((p) => ({ ...p, new1: e.target.value }))}
                        label="Your New Password"
                        InputLabelProps={{ shrink: true }}
                        error={changingPassword && !passwordOkay}
                        helperText={changingPassword ? (!passwordOkay ? "Password is too short" : "Password is long enough") : <>&nbsp;</>}
                />
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        type="password"
                        disabled={!changingPassword || !passwordOkay}
                        value={password.new2}
                        onChange={(e) => setPassword((p) => ({ ...p, new2: e.target.value }))}
                        label="Confirm Your New Password"
                        InputLabelProps={{ shrink: true }}
                        error={changingPassword && !password2Okay}
                        helperText={changingPassword ? (!password2Okay ? "Passwords don't match" : "Passwords match") : <>&nbsp;</>}
                />
                </Box>
            </Grid>
            <Grid item xs={4}/>
            </>}

            {tabs[tab] === TABS.INBOUND_ALERTS && <>
            {(account.type === 'registrar' || account.type === 'registry') && <>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        These alert settings are shared across your organization. Other verified {account.registrarName || account.registryName} users may alter these settings.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Receive Abuse Incidents By Email</FormLabel>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Abuse Email Address</TableCell>
                                    {Object.entries(abuseNames).map(([ name, label ]) => <TableCell align='center' key={name}>{label}</TableCell>)}
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {settings.abuseEmails.map(({ email, types }, index) => <TableRow key={index}>
                                    <TableCell>
                                        <TextField
                                            fullWidth
                                            type="email"
                                            variant='standard'
                                            //sx={{width: '360px'}}
                                            value={email}
                                            onChange={({ target: { value }}) => setSettings((a) => { return ({ ...a, abuseEmails: [
                                                ...a.abuseEmails.slice(0, index),
                                                { ...a.abuseEmails[index], email: value },
                                                ...a.abuseEmails.slice(index+1),
                                            ] }) })}
                                            placeholder="email@domain.com"
                                        />
                                    </TableCell>
                                    {Object.keys(abuseNames).map((name) => <TableCell key={name} align='center'>
                                        <Checkbox
                                            checked={types.includes(name)}
                                            onChange={(event, checked) => setSettings((a) => ({ ...a, abuseEmails: [
                                                ...a.abuseEmails.slice(0, index),
                                                { email: a.abuseEmails[index].email, types: checked ? [ ...a.abuseEmails[index].types, name ] : a.abuseEmails[index].types.filter((t) => t !== name) },
                                                ...a.abuseEmails.slice(index+1),
                                            ] }))}
                                        />
                                    </TableCell>)}
                                    <TableCell>
                                        <IconButton onClick={() => setSettings((a) => ({ ...a, abuseEmails: [
                                            ...a.abuseEmails.slice(0, index),
                                            ...a.abuseEmails.slice(index+1),
                                        ]}))}><Delete/></IconButton>
                                    </TableCell>
                                </TableRow>)}
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Button onClick={() => setSettings((a) => ({ ...a, abuseEmails: [ ...a.abuseEmails, { email: '', types: [] }]}))}>
                                            <Add/>
                                            Add another email address
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>                    
                </Grid>
                <Grid item xs={6}>
                    <FormControl>
                        <FormLabel id="enrichment-selections">Enrichment Pipeline</FormLabel>
                        <List>
                            {enrichments.map(({ name, primary, secondary }) => <ListItem key={name} disablePadding>
                                <ListItemButton dense onClick={(event) => setSettings((a) => ({ ...a, enrichments: event.target.checked ? [...a.enrichments, name] : a.enrichments.filter((e) => e !== name) }))}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={settings.enrichments.includes(name)}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                                <ListItemText sx={{width: '100%'}} primary={primary} secondary={secondary}/>
                            </ListItem>)}
                        </List>
                    </FormControl>
                </Grid>
                <Grid item container xs={6}>
                    <Grid item xs={12} mb={2}>
                        <FormControl>
                            <FormLabel id="report-format-label">Abuse Report Email Format</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="report-format-label"
                                name="report-format-group"
                                value={settings.reportFormat || null}
                                onChange={(e, reportFormat) => setSettings((a) => ({ ...a, reportFormat }))}
                            >
                                <FormControlLabel value="xarf" control={<Radio />} label="XARF (JSON)" />
                                <FormControlLabel value="pretty" control={<Radio />} label="Pretty (field: value)" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <FormControl>
                            <FormLabel id="report-frequency-label">Frequency of Emails</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="report-frequency-label"
                                name="report-frequency-group"
                                value={settings.reportFrequency || null}
                                onChange={(e, reportFrequency) => setSettings((a) => ({ ...a, reportFrequency }))}
                            >
                                <FormControlLabel value="realtime" control={<Radio />} label="Real Time" />
                                <FormControlLabel value="digest" control={<Radio />} label="Digest" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {settings.reportFrequency === 'digest' && <>
                        <Grid item xs={12}>
                            <FormLabel id="report-digest-frequency-label">Digest Frequency</FormLabel>
                            <Slider
                                aria-labelledby="report-digest-frequency-label"
                                value={settings.reportPeriod || null}
                                onChange={(e, reportPeriod) => setSettings((a) => ({ ...a, reportPeriod }))}
                                valueLabelDisplay='auto'
                                valueLabelFormat={(h) => `Every ${freqLabels[h]}`}
                                marks={freqMarks}
                                step={null}
                                min={30}
                                max={60*24}
                            />
                        </Grid>
                    </>}
                </Grid>

                {/*<Grid item xs={4}>
                    <FormControlLabel control={
                        <Switch
                            checked={Boolean(settings.abuseEndpoint && settings.reportByEndpoint)}
                            onChange={(e, reportByEndpoint) => setSettings((a) => ({ ...a, reportByEndpoint }))}
                            disabled={!settings.abuseEndpoint}
                        />}
                        label="Receive Abuse Reports Via Endpoint"
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        type="url"
                        value={settings.abuseEndpoint}
                        onChange={(e) => setSettings((a) => ({ ...a, abuseEndpoint: e.target.value }))}
                        label="Abuse Endpoint"
                        InputLabelProps={{ shrink: true }}
                        required={settings.reportByEndpoint}
                    />
                </Grid>*/}
            </>}
            </>}

            {tabs[tab] === TABS.OUTBOUND_ALERTS && <>
            {(account.approved) && <>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        These settings change how reports sent from you via NetBeacon appear to Recipients.{' '}
                        As a NetBeacon Verified Reporter you can use these settings to ensure recipients are aware of who is making the report.{' '}
                        These settings are shared across all users in your organization.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Report Subject Referrer Text</FormLabel>
                    <Typography>
                        This setting changes the "Referred by" organization name in the subject of reports sent via NetBeacon.
                        It should be brief. A typical report subject line is: <br/>"NetBeacon: example[dot]tld: phishing abuse incident - Referred by <b>%OrganizationName%</b>"
                    </Typography>
                    <Grid item xs={12} mt={1}>
                        <TextField
                            type={'text'}
                            value={reportReferredBy}
                            onChange={(event) => setReportReferredBy(event.target.value)}
                            label="Referrer Text"
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '30rem' }}
                            inputProps={{
                                maxLength: 40,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} container spacing={1} mt={1}>
                        <Grid item><Button variant="outlined" disabled={settings.reportReferredBy === reportReferredBy} onClick={() => setReportReferredBy(settings.reportReferredBy)}>Reset</Button></Grid>
                        <Grid item><Button variant="contained" disabled={settings.reportReferredBy === reportReferredBy} onClick={() => {
                            setSettings((settings) => ({ ...settings, reportReferredBy }));
                            updateSettings(account.type, accountOrg, { ...settings, reportHeader });
                        }}>Save</Button></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Report Content Header</FormLabel>
                    <Typography>
                        This setting allows you to include text at the top of the body of every report sent from your organization via NetBeacon.{' '}
                        You can leave this blank or provide information you think is useful for report recipients, including contact information or expectations.{' '}
                        Content provided by your organization is subject to the NetBeacon Terms of Service.
                    </Typography>
                    <Typography mt={2}>
                        An example would be:
                        <blockquote>
                            This report was submitted to you via NetBeacon from a representative of Your Organization.{' '}
                            Your Organization is an Internet Security firm that specializes in reports of phishing.{' '}
                            If you have any questions or concerns about this report, please respond to the Reply-To address of this email, or by emailing contact@organization.tld
                        </blockquote>
                    </Typography>
                    <Grid item xs={12} mt={1}>
                        <TextField
                            type={'text'}
                            rows={5}
                            multiline
                            value={reportHeader}
                            onChange={(event) => setReportHeader(event.target.value)}
                            label="Report Header"
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '40rem' }}
                            inputProps={{
                                maxLength: 1000,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} container spacing={1} mt={1}>
                        <Grid item><Button variant="outlined" disabled={settings.reportHeader === reportHeader} onClick={() => setReportHeader(settings.reportHeader)}>Reset</Button></Grid>
                        <Grid item><Button variant="contained" disabled={settings.reportHeader === reportHeader} onClick={() => {
                            setSettings((settings) => ({ ...settings, reportHeader }));
                            updateSettings(account.type, account[`${account.type}Name`] || account[`${account.type}Org`], { ...settings, reportHeader });
                        }}>Save</Button></Grid>
                    </Grid>
                </Grid>
            </>}
            </>}

            {tabs[tab] === TABS.API && <>
            {/*(account.type !== 'reporter') &&*/ <>
            <Grid item xs={12} container flex alignItems='center' spacing={2}>
                <Grid item xs={4} container flex justifyContent='flex-end'>
                    <Button variant="contained" onClick={() => newApiKey()}>Generate New API Key</Button>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        type={showApiKey ? 'text' : 'password'}
                        value={account.api?.key || ''}
                        label="API Key"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => setShowApiKey((v) => !v)}>{showApiKey ? <VisibilityOff/> : <Visibility/>}</IconButton>
                            </InputAdornment>
                        }}
                    />
                </Grid>

                <Grid item xs={4}/>
                <Grid item xs={8}>
                    <SwaggerUI
                        docExpansion='list'
                        spec={myApiSpecs}
                        showMutatedRequest={true}
                        requestInterceptor={(req) => {
                            req.headers.Authorization = `Bearer ${account.api?.key}`;
                            return req;
                        }}
                    />
                </Grid>

                {/* <Grid item xs={4} container flex justifyContent='flex-end'>
                    <Button variant='contained' onClick={() => apiSend('receive/incidents', apiArgs)}>Receive Abuse Incidents</Button>
                </Grid>

                <Grid item xs={8} container>
                    <Grid item xs={4}>
                        <TextField
                            label='Reports After This Date'
                            InputLabelProps={{ shrink: true }}
                            type='datetime-local' value={apiArgs.since || ''} onChange={(ev) => setApiArgs((args) => ({ ...args, since: ev.target.value }))}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            label='Reports Before This Date'
                            InputLabelProps={{ shrink: true }}
                            type='datetime-local' value={apiArgs.until || ''} onChange={(ev) => setApiArgs((args) => ({ ...args, until: ev.target.value }))}/>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            label='Maximum Reports Returned'
                            InputLabelProps={{ shrink: true }}
                            type='number' value={apiArgs.limit || 3} onChange={(ev) => setApiArgs((args) => ({ ...args, limit: ev.target.value }))}/>
                    </Grid>
                </Grid>

                <Grid item xs={4}/>
                <Grid item xs={8}>
                    <xmp>{JSON.stringify(apiResponse, null, 4)}</xmp>
                </Grid> */}
            </Grid>

            </>}
            </>}

            {tabs[tab] === TABS.REPORTERS && <>
            <Grid item xs={12}>
                <Typography variant='h6'>
                    This information is shared across your organization. Other verified {account.registrarName || account.registryName} users may modify this table.
                </Typography>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Incidents</TableCell>
                                    <TableCell>Label</TableCell>
                                    <TableCell>Reputation</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {settings.reporters.map(({ email, name, count, label, reputation }, i) => {
                                    return <TableRow key={email}>
                                        <TableCell>{email}</TableCell>
                                        <TableCell>{name}</TableCell>
                                        <TableCell>{count}</TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                value={label}
                                                onChange={(event, label) => {
                                                    setSettings((a) => ({
                                                        ...a,
                                                        reporters: [ ...a.reporters.slice(0, i), { ...a.reporters[i], label }, ...a.reporters.slice(i+1) ],
                                                        labels: settings.labels.includes(label) ? settings.labels : [ ...settings.labels, label ],
                                                    }));
                                                }}
                                                getOptionLabel={(option) => option || ''}
                                                options={settings.labels.filter((l) => l)}
                                                selectOnFocus
                                                handleHomeEndKeys
                                                freeSolo
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={Boolean(reputation)}
                                                onChange={(e, reputation) => setSettings((a) => ({ ...a, reporters: [ ...a.reporters.slice(0, i), { ...a.reporters[i], reputation }, ...a.reporters.slice(i+1) ] }))}
                                            />
                                        </TableCell>
                                    </TableRow>;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>                    
                </Grid>
            </Grid>
            
            </>}
            
            {tabs[tab] !== TABS.OUTBOUND_ALERTS && <>
            <Grid item xs={12} container justifyContent='flex-end' spacing={1}>
                {tabs[tab] !== TABS.PASSWORD && <Grid item>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setAccount(initialAccount);
                            setSettings(initialSettings);
                        }}
                    >Reset</Button>
                </Grid>}
                <Grid item>
                    <Button
                        disabled={tabs[tab] === TABS.PASSWORD ? (!password2Okay) : !ready}
                        variant="contained"
                        onClick={() => {
                            if (tabs[tab] === TABS.PASSWORD) {
                                updatePassword(password);
                            }
                            else {
                                updateUserAccount(account);
                                updateSettings(account.type, account[`${account.type}Name`] || account[`${account.type}Org`], settings);
                            }
                        }}
                    >{tabs[tab] === TABS.PASSWORD ? 'Update Password' : 'Save'}</Button>
                </Grid>
            </Grid>
            </>}
        </Grid>
        {/* <xmp>{JSON.stringify(account, null, 2)}</xmp> */}
        <Dialog open={showVerifyModal} onClose={() => setShowVerifyModal(false)}>
            <DialogTitle>Request Verification</DialogTitle>
            <DialogContent sx={{minHeight: '20rem'}}>
                <DialogContentText>
                    Select the organization you represent to request verification.
                </DialogContentText>
                <FormControl component="fieldset" required>
                    <RadioGroup row value={verify.type} onChange={(ev) => setVerify((v) => ({
                        type: ev.target.value,
                        name: (ev.target.value === 'reporter' && account.reporterOrg) || '',
                        org: { name: (ev.target.value === 'reporter' && account.reporterOrg) || '' },
                        terms: Boolean(ev.target.value === 'reporter'),
                    }))}>
                        {accountTypes.filter(({ name }) => name === 'reporter' || name === 'registrar' || name === 'registry').map(({ name, label }) => <FormControlLabel key={name} value={name} control={<Radio />} label={label}/>)}
                    </RadioGroup>
                </FormControl>
                {verify.type === 'reporter' && <>
                    <TextField
                        required
                        fullWidth
                        value={verify.name}
                        onChange={(ev) => setVerify((verify) => ({ ...verify, name: ev.target.value, org: { ...verify.org, name: ev.target.value } }))}
                        label={`${accountTypes.find((t) => t.name === verify.type).label} Name`}
                        InputLabelProps={{ shrink: true }}
                        error={!verify.name}
                    />
                </>}
                {verify.type !== 'reporter' && <><Autocomplete
                    disablePortal
                    fullWidth
                    options={groups[verify.type] || []}
                    filterOptions={(opts, state) => {
                        if (state.inputValue.length < 2)
                            return [];
                        const lc = state.inputValue.toLocaleLowerCase();
                        return opts
                            .filter((o) => o.lc.includes(lc))
                            .map((_) => ({ ..._, start: _.lc.startsWith(lc) }))
                            .sort((a, b) => b.start - a.start || a.name.localeCompare(b.name))
                            .slice(0, 5);
                    }}
                    getOptionLabel={(opt) => opt && opt.name}
                    renderOption={(props, opt) => <MenuItem {...props}>{verify.type === 'registrar' ? (`${opt.name}${opt.registrars.length > 1 ? ` (${opt.registrars.length} linked)` : ''}`) : opt.name}</MenuItem>}
                    value={verify.org}
                    onChange={(e, value) => setVerify((v) => ({ ...v, org: value }))}
                    inputValue={verify.name || ''}
                    onInputChange={(e, value) => setVerify((v) => ({ ...v, name: value }))}
                    renderInput={(params) => <TextField {...params} fullWidth required label={`${accountTypes.find((t) => t.name === verify.type).label} Name`} InputLabelProps={{ shrink: true }} error={!verify.org} />}
                /></>}
                {(verify.type === 'registrar' || verify.type === 'registry') && <>
                <FormControlLabel control={<Checkbox
                    required
                    value={verify.terms}
                    onChange={(e, terms) => setVerify((v) => ({ ...v, terms }))} />} label={<>I have read and accept the <a href="/recipient-terms" target="_blank" rel="nofollow">Recipient Terms of Use</a>.</>}
                />
                </>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowVerifyModal(false)}>Cancel</Button>
                <Button disabled={!verify.terms} onClick={() => requestVerification(verify)}>Confirm</Button>
            </DialogActions>
        </Dialog>
    </>);
};

const getTabs = ({ approved, type }, providerType) => {
    const password = providerType === 'local-userpass' ? [ TABS.PASSWORD ] : [];
    const outbound = approved ? [ TABS.OUTBOUND_ALERTS ] : [];

    if (type === 'reporter') {
        return [
            TABS.IDENTITY,
            ...password,
            ...outbound,
            TABS.API,
        ];
    }
    else if (type === 'admin') {
        return [
            TABS.IDENTITY,
            ...password,
            TABS.OUTBOUND_ALERTS,
            TABS.API,
        ];
    }
    else {
        return [
            TABS.IDENTITY,
            ...password,
            TABS.INBOUND_ALERTS,
            ...outbound,
            TABS.REPORTERS,
            TABS.API,
        ];
    }
};