import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { RealmContext } from './ReactRealmProvider';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AppBar, Backdrop, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider, Toolbar, Typography } from '@mui/material';
import { Login } from './routes/Login';
import { Logout } from './routes/Logout';
import { Signup } from './routes/Signup';
import { DataContext } from './contexts/DataProvider';
import { toolbarButtonsTheme } from './App';
import { MainScreen } from "./routes/MainScreen";
import { Account } from "./routes/account";
import { UsersAdmin } from "./routes/admin/Users";
import { RegistriesAdmin } from './routes/admin/Registries';
import { RegistrarsAdmin } from './routes/admin/Registrars';
import { UserToolbar } from "./components/UserToolbar";
import { Incidents } from './routes/Incidents';
import { ReportRoot, MakeNewReport } from './routes/MakeNewReport';
import { ReportHistory } from './routes/account/ReportHistory';
import { TestAPI } from './routes/account/TestAPI';
import { Reports } from './routes/Reports';
import { Terms } from './Terms';
import { Privacy } from './Privacy';
import { RecipTerms } from './RecipTerms';
import cleandns from './assets/cleandns.png';
import NetBeacon from './assets/NetB-2022-Logo_rgb_horizontal_KO.png'
import { LanguageContext } from './contexts/LanguageProvider';
import { CCtldsAdmin } from './routes/admin/CCtldsAdmin';
import { Dashboard } from './routes/admin/Dashboard';

const emptyObject = {};

export const Template = () => {
    const { user, mongo, customData = emptyObject } = useContext(RealmContext);
    const { loading } = useContext(DataContext);
    const loggedIn = user?.isLoggedIn;
    const isAdmin = customData.type === 'admin';
    const { active, approved } = customData;
    const location = useLocation();

    useEffect(() => {
        if (loggedIn) {
            mongo.db('cart').collection('users').updateOne({ realmId: user.id }, { $set: { tsLastLogin: new Date () }}).then(() => {
                console.log(`updated tsLastLogin`);
            })
        }
    }, [loggedIn, user]);

    return (
        <div>
            <TopBar/>
            <BottomBar/>
            <Box mt={1} sx={{marginBottom: '5rem', marginLeft: '2rem', marginRight: '2rem'}}>
                {!loggedIn ? <Switch>
                    <Route path="/terms"><Terms/></Route>
                    <Route path="/recipient-terms"><RecipTerms/></Route>
                    <Route path="/privacy"><Privacy/></Route>
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/signup" component={Signup} />
                    <Redirect to={{ pathname: "/login", state: { pathname: window.location.pathname } }}/>
                </Switch> : <>
                    <SessionTimeout/>
                    {active ? <Switch>
                        <Redirect from="/login" to={location.state?.pathname || "/"} />
                        <Route path="/logout" component={Logout} />
                        {loading && <Route>
                            <Backdrop open>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </Route>}
                        {approved && <Route path="/account/reports" component={ReportHistory} />}
                        <Route path="/account/api" component={TestAPI} />
                        <Route path="/account" component={Account} />
                        {!customData.type && <Redirect from="/" to="/account" />}
                        {isAdmin && <Route path="/admin/users" component={UsersAdmin} />}
                        {/* {isAdmin && <Route path="/admin/registries" component={RegistriesAdmin} />} */}
                        {isAdmin && <Route path="/admin/cctlds" component={CCtldsAdmin} />}
                        {isAdmin && <Route path="/admin/registrars" component={RegistrarsAdmin} />}
                        {isAdmin && <Route path="/admin/dashboard"><Dashboard mode="admin"/></Route>}
                        {customData.type === 'registry' && <Route path="/dashboard"><Dashboard mode="registry"/></Route>}
                        <Route path="/reports" component={Reports} />
                        {approved && customData.type !== 'reporter' && <Route path="/incidents" component={Incidents} />}
                        <Route path="/new-report/:type?/:id?">
                            <ReportRoot>
                                <MakeNewReport/>
                            </ReportRoot>
                        </Route>
                        <Redirect from="/signup" to="/" />
                        <Route exact path="/" component={MainScreen} />
                        <Route path="/terms"><Terms/></Route>
                        <Route path="/recipient-terms"><RecipTerms/></Route>
                        <Route path="/privacy"><Privacy/></Route>
                        <Route>[404 Not Found]</Route>
                    </Switch> : <Switch>
                    <Route exact path="/">Your account is not currently active.</Route>
                    <Route path="/logout" component={Logout} />
                    <Redirect to="/" />
                </Switch>}
                </>}
            </Box>
        </div>
    );
};


const TopBar = () => {
    const language = useContext(LanguageContext);
    const { user, customData = emptyObject } = useContext(RealmContext);
    const loggedIn = user?.isLoggedIn;
    
    return <ThemeProvider theme={toolbarButtonsTheme}>
        <AppBar position="sticky">
            <Toolbar>
                <Box sx={{ mr: 4, [(user && customData.type) ? 'flexShrink' : 'flexGrow']: 1 }}>
                    <img src={NetBeacon} height={30} />
                </Box>
                {<>
                    {loggedIn && <UserToolbar />}
                    {!loggedIn && <Button component={Link} to="/signup">{language.signUp}</Button>}
                    {!loggedIn && <Button component={Link} to="/login">{language.signIn}</Button>}
                </>}
            </Toolbar>
        </AppBar>
    </ThemeProvider>;
};

const BottomBar = () => {
    const { customData = emptyObject } = useContext(RealmContext);

    return <ThemeProvider theme={toolbarButtonsTheme}>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box>
                    <Typography>
                        <Link to="/privacy" style={{ color: 'white' }}>Privacy Notice</Link>
                    </Typography>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                    {/* <span>PIR</span> */}
                    <span style={{marginLeft: '2rem', marginRight: '2rem'}}>Powered by <a href="https://cleandns.com"><img style={{ height: '1rem' }} src={cleandns} /></a></span>
                    {/* <span>DNSAI</span> */}
                </Box>
                <Box>
                    <Typography>
                        <Link to="/terms" style={{ color: 'white' }}>Terms of Use</Link>
                        {(customData.type === 'registrar' || customData.type === 'registry') && <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/recipient-terms" style={{ color: 'white' }}>Recipient Terms of Use</Link>
                        </>}
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    </ThemeProvider>;
};

const SessionTimeout = ({ expiresAfter=60*50, warningAfter=60*40 }) => {
    const countdown = useRef();
    const warning = useRef();
    const timeout = useRef();
    const { logout } = useContext(RealmContext);
    const [ showWarning, setShowWarning ] = useState(false);
    const [ expiresIn, setExpiresIn ] = useState();

    const resetTimer = useCallback(() => {
        clearTimeout(timeout.current);
        clearTimeout(warning.current);

        setShowWarning(false);
        setExpiresIn(expiresAfter);
        warning.current = setTimeout(() => setShowWarning(true), 1000*warningAfter);
        timeout.current = setTimeout(() => {
            logout();
        }, 1000*expiresAfter);
    }, [logout]);

    useEffect(() => {
        // console.log('timer enabled');

        resetTimer();
        countdown.current = setInterval(() => setExpiresIn((t) => t-1), 1000);
        window.addEventListener('click', resetTimer);
        window.addEventListener('mousemove', resetTimer);

        return () => {
            // console.log('timer disabled');

            clearInterval(countdown.current);
            clearTimeout(timeout.current);
            clearTimeout(warning.current);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('mousemove', resetTimer);    
        };
    }, []);

    return <Dialog open={showWarning}>
        <DialogTitle>Session Timeout Warning</DialogTitle>
        <DialogContent>
            Your session will expire in {expiresIn} second{expiresIn > 1 && 's'}...
        </DialogContent>
    </Dialog>;
};
