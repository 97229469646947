import React, { useEffect } from 'react';
import { Button, TextField, InputAdornment, IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
import Warning from '@mui/icons-material/Warning';
import { template } from '../../data/template';

export const MultiText = ({ value, setValue, config }) => {
    const isOptional = config.optional || !config.okay;
    const isOkay = (value || []).map((v) => !config.okay || config.okay(v || ''));

    // provide blank value on first render
    useEffect(() => setValue(value), []);

    return <>
        {value.map((v, idx) => {
            const [Adornment, adornmentColor, adornmentTitle] = isOkay[idx] ? [Check, 'success', 'okay'] : [Warning, 'warning', 'notOkay'];

            return <div key={idx}>
                <TextField
                    sx={{ marginTop: '0.5em', marginBottom: '1em' }}
                    fullWidth
                    required={!isOptional}
                    value={v}
                    onChange={(e) => setValue((values) => [...values.slice(0, idx), e.target.value, ...values.slice(idx + 1)])}
                    type={config.type}
                    label={`${config.label} ${idx + 1}${isOptional ? ' (Optional)' : ''}`}
                    placeholder={template[config.field]?.placeholder || ''}
                    error={!isOkay[idx]}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        endAdornment: template[config.field] && <InputAdornment position="end">
                            <Adornment title={template[config.field][adornmentTitle]} color={adornmentColor} />
                            <IconButton disabled={value.length === 1} onClick={() => setValue((values) => [...values.slice(0, idx), ...values.slice(idx + 1)])}><Delete /></IconButton>
                        </InputAdornment>
                    }} />
            </div>;
        })}
        <div><Button disabled={!isOkay.every((_) => _)} onClick={() => setValue((values) => [...values, ''])}>Add Another URL</Button></div>
    </>;
};
