export const surbl = (c) => {
    const types = [];
    const n = parseInt(c.replace(/^.*\./, ''));

    if (n & 4) types.push('diposable email');
    if (n & 8) types.push('phishing');
    if (n & 16) types.push('malware');
    if (n & 32) types.push('click tracker');
    if (n & 64) types.push('spam');
    if (n & 128) types.push('cracked');

    return types.length ? types : ['n/a'];
};