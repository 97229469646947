import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Grid, Box, TextField, AppBar, Toolbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { okEmail } from '../../utils/ok';
import { toolbarButtonsTheme } from '../../App';
import NetBeacon from '../../assets/NetB-2022-Logo_rgb_horizontal_KO.png'
import { RealmError } from '../RealmError';
import { RealmContext } from '../../ReactRealmProvider';

export const ResetPassword = () => {
    const { Realm, app, user, login } = useContext(RealmContext);

    const location = useLocation();
    const params = location.search;
    const query = new URLSearchParams(params);

    const [ password, setPassword ] = useState({ email: '', new1: '', new2: '' });
    const emailIsOk = true; // useMemo(() => okEmail(password.email), [password.email]);
    const passwordOkay = emailIsOk && password.new1.length >= 8;
    const password2Okay = passwordOkay && password.new1 === password.new2;

    const [confirmed, setConfirmed] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const refEmail = useRef();
    const refPass1 = useRef();
    const refPass2 = useRef();
    const refButton = useRef();

    useEffect(() => {
        refPass1.current.focus();
    }, []);

    const resetPassword = useCallback(async () => {
        setLoading(true);
        app.emailPasswordAuth.resetPassword({ password: password.new1, token: query.get('token'), tokenId: query.get('tokenId') }, true).then(() => {
            setConfirmed(true);
            setError();
            setLoading(false);
        }).catch((error) => {
            setConfirmed();
            setError(error);
            setLoading(false);
        });

    }, [password]);

    return (<>
        <ThemeProvider theme={toolbarButtonsTheme}>
            <AppBar position="sticky">
                <Toolbar>
                    <Box sx={{ mr: 4, flexGrow: 1 }}>
                        <img src={NetBeacon} height={30} />
                    </Box>
                    {/* <Button component={Link} to="/privacy">Privacy Notice</Button> */}
                    {/* <Button component={Link} to="/terms">Terms of Use</Button> */}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
        <Container maxWidth="sm">
            <Backdrop
                open={loading}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress/>
            </Backdrop>
            <Dialog open={Boolean(confirmed || error)}>
                <DialogTitle>Notice</DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmed ? "Your password has been successfully updated." : <RealmError error={error}/>}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setError();
                        setConfirmed();
                        window.location.href = "/login";
                    }}>Okay</Button>
                </DialogActions>
            </Dialog>
            {!confirmed && <>
            <Grid item xs={8} mt={4}>
                {/* <Box mb={4}>
                    <TextField
                        fullWidth
                        inputRef={refEmail}
                        type="text"
                        value={password.email}
                        onChange={(e) => setPassword((p) => ({ ...p, email: e.target.value }))}
                        label="Your Email Address"
                        InputLabelProps={{ shrink: true }}
                    />
                </Box> */}
                <Box mb={1}>
                    <TextField
                        fullWidth
                        inputRef={refPass1}
                        type="password"
                        disabled={!emailIsOk}
                        value={password.new1}
                        onChange={(e) => setPassword((p) => ({ ...p, new1: e.target.value }))}
                        onKeyDown={(e) => e.code === 'Enter' && passwordOkay && refPass2.current.focus()}
                        label="Your New Password"
                        InputLabelProps={{ shrink: true }}
                        error={emailIsOk && !passwordOkay}
                        helperText={!passwordOkay ? "Password is too short" : "Password is long enough"}
                />
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        inputRef={refPass2}
                        type="password"
                        disabled={!passwordOkay}
                        value={password.new2}
                        onChange={(e) => setPassword((p) => ({ ...p, new2: e.target.value }))}
                        onKeyDown={(e) => e.code === 'Enter' && password2Okay && refButton.current.focus()}
                        label="Confirm Your New Password"
                        InputLabelProps={{ shrink: true }}
                        error={passwordOkay && !password2Okay}
                        helperText={!password2Okay ? "Passwords don't match" : "Passwords match"}
                />
                </Box>
                <Box mb={2}>
                    <Button ref={refButton} variant="contained" disabled={!password2Okay} onClick={resetPassword}>Reset Password</Button>
                </Box>
            </Grid>
            </>}
        </Container>
    </>);
};
