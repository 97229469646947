import { Add, CheckBox, CheckBoxOutlineBlank, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useMemo, useState } from "react";
import { RealmContext } from "../../../ReactRealmProvider";
import { DataContext } from "../../../contexts/DataProvider";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export const CCtldsAdmin = () => {
  const { mongo } = useContext(RealmContext);
  const data = useContext(DataContext);
  const registries = useMemo(
    () =>
      data.registries.filter(({ tlds }) =>
        tlds?.every((tld) => tld.length === 2)
      ),
    [data.registries]
  );
  const pageSizes = [20, 50, 100];
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [loading, setLoading] = useState(false);
  const [registry, setRegistry] = useState();
  const registryModal = Boolean(registry);

  const updateRegistry = async (registry) => {
    if (registry._id) {
      await mongo
        .db("cart")
        .collection("registries")
        .updateOne({ _id: registry._id }, { $set: registry });
      await mongo
        .db("cart")
        .collection("groupedRegistries")
        .updateOne({ _id: registry._id }, { $set: { tlds: registry.tlds, name: registry.name, children: [ registry.name ] } })
      await mongo
        .db("cart")
        .collection("settings")
        .updateOne(
          { type: "registry", registryId: registry._id },
          {
            $setOnInsert: {
              enrichments: [
                "rdap",
                "dns",
                "cleanDns",
                "abuseCh",
                "phishTank",
                "urlScan",
                "hybridAnalysis",
              ],
              labels: [],
              reportFormat: "xarf",
              reportFrequency: "realtime",
              reporters: [],
            },
            $set: {
              name: registry.name,
              abuseEmails: registry.active
                ? [
                    {
                      email: registry.abuseEmail,
                      types: ["phishing", "spam", "malware", "botnets"],
                    },
                  ]
                : [],
            },
          },
          { upsert: true }
        );
    } else {
      const _id = await mongo
        .db("cart")
        .collection("registries")
        .insertOne(registry)
        .then((r) => r.insertedId);
      await mongo
        .db("cart")
        .collection("groupedRegistries")
        .insertOne({
          _id,
          tlds: registry.tlds,
          children: [registry.name],
          name: registry.name,
        });
      await mongo
        .db("cart")
        .collection("settings")
        .updateOne(
          { type: "registry", registryId: _id },
          {
            $setOnInsert: {
              enrichments: [
                "rdap",
                "dns",
                "cleanDns",
                "abuseCh",
                "phishTank",
                "urlScan",
                "hybridAnalysis",
              ],
              labels: [],
              reportFormat: "xarf",
              reportFrequency: "realtime",
              reporters: [],
            },
            $set: {
              name: registry.name,
              abuseEmails: registry.active
                ? [
                    {
                      email: registry.abuseEmail,
                      types: ["phishing", "spam", "malware", "botnets"],
                    },
                  ]
                : [],
            },
          },
          { upsert: true }
        );
    }

    await data.updateData();
    setRegistry();
  };

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", width: 280 },
      { field: "abuseEmail", headerName: "Abuse Email", width: 280 },
      {
        field: "tlds",
        headerName: "TLDs",
        flex: 1,
        valueGetter: (params) => {
          return (
            (params.row.tlds.length > 1 ? `(${params.row.tlds.length}) ` : "") +
            params.row.tlds.join(" ")
          );
        },
      },
      {
        field: "active",
        headerName: "Active",
        width: 60,
        renderCell: (params) => {
          return (
            <Checkbox
              checked={Boolean(params.row.active)}
              onChange={(ev) =>
                updateRegistry({ ...params.row, active: ev.target.checked })
              }
            />
          );
        },
      },
      {
        field: "action",
        headerName: "",
        width: 60,
        renderCell: (params) => (
          <IconButton
            onClick={() =>
              setRegistry({
                ...params.row,
                tlds: (params.row.tlds || []).join("\n"),
              })
            }
          >
            <Edit />
          </IconButton>
        ),
      },
    ],
    [data, setRegistry]
  );

  return (
    <>
      <Typography mt={1} variant="h4">
        Administration: ccTLD Registries
      </Typography>
      {!loading && (
        <DataGrid
          autoHeight
          rows={registries}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={pageSizes}
          onPageSizeChange={(n) => setPageSize(n)}
          disableSelectionOnClick
          // checkboxSelection
        />
      )}

      <Fab
        sx={{ position: "absolute", bottom: "calc(4rem + 32px)", right: 32 }}
        color="primary"
        onClick={() => setRegistry({ name: "", tlds: "", abuseEmail: "" })}
      >
        <Add />
      </Fab>

      <Dialog
        open={registryModal}
        onClose={() => setRegistry()}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {registry?._id ? "Modify" : "Create"} ccTLD Registry
        </DialogTitle>
        {registry && (
          <DialogContent>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              value={registry.name || ""}
              onChange={(e) =>
                setRegistry((reg) => ({ ...reg, name: e.target.value }))
              }
              error={!registry.name}
              helperText={
                !registry.name && "You must provide a name for the ccTLD"
              }
              label="Registry Name"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              multiline
              rows={5}
              value={registry.tlds}
              onChange={(e) =>
                setRegistry((reg) => ({ ...reg, tlds: e.target.value }))
              }
              label="Registry TLDs (one per line)"
              helperText={
                (registry.tlds.match(/\b\w\b|\w\w\w/) &&
                  "Only two-character ccTLDs permitted") ||
                (!registry.tlds.match(/\w\w/) && "At least one ccTLD required")
              }
              error={Boolean(
                registry.tlds.match(/\b\w\b|\w\w\w/) ||
                  !registry.tlds.match(/\w\w/)
              )}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              value={registry.abuseEmail || ""}
              onChange={(e) =>
                setRegistry((reg) => ({ ...reg, abuseEmail: e.target.value }))
              }
              label="Registry Abuse Email"
              error={!registry.abuseEmail}
              helperText={
                !registry.abuseEmail &&
                "You must provide an abuse email contact for the ccTLD"
              }
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(registry.active)}
                  onChange={(e) =>
                    setRegistry((reg) => ({ ...reg, active: e.target.checked }))
                  }
                />
              }
              label="Registry Intermediary Active?"
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => setRegistry()}>Cancel</Button>
          <Button
            disabled={
              Boolean(registry &&
              (!registry.name ||
                registry.tlds.match(/\w\w\w|\b\w\b/) ||
                !registry.tlds.match(/\w\w/) ||
                !registry.abuseEmail))
            }
            onClick={() =>
              updateRegistry({
                ...registry,
                tlds: registry.tlds
                  .trim()
                  .replace(/\./g, "")
                  .split(/\s+/)
                  .filter((tld) => tld.length === 2),
              })
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
