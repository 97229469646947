import React from 'react';
import { Box, Typography } from '@mui/material';

export const Privacy = () => {
    return <Box>
        <Typography variant='h4'>NetBeacon Reporter</Typography>
        <Typography variant='h4'>Privacy Notice</Typography>
        <Typography mb={1}>
            NetBeacon Institute (to encompass  the NetBeacon Institute website at netbeacon.org, and the NetBeacon Reporter Service at app.NetBeacon, collectively “NetBeacon Institute” or “We”) provides a centralized tool to accept reports of DNS Abuse (“Reports”) from reporters (“Abuse Reporters”), enrich Reports, and distribute Reports to the relevant registrar, provider, or party (“Abuse Report Recipient”).
        </Typography>
        <Typography mb={1}>
            This Privacy Notice provides information regarding how and when NetBeacon Reporter will collect and process personal data and how users may make inquiries about the use, amendment, and correction of their personal data. Personal data includes data such as: your name, email address, and other data that could directly or indirectly identify you.
        </Typography>
        <Typography mb={1}>
            Inquiries can be made electronically via email at <a href="mailto:privacy@netbeacon.org">privacy@netbeacon.org</a>, or via mail to NetBeacon Institute, 11911 Freedom Drive, 10th Floor, Suite 1000, Reston, VA 20190, USA, or via PIR’s EU and UK Representative at <a href="mailto:datenschutz@rickert.net">datenschutz@rickert.net</a> or Rickert Rechtsanwaltsgesellschaft mbH, Kaiserplatz 7-9, 53113, Bonn, Germany.
        </Typography>
        <Typography variant='h5'>I. Why do we collect information</Typography>
        <Typography mb={1}>
            In order to accept Reports and provide Reports to the Abuse Report Recipient, We will:
            <ul>
                <li>Authenticate an Abuse Reporter through use of an Oauth service.</li>
                <li>Create an account for the Abuse Reporter.</li>
                <li>Accept appropriate evidence for the type of Report.</li>
                <li>Identify the Abuse Report Recipient.</li>
                <li>Enrich the Report: NetBeacon Reporter will enrich the Report by submitting the reported domain name or URL to online sources for domain name and abuse related information, and then appending those results to the submitted Report. NetBeacon Reporter may respond to the Abuse Reporter to clarify the submitted information. Reports submitted to the Abuse Report Recipient will include the Abuse Reporter’s contact information as submitted to NetBeacon Reporter so that the Abuse Report Recipient can communicate with the Abuse Reporter regarding action on the report.</li>
                <li>Submit the enriched Report to the correct Abuse Report Recipient.</li>
                <li>Prevent abuse of the tool itself (submission of malware, bots, spamming the system, ddos, etc.). </li>
            </ul>
        </Typography>
        <Typography variant='h5'>II. What personal information do we collect</Typography>
        <Typography variant='h6'>a. Abuse Reporter Accounts</Typography>
        <Typography>
            <ul>
                <li>To create an account within NetBeacon using:</li>
                <ul>
                    <li>Abuse Reporter name</li>
                    <li>Abuse Reporter email address</li>
                </ul>
                <li>To authenticate an Abuse Reporter</li>
                <ul>
                    <li>NetBeacon Reporter will utilize several Oauth providers to authenticate Abuse Reporter email addresses. Only name and email address will be available to NetBeacon Reporter as part of the Oauth service.</li>
                </ul>
            </ul>
        </Typography>
        <Typography variant='h6'>b. Reports/Submissions</Typography>
        <Typography>
            <ul>
                <li>Accept appropriate evidence for the type of abuse </li>
                <ul><li>NetBeacon Reporter will accept screen shots and other evidence to support the Report. NetBeacon Reporter  has directed Abuse Reporters not to submit personal data within the Report.</li></ul>
                <li>Enrich the Report</li>
                <ul><li>This process consists of submitting the URL to publicly available online sources of domain name abuse intelligence. The information shared will only consist of the reported URL.</li>
                    <li>No personal data is collected or processed for this action.</li></ul>
                <li>Identify the registrar, registry, and hosting provider supporting the domain name </li>
                <ul><li>No personal data is collected or processed for this action.</li></ul>
                <li>Submit the enriched Report to the Abuse Report Recipient</li>
                <ul><li>NetBeacon Reporter will share the provided Abuse Reporter’s contact information with the Abuse Report Recipient with the Report so that the party can communicate with the Abuse Reporter regarding action on the Report.</li></ul>
            </ul>
        </Typography>
        <Typography variant='h6'>c. User generated content</Typography>
        <Typography mb={1}>
            NetBeacon Reporter is not responsible for maintaining as confidential any personal or other data posted by you on message boards hosted by our websites. Users voluntarily disclosing such personal data makes your information publicly available.
        </Typography>
        <Typography mb={1}>
            NetBeacon Reporter will accept screen shots and other evidence to support the Report which is considered user generated content. NetBeacon Reporter  has directed Abuse Reporters not to submit personal data within the Report.
        </Typography>
        <Typography variant='h6'>d. Website users</Typography>
        <Box ml={4}>
            <Typography variant='h6'>i. User Information</Typography>
            <Typography mb={1}>You may visit the NetBeacon Institute website without identifying yourself. If you are interested in our services or wish to subscribe to our newsletter, you may be required to create an account or provide an email address. When you create an account, we may request certain personal data, including your name and email address. We may also request personal data for purposes such as the provision of customer service and other exchanges of information.</Typography>
            <Typography variant='h6'>ii. Cookies</Typography>
            <Typography mb={1}>A cookie is a small data file that certain websites write to your hard drive when you visit the site. A cookie file can contain information that allows - NetBeacon Institute to track the pages you have visited. We use cookies to tell when a user is a repeat visitor and to let NetBeacon Institute  know how the user found the website. Cookies also allow NetBeacon Institute  to automatically link users to their personalized accounts (should you choose to register), enabling you to enter various services as a member and to visit member-restricted areas of the site without having to log in each time. This applies to the use of cookies by NetBeacon Institute and does not apply to the use of cookies or other tracking technologies used by any third parties.</Typography>
            <Typography mb={1}>Visitors to our sites have the option to disable cookies via their browser preferences.  You can refuse cookies by turning them off in your browser (<a href="https://support.google.com/chrome/answer/95647">Google Chrome</a>, <a href="https://support.apple.com/en-us/HT201265">Safari</a>, <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Edge</a>, <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Firefox</a>).  Please be aware that some websites and services will not perform optimally if cookies are disabled. </Typography>
            <Typography variant='h6'>iii. Analytics</Typography>
            <Typography mb={1}>NetBeacon Reporter utilizes third-party website analytics tools which includes collecting aggregated anonymized data from visitors to our websites via third-party data analytics (Google) (i.e., cookies, log files, usage data, IP address, browser, click stream data, etc.) as well as general performance data from any third-party ads and may use that data to analyze and evaluate the effectiveness of our website design, services, marketing, and campaigns.</Typography>
            <Typography variant='h6'>iv. Third-party Links</Typography>
            <Typography mb={1}>This Privacy Notice only addresses the processing of personal data you provide to NetBeacon Reporter via this website. The processing of any data you disclose to other parties via third-party links will be governed by their privacy policies. Users should be aware of their personal privacy settings when using platforms and apps and update them to manage your privacy preferences. NetBeacon Institute is not responsible for the privacy policies of other websites. We encourage you to familiarize yourself with the privacy policies of other websites you visit.</Typography>
            <Typography variant='h6'>v. Log Files</Typography>
            <Typography mb={1}>NetBeacon Reporter gathers information about all users collectively, such as what areas of its site’s users visit most frequently and what services users access most often. This data is logged and aggregated so we may use it to understand user behavior and for system-performance monitoring. NetBeacon Reporter does not maintain individual log file data and only maintains aggregate user statistics which are not capable of identifying an individual. NetBeacon Reporter may disclose aggregated user statistics to describe the service to prospective partners, advertisers, and other third parties and for other lawful purposes.</Typography>
            <Typography variant='h6'>iv. Google Safe Search</Typography>
            <Typography mb={1}>NetBeacon Reporter directs its Abuse Reporters to not include personal data or explicit images as part of Report evidence. However, to ensure that NetBeacon Reporter does not view or inadvertently share explicit material, NetBeacon Reporter will utilize Google’s Safe Search to scan Reports. This service only scans an image file and does not include or collect any personal data.</Typography>
            <Typography variant='h6'>iv. OAuth Service</Typography>
            <Typography mb={1}>As part of the account creation and registration process for Abuse Reporters, NetBeacon Reporter will use several Oauth services (Google, LinkedIn, Facebook, Apple) to authenticate the Abuse Reporter’s email address. NetBeacon Reporter will only have access to the name and email address of the user and the user will only be asked to submit name and email to the Oauth service. As noted above, we encourage users to review the privacy policies of third party services.</Typography>
        </Box>
        <Typography variant='h5'>III. Purposes for using personal data</Typography>
        <Typography mb={1}>NetBeacon Reporter will make all reasonable efforts to ensure that personal information is processed only for the purposes set out below (including to provide the services requested by you). We will make all reasonable efforts to ensure that personal information is not processed in a way incompatible with the purpose for which it was collected or received. We will only disclose personal information, or elements of this information, in certain situations as defined below under Data Sharing and Disclosure.</Typography>
        <Typography variant='h6'>a. NetBeacon Reporter (Centralized DNS Abuse Reporting Tool)</Typography>
        <Typography>
            NetBeacon Reporter uses the personal data you provide to us to perform the functions and services of a centralized DNS abuse reporting tool to:
            <ul>
                <li>Administer and manage your account,</li>
                <li>Submit Reports centrally,</li>
                <li>Provide Reports to Abuse Report Recipient,</li>
                <li>Facilitate communication between NetBeacon Reporter and Abuse Reporters, and between Abuse Reporters and Abuse Report Recipient, and</li>
                <li>Develop public reporting metrics (applying appropriate anonymization).</li>
            </ul>
        </Typography>
        <Typography variant='h6'>b. Website</Typography>
        <Typography mb={1}>If you submit personal data to NetBeacon Reporter through our website, we may use that information to assist with the functionality of the website. This may include sending you information you have requested to receive (i.e., newsletters) and providing you with policy and services updates. Be aware that any information you voluntarily provide via message boards or public discussion tools becomes publicly available information and may be used by NetBeacon Reporter or others.</Typography>
        <Typography mb={1}>We collect aggregated anonymized data from visitors to our websites via third-party data analytics (Google) (i.e. cookies, log files, usage data, IP address, browser, click stream data, etc.) and may use that data to evaluate and improve the effectiveness of our website design and services.</Typography>

        <Typography variant='h5'>IV. Lawful Bases for Processing Personal Data</Typography>
        <Typography mb={1}>NetBeacon Reporter processes your personal data: (a) based on the performance of a contract to which you are a subject, (b) where we have a legitimate business interest in doing so, or (c) on the basis of consent freely provided by you.</Typography>
        <Typography mb={1}>When an Abuse Reporter creates an account and requests use of the NetBeacon Reporter Service, they will consent to processing of specific personal data and agree to the Terms of Use.</Typography>
        <Typography mb={1}>NetBeacon Reporter may also have a legitimate interest in using personal data that is provided by you in order to optimize our service to you and your experience on our Website.</Typography>
        <Typography mb={1}>In some cases, where a user requests to receive information (i.e., mailing list, newsletter, etc.) but does not create an account with NetBeacon Reporter, their requests may be processed on the basis of consent.</Typography>

        <Typography variant='h5'>V. Data Sharing and Disclosure</Typography>
        <Typography mb={1}>Our trusted processors (technical backend providers, vendors, and other similar business partners) are responsible for performing certain parts of the technical requirements of the services provided. NetBeacon Reporter and will therefore share some of the personal data you provide to those processors as necessary to perform the requested services. These processors are not authorized to use such personal data for purposes beyond those specified by us. To the extent that they process any personal data they are required to provide security and privacy protections in line with this Privacy Notice and in compliance with applicable privacy and data protection laws.</Typography>
        <Typography mb={1}>The information that you supply to NetBeacon Reporter will never be given, sold, rented, loaned, or otherwise disclosed to any third parties, except as outlined above or as described in Exceptions and Special Circumstances, below. Our Exceptions and Special Circumstances Policy (below), permits NetBeacon Reporter to share information when it is necessary to comply with legal process or protect the rights, property, or personal safety of NetBeacon Reporter, its customers, or the public.</Typography>
        <Typography variant='h6'>a. Back End</Typography>
        <Typography mb={1}>NetBeacon Reporter is the operator of the centralized abuse reporting tool. NetBeacon Reporter’s technical developer, CleanDNS, owns and administers the service and maintains the infrastructure. CleanDNS is a data processor for NetBeacon Reporter.</Typography>
        <Typography variant='h6'>b. Registrars/Registries</Typography>
        <Typography mb={1}>NetBeacon Reporter, as stated in its Terms of Use, will disclose the Abuse Reporter’s email address to the Abuse Report Recipient so that the Abuse Report Recipient can communicate with the Abuse Reporter regarding action on the Report.</Typography>
        <Typography variant='h6'>c. Links and third-party collection</Typography>
        <Typography mb={1}>This Privacy Notice only addresses the processing of personal data you provide to NetBeacon Reporter via this website. The processing of any data you disclose to other parties via third-party links will be governed by their privacy policies. Users should be aware of their personal privacy settings when using platforms and apps and update them to manage your privacy preferences. NetBeacon Reporter is not responsible for the privacy policies of other websites. We encourage you to familiarize yourself with the privacy policies of other websites you visit.</Typography>
        <Typography variant='h6'>d. OAuth Services</Typography>
        <Typography mb={1}>As part of the account creation and registration process for Abuse Reporters, NetBeacon Reporter will use several Oauth services (Google, LinkedIn, Facebook, Apple) to authenticate the Abuse Reporter’s email address. NetBeacon Reporter will only have access to the name and email address of the user and the user will only be asked to submit name and email to the Oauth service. As noted above, we encourage users to review the Privacy Policies of third-parties services.</Typography>
        <Typography variant='h6'>e. Google Safe Search</Typography>
        <Typography mb={1}>NetBeacon Reporter directs its Abuse Reporters to not include personal data or explicit images as part of Report evidence. However, to ensure that NetBeacon Reporter does not view or inadvertently share explicit material, NetBeacon Reporter will utilize Google’s Safe Search to scan Reports. This service only scans an image file and does not include or collect any personal data.</Typography>
        <Typography variant='h6'>f. Analytics</Typography>
        <Typography mb={1}>NetBeacon Reporter utilizes third-party website analytics tools which includes collecting aggregated anonymized data from visitors to our websites via third-party data analytics (Google) (i.e., cookies, log files, usage data, IP address, browser, click stream data, etc.) as well as general performance data from any third-party ads and may use that data to analyze and evaluate the effectiveness of our website design, services, marketing, and campaigns.</Typography>
        <Typography variant='h6'>g. Website</Typography>
        <Typography mb={1}>NetBeacon Reporter will use WP Engine and Google Cloud to host and maintain the security and stable operation of the website.</Typography>

        <Typography variant='h5'>VI. Exceptions and Special Circumstances</Typography>
        <Typography mb={1}>There are occasionally special circumstances that require NetBeacon Reporter to disclose information which could include personal data.</Typography>
        <Typography mb={1}>In certain circumstances, NetBeacon Reporter must disclose information beyond the limits outlined above including when it is necessary to fulfill a transaction or provide information you have requested; necessary to protect the rights, property or personal safety of NetBeacon Reporter , its customers or the public; in the vital interests of the data subject or another person; required by law or necessary to respond to legal process; necessary to meet the requirements of requests, lawfully made by public authorities, including requests to meet national security or law enforcement requirements.</Typography>
        <Typography mb={1}>NetBeacon Reporter reserves the right to disclose personal data or non-personal data that we believe, in good faith, is appropriate or necessary to enforce our Terms of Use, and to protect the security or integrity of our systems.</Typography>

        <Typography variant='h5'>VII. How to Access, Amend, and Control your personal data</Typography>
        <Typography mb={1}>As an Abuse Reporter you may access and amend your account information via the NetBeacon Reporter app website. You may also revoke or amend your consent for personal data processing at any time via your account or by contacting us.</Typography>
        <Typography mb={1}>You may also wish to contact us should you no longer desire our service. You may contact NetBeacon Institute via email at <a href="mailto:privacy@netbeacon.org">privacy@netbeacon.org</a> or at NetBeacon Institute, ℅ PIR 11911 Freedom Drive, 10th Floor, Suite 1000, Reston, VA 20190, United States of America.</Typography>

        <Typography variant='h5'>VIII. Special note for EU data subjects</Typography>
        <Typography variant='h6'>a. Data Subject Rights</Typography>
        <Typography mb={1}>The EU General Data Protection Regulation (2016/679) (the “GDPR”) affords you certain rights. Where applicable you may request confirmation that NetBeacon Reporter  does or does not process personal data related to you. You may request a copy of your personal data or request that a copy is sent to a third party. You may request that your data, such as your address, is amended or corrected. In some circumstances you may request that your data processed by NetBeacon Reporter be deleted. You may also request, in certain cases, that we restrict processing of your personal data by NetBeacon Reporter. You also have the right to object to receiving direct marketing. To the extent your information is processed by NetBeacon Reporter  based upon consent, you may withdraw that consent at any time.</Typography>
        <Typography mb={1}>Should you wish to exercise any of these rights or other rights afforded you by the GDPR please contact us via email at <a href="mailto:privacy@netbeacon.org">privacy@netbeacon.org</a>, or at NetBeacon Institute, ℅ PIR, 11911 Freedom Drive, 10th Floor, Suite 1000, Reston, VA 20190, United States of America.</Typography>
        <Typography mb={1}>You may contact PIR’s EU and UK Representative at <a href="mailto:datenschutz@rickert.net">datenschutz@rickert.net</a> or Rickert Rechtsanwaltsgesellschaft mbH, Kaiserplatz 7-9, 53113, Bonn, Germany.</Typography>
        <Typography variant='h6'>b. Data Transfer</Typography>
        <Typography mb={1}>In order to provide the promised services to you, NetBeacon Reporter may receive data from EEA countries or transfer your data to third countries. NetBeacon Reporter transfers EEA data to third countries as is necessary to perform the requirements of our Terms of Use using standard contractual clauses and consent (depending upon the processing action) as our legal mechanism for transfer. NetBeacon Reporter may transfer your data on the basis of consent. In such cases, your consent will be explicitly requested and is revocable at any time.</Typography>

        <Typography variant='h5'>IX. Security</Typography>
        <Typography mb={1}>NetBeacon Reporter takes precautions to protect information and continually strives to ensure that we are adhering to industry best practices and security standards. When you submit personal data to us, your personal data is protected both online and offline.</Typography>
        <Typography mb={1}>We are required to take reasonable steps to protect personal data from loss, misuse, unauthorized disclosure, alteration, or destruction and not to use or authorize the use of personal data in a way that is incompatible with the purposes for which it was collected. All partners and vendors (third party processors) are to adhere to the requirements in this privacy notice and in their contracts to meet security standards that are commensurate with the data they receive and process. These third parties also have their own privacy and security policies. Should you have questions about their privacy policies or security practices, you should contact them.</Typography>

        <Typography variant='h5'>X. Data Breaches</Typography>
        <Typography mb={1}>Upon discovering a data breach NetBeacon Reporter will ensure we meet all data privacy requirements, including GDPR Articles 33 and 34, where applicable.</Typography>

        <Typography variant='h5'>XI. Data Retention</Typography>
        <Typography mb={1}>NetBeacon Reporter does not retain personal data for any longer than is necessary for its original purpose.</Typography>

        <Typography variant='h5'>XII. Update</Typography>
        <Typography mb={1}>This Privacy Notice was last updated April 2024.</Typography>

    </Box>;
};
