import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

export const RecipTerms = () => {
    return <Box>
        <Typography variant='h4'>DNS Abuse Institute</Typography>
        <Typography variant='h4'>NetBeacon Notification Recipient Terms of Use</Typography>

        <Typography mt={2} mb={2} variant='h5'>Last Updated Date: April 27, 2022</Typography>

        <Typography mb={1}>
            These terms and conditions (the “<b>Terms</b>”) apply to your access to and your use of the DNS Abuse Institute’s NetBeacon abuse reporting tool (the “<b>NetBeacon Service</b>”) for the purposes of setting up an account with us to facilitate your receipt of abuse Notifications (as defined below), to view certain aggregated information concerning Notifications sent to you, and to provide feedback to us concerning Notifications, all as further set forth herein.
            {' '}
            <b>By accessing or using the NetBeacon Service, you agree that you are bound by these Terms.</b> As used in these Terms, the phrases "you", "your" and "yours" refer to the individual agreeing to these Terms; if you are acting on behalf of an entity (e.g., a company, organization, etc.), then those phrases refer to the entity on whose behalf you are acting.  As used in these Terms, the phrases "DNSAI”, "we", "us" or "ours" refer collectively to Public Interest Registry and its DNS Abuse Institute.
            {' '}
            <b>If you do not agree to be bound by these Terms, you are not authorized to use the NetBeacon Service.</b>  We may update these Terms from time to time, and we'll post the updated version of these Terms <Link to="/recipient-terms">here</Link>.  We'll include a "Last Updated Date" at the beginning of these Terms so that you can see when we last made changes.  Your continued use of any aspect of the NetBeacon Service after we post updated Terms will constitute your agreement to the updated Terms.
        </Typography>

        <Typography variant='h5'>Privacy</Typography>
        <Typography mb={1}>
            Our <Link to="/privacy">Privacy Notice</Link> forms a part of, and is hereby incorporated into, these Terms.  Without limiting our other rights hereunder or elsewhere, you agree that we may make available information you provide or that we maintain about you to third parties as applicable laws require or permit, including, without limitation, as provided in our Privacy Notice.  We may also report to appropriate law enforcement agencies activity that we believe to be potentially criminal.  This paragraph will survive the termination of these Terms.
        </Typography>

        <Typography mt={2} mb={2} variant='h5'>NetBeacon Service Access &amp; Use</Typography>

        <Typography mb={1}>
            <b>Purpose of NetBeacon Service.</b> Our NetBeacon Service is intended to provide a centralized, structured and efficient method for users (“Abuse Reporters”) to submit abuse complaints (each, a “Notification”) via NetBeacon to Internet infrastructure providers, such as the relevant registrar, provider, or party (“Abuse Report Recipients”), so that abuse complaints can be appropriately addressed. 
        </Typography>
        <Typography mb={1}>
            <b>Your Access.</b> Subject to these Terms, you may access and use the NetBeacon Service solely to manage your account with us, designate other preferences (as made available by us) for the receipt of Notifications through the NetBeacon Service, and, to the extent made available by us, (i) view aggregated information concerning Notifications sent to you, and (ii) provide feedback to us concerning Notifications.  If you are managing the access of other users within your organization, those users must also agree to be bound by these Terms.  User IDs are granted to individual persons and may not be shared.  You must follow all instructions provided by us for using the NetBeacon Service.  You are not authorized to, and you agree that you will not, use the NetBeacon Service other than as expressly permitted in these Terms.  Without limiting the foregoing, you agree that you will not do any of the following in connection with the NetBeacon Service: 
            <ul>
                <li>Use the NetBeacon Service for any unlawful, nefarious, malicious, abusive, or inappropriate purpose, or to wrongfully attempt to interrupt or disrupt products, services or business of another person or entity.</li>
                <li>Introduce into the NetBeacon Service any malicious or otherwise harmful code or computer virus.</li>
                <li>Introduce into the NetBeacon Service, or post, upload, transmit or link to, any Child Sexual Abuse Materials (“<b>CSAM</b>”) or any image depicting child pornography.</li>
                <li>Introduce into the NetBeacon Service, or post, upload, transmit or link to, any illegal, sexually explicitimages or content. </li>
                <li>Attempt to access or obtain information from another person or entity’s account with us.</li>
                <li>Engage in any activity that could disrupt or interfere with any other person’s or entity’s use of the NetBeacon Service.</li>
                <li>Impersonate another person or entity.</li>
                <li>Submit more than one Notification for a particular abuse incident.</li>
                <li>Engage in any activity that is abusive or could be harmful to DNSAI or its vendors or licensors, or that could subject any of them or us to potential litigation or other legal action by another party. </li>
            </ul>
        </Typography>
        <Typography mb={1}>
            <b>Your Online Account.</b> We may require that you create an online account with us as a condition of using the NetBeacon Service.  You agree to provide accurate and complete information in connection with any account you establish with us, and you agree to keep that information accurate and complete for as long as you have an online account with us.  You are responsible for safeguarding any authentication credentials (e.g., passwords) issued or created for your account, and you are responsible for all actions taken through the use of your account—     whether authorized by you or not.  We may reset your password, suspend your access to and interactions with the NetBeacon Service, either temporarily or permanently, or take other similar actions for the purpose of NetBeacon Service security.  You agree to immediately notify us upon discovering any compromise, misuse, or unauthorized access to your authentication credentials or your account.
        </Typography>
        <Typography mb={1}>
            In the event we, in our sole discretion, determine you have violated these Terms, you understand and agree that we reserve the right to sever your access to the NetBeacon Service and/or terminate your account.
        </Typography>
        <Typography mb={1}>
            <b>Notifications.</b> When we receive a Notification through the NetBeacon Service that we believe may be relevant to you and is in compliance with the NetBeacon Service requirements we have set for the submission or sending of Notifications (“NetBeacon Requirements”), we will use commercially reasonable efforts to attempt to send the Notification to your identified endpoint (as reflected in your online account) or to your default abuse email address.  We may change the NetBeacon Requirements from time to time in our sole discretion.  We may include with the Notification additional information concerning the Notification that we obtain from the Abuse Reporter or from other sources available to us, as well as the Submitter’s email address or other communication identifier; however, all such information may be inaccurate or invalid, and we cannot and do not make any representation or promise to you or any other party concerning (and you assume all risks related to) the validity or accuracy of such information.  We may provide you with the ability to flag Notifications as unactionable and to provide us with information concerning the same (“Unactionable Notifications”).  You agree to use reasonable efforts to provide us with accurate information when reasonably requested by us or within the NetBeacon Service concerning all Unactionable Notifications.
        </Typography>
        <Typography mb={1}>
            <b>Your Activity and Statistics.</b> We may provide you with the ability to view and share certain information concerning your activity within the NetBeacon Service, such as, e.g., anonymized statistics regarding the number of Notifications sent to you, the number of Abuse Reporters , the types of Notifications sent to you, and logs pertaining to your activities within the NetBeacon Service (collectively, “Activity Information”).  While we try to provide Activity Information that is accurate, you acknowledge and agree that we cannot and do not promise that it will be accurate, and you assume all risks that your Activity Information might be inaccurate.  You agree that we have the right to use and publish for our purposes any Activity Information that you have published, and the right to use and publish all Activity Information that is non-personal information in any manner and for any purposes that does not reasonably allow another person to identify you.  This paragraph will survive the termination of these Terms.
        </Typography>
        <Typography mb={1}>
            <b>No Guaranty of Submission or Action.</b> We may, in our sole discretion, refuse to process or forward any Notification, and we cannot and do not promise that any Notification will reach you or will be actionable.
        </Typography>
        <Typography mb={1}>
            <b>Your equipment.</b> You are responsible for acquiring and maintaining all equipment and services (such as, without limitation, Internet connectivity) required to use the NetBeacon Service as made available by us.
        </Typography>
        <Typography mb={1}>
            <b>Your Representations and Warranties.</b> You represent and warrant to us the following:
            <ul>
                <li>You are at least 18 years of age.</li>
                <li>You will only submit Notifications based upon a good faith belief by you that each such Notification pertains to abusive, unauthorized, or unlawful activity falling within the Notification categories made available to you within the NetBeacon Service.</li>
                <li>You are not and will not use the NetBeacon Service for any unlawful, nefarious, or malicious purpose.</li>
                <li>All of the information you provide to, within or through the NetBeacon Service is and will, to the best of your knowledge and belief, be accurate and complete, and in compliance with these Terms.</li>
                <li>If you are acting on behalf of another person or entity, you have all necessary authorizations and permissions to legally act on that person’s or entity’s behalf and to provide the information you provide or make available through the NetBeacon Service.</li>
            </ul>
            This paragraph will survive the termination of these Terms.
        </Typography>

        <Typography variant='h5'>Intellectual Property Notices &amp; Protections.</Typography>
        <Typography mb={1}>
            You agree that we and our licensors own all right, title and interest, including all intellectual property rights, in and to the NetBeacon Service, and all software, algorithms, code, methodologies, content, trademarks, service marks, logos, images, data, and forms related thereto, and all modifications to any of the foregoing (the “DNSAI IP”).  You may from time-to-time provide suggestions or ideas concerning the DNSAI IP (“Feedback”).  We shall have the unrestricted right, and you hereby grant to us a non-exclusive, worldwide, perpetual, irrevocable, sublicensable, transferable, and fully paid right and license, to use and fully exploit any and all Feedback for any purposes, commercial or otherwise, without any obligation of compensation or attribution to you or any third party.  You agree not to remove or attempt to use technological means to remove or bypass any intellectual property notices or technological safeguards included in any DNSAI IP.  All rights not expressly granted under these Terms are hereby reserved by DNSAI and its licensors.  You will not use any DNSAI IP in any manner or for any purpose not expressly permitted in these Terms.  Without limiting the foregoing, you will not, either directly or through a third party: (i) copy, modify, adapt, alter, translate or create derivative works of any DNSAI IP; (ii) distribute, sublicense, lease, rent, loan or otherwise transfer any aspect of the DNSAI IP to any third party; or (iii) reverse engineer, decompile, disassemble or otherwise attempt to derive source code for any aspect of the DNSAI IP.  This paragraph will survive the termination of these Terms.
        </Typography>

        <Typography variant='h5'>Termination &amp; Effect of Termination.</Typography>
        <Typography mb={1}>
            You and we each may terminate these Terms at any time, with or without cause, by providing written notice to the other party (and our written notice to you may be via email or through any account you have created with us).  Upon termination of these Terms, all access and use rights granted to you will cease, you will no longer be authorized to access or use the NetBeacon Service, and we will have no obligation to provide any information concerning your account to you, including, without limitation, any Activity Information.  We may, at any time, in our sole discretion, modify or cease providing the NetBeacon Service or any aspect of it.
        </Typography>

        <Typography variant='h5'>Limitation of Liability</Typography>
        <Typography mb={1}>
            YOU AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY LAW, DNSAI, AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND SERVICE PROVIDERS SHALL NOT HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, SPECIAL, EXEMPLARY OR INDIRECT DAMAGES, OR LOST PROFITS OR REVENUES, ARISING OUT OF OR RELATING TO ANY ACCESS TO OR USE OF THE NETBEACON SERVICE, OR THESE TERMS, WHETHER ARISING IN TORT, CONTRACT OR OTHERWISE, AND EVEN IF WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES.
        </Typography>
        <Typography mb={1}>
            IN THE EVENT (AND ONLY IN THE EVENT) THE LIMITATIONS OF LIABILITY SET FORTH ABOVE ARE DEEMED BY A COURT OF COMPETENT JURISDICTION TO BE UNENFORCEABLE, IN WHOLE OR IN PART, WITH RESPECT TO ANY CLAIM BY YOU OR SOMEONE ACTING ON YOUR BEHALF ARISING OUT OF OR RELATING TO ANY ACCESS TO OR USE OF THE NETBEACON SERVICE, OR THESE TERMS, YOU AGREE THAT IN THAT CASE, TO THE EXTENT NOT COVERED BY THE LIMITATIONS OF LIABILITY ABOVE, AND TO THE FULLEST EXTENT PERMITTED BY LAW, OUR TOTAL AGGREGATE LIABILITY FOR ALL SUCH CLAIMS WILL NOT EXCEED ONE HUNDRED DOLLARS ($100).
        </Typography>
        <Typography mb={1}>
            YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT CHARGING YOU A FEE FOR THE ACCESS AND USE OF THE NETBEACON SERVICE GRANTED IN THESE TERMS, AND THEREFORE THE LIMITATION OF OUR LIABILITY ABOVE IS ACCEPTABLE TO YOU, AND THAT WITHOUT THE LIMITATION OF LIABILITY STATED HEREIN, WE WOULD NOT MAKE THE NETBEACON SERVICE AVAILABLE TO YOU.  YOU ACKNOWLEDGE AND AGREE THAT IT IS NOT NECESSARY TO USE THE NETBEACON SERVICE TO SUBMIT ABUSE NOTIFICATIONS DIRECTLY TO THIRD PARTIES.
        </Typography>
        <Typography mb={1}>
            The provisions in this “Limitation of Liability” section will survive the termination of these Terms.
        </Typography>

        <Typography variant='h5'>Indemnification.</Typography>
        <Typography mb={1}>
            YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD DNSAI AND ITS AFFILIATES, AND EACH OF THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND REPRESENTATIVES, HARMLESS FROM AND AGAINST ALL CLAIMS, LOSSES, LIABILITIES, OR DAMAGES ARISING FROM OR RELATED TO YOUR USE OF THE NETBEACON SERVICE.
        </Typography>

        <Typography variant='h5'>Disclaimer; No Warranties</Typography>
        <Typography mb={1}>
            YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE NETBEACON SERVICE IS AT YOUR OWN RISK, AND THAT THE NETBEACON SERVICE IS PROVIDED ON AN "AS IS" BASIS.  WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.  WE DO NOT WARRANT THAT THE NETBEACON SERVICE OR ITS CONTENT WILL BE COMPLETE, ACCURATE, UNINTERRUPTED, ERROR FREE, FREE FROM MALICIOUS OR HARMFUL CODE, OR THAT ANY OF THEM WILL MEET YOUR REQUIREMENTS.  ADDITIONALLY, YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR THE CONTENT OR QUALITY OF ANY THIRD-PARTY WEBSITES OR RESOURCES THROUGH WHICH YOU MIGHT ACCESS, USE OR RECEIVE MESSAGES CONCERNING THE NETBEACON SERVICE.  THE INCLUSION OF A LINK OR FORM PERTAINING TO THE NETBEACON SERVICE ON A THIRD PARTY’S WEBSITE DOES NOT IMPLY THAT WE ENDORSE THE OTHER PARTY OR THEIR SERVICES, WEBSITES OR CONTENT.  YOU AGREE THAT YOUR USE OF ANY LINKS, FORMS OR OTHER ACCESS TO THE NETBEACON SERVICE ON A THIRD-PARTY SITE OR THROUGH A THIRD-PARTY SYSTEM IS AT YOUR OWN RISK.
        </Typography>

        <Typography variant='h5'>Legal Action; Governing Law and Jurisdiction; No Class Actions.</Typography>
        <Typography mb={1}>
            You and we agree that these Terms shall be governed by and interpreted under the laws of the Commonwealth of Virginia, without regard to its conflicts of laws rules, and any dispute arising out of or related to the NetBeacon Service or these Terms must be brought exclusively in the state and federal courts for Fairfax County, Virginia, USA.  You and we each agree to the exclusive personal jurisdiction and venue of such courts for the resolution of all such disputes.  To the fullest extent permitted by law, neither the Uniform Computer Information Transactions Act (as enacted in any jurisdiction) nor the United Nations Convention on Contracts for the International Sale of Goods shall apply to these Terms, and you and we hereby exclude, reject and waive the application of the same to these Terms.  You further agree that any dispute relating to or arising out of these Terms or the NetBeacon Service shall be resolved on an individual basis. Consequently, you agree that you may not bring a claim that relates to or arises out of these Terms or your use of or access to the NetBeacon Service as a plaintiff in, or a class member in, a class action or similar type of group or consolidated action.  You agree that the limitations contained in this paragraph are an essential element of these Terms and without such limitations, we would not make the NetBeacon Service available to you.  This paragraph will survive the termination of these Terms.
        </Typography>

        <Typography variant='h5'>General Information</Typography>
        <Typography mb={1}>
            These Terms constitute the full and complete agreement between you and us concerning the NetBeacon Service and any access or use by you, and these Terms supersede all prior communications or agreements between you and us pertaining to the subject matter of these Terms.  You may not modify these Terms in any way except as agreed to by us in a written document signed by both you and us.  If any provision is declared invalid or unenforceable, all remaining provisions will nevertheless remain in effect.  Any provision of these Terms that requires or reasonably contemplates the performance or existence of obligations by either you or us after termination of these Terms will survive such termination, including, without limitation, any paragraphs and sections expressly referred to herein as surviving such termination.  If you have any questions regarding these Terms, please contact us via e-mail at <a href="mailto:info@dnsabuseinstitute.org">info@dnsabuseinstitute.org</a>.
        </Typography>
    </Box>;
};
