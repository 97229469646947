import { Box } from "@mui/material";
import { useCallback } from "react";
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { ChartWrapper } from "../../../components/charts/ChartWrapper";
import { useDashboardAggregation } from "../../../hooks/useDashboardAggregations";

export const ChartByTldAndRegistrar = ({}) => {
  const groupBy = 'Registrar'; // TODO: make this Registrar OR Tld

  const handleData = useCallback((data) => {
    const group = data[0]['by' + groupBy];

    const subset = group
      .sort((a, b) => b.total - a.total)
      .slice(0, 10);

    const groups = Array.from(
      new Set(
        subset.map(({ registrars, tlds }) => Object.keys((groupBy === 'Registrar' ? tlds : registrars) || {})).flat()
      )
    );

    return {
      grouped: subset.map(({ total, _id, ianaId, tlds, registrars, name }) => ({
        _id,
        ianaId,
        total,
        name,
        ...(groupBy === 'Registrar' ? tlds : registrars),
      })),
      groups,
      datatable: group.map((d) => Object.entries(d[groupBy === 'Registrar' ? 'tlds' : 'registrars']).map(([ item, incidents ]) => ({
        _id: d.name + ':' + item,
        name: d.name,
        ianaId: d.ianaId,
        item,
        incidents,
      }))).flat(),
    };
  }, [groupBy]);

  const { data, loading, colors } = useDashboardAggregation({
    aggregation: "incidentsByTldAndRegistrar",
    callback: handleData,
  });

  return (
    <ChartWrapper
      data={data?.datatable || []}
      showTable={true}
      headers={[
        groupBy === 'Registrar' ? { name: 'ID', field: 'ianaId' } : null,
        { name: groupBy === 'Registrar' ? "Registrar" : "TLD", field: "name" },
        { name: groupBy === 'Registrar' ? "TLD" : "Registrar", field: "item" },
        { name: "Incidents", field: "incidents" },
      ].filter(Boolean)}
      needsDivider={false}
      title={"Incidents By Registrar and TLD"}
      xs={12}
      height={!data?.grouped?.length ? 120 : Math.min(480, data?.grouped?.length * 75)}
    >
      <BarChart
        loading={loading}
        layout="vertical"
        data={data?.grouped}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        {/* <XAxis dataKey="name" /> */}
        {/* <YAxis dataKey="total" /> */}
        <YAxis type="category" dataKey={'name'} width={250} interval={0} />
        <XAxis type="number" dataKey='total' />

        <Legend />
        <Tooltip content={CustomTooltip} />
        {data?.groups?.map((group, i) => (
          <Bar
            key={i}
            dataKey={group}
            stackId="a"
            fill={colors[i % colors.length]}
          />
        ))}
      </BarChart>
    </ChartWrapper>
  );
};

export default ChartByTldAndRegistrar;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { name } = payload[0].payload;
    return (
      <Box
        sx={{
          background: "#FFFFFF",
          opacity: "100",
          width: "300px",
          height: "fit-content",
          marginTop: "-60px",
          border: "1px solid grey",
          zIndex: "1500",
        }}
        padding={2}
      >
        <div>
          {name} {parseInt(label) > 0 && ` (IANA ID ${label})`}
        </div>
        {payload.map(({ fill, dataKey, value }, i) => {
          return (
            <div key={i}>
              <span
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: fill,
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              />{" "}
              {dataKey} ({value})
            </div>
          );
        })}
      </Box>
    );
  }

  return null;
};
