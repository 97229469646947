import { Box } from "@mui/material";
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis, Cell } from "recharts";
import { ChartWrapper } from "../../../components/charts/ChartWrapper";
import { useDashboardAggregation } from "../../../hooks/useDashboardAggregations";

export const IncidentsByTld = ({ }) => {
  const { loading, data, colors } = useDashboardAggregation({
    aggregation: "incidentsByTld",
  });

  return (
    <ChartWrapper
      data={data}
      showTable={true}
      headers={[
        { name: "TLD", field: "tld" },
        { name: "Total", field: "incidents" },
      ]}
      needsDivider={false}
      title={"Incidents By TLD"}
      xs={12}
      height={300}
    >
      <BarChart
        loading={loading}
        data={data.slice(0, 10)}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <XAxis dataKey="tld" />
        <YAxis dataKey="incidents" />
        <Legend align="center" content={<div> incidents </div>} />
        <Tooltip content={<CustomTooltip data={data} colors={colors} />} />
        <Bar dataKey={"incidents"} >
          {data.map((entry, index) => <Cell cursor="pointer" fill={colors[index]} key={`cell-${index}`} />)}
        </Bar>
      </BarChart>
    </ChartWrapper>
  );
};

export default IncidentsByTld;

const CustomTooltip = ({ active, payload, label, data, colors }) => {
  if (active && payload && payload.length) {
    const { _id } = payload[0].payload;
    const color = colors[data.findIndex((d) => d._id === _id)];
    return (
      <Box
        sx={{
          background: "#FFFFFF",
          opacity: "100",
          width: "300px",
          height: "fit-content",
          border: "1px solid grey",
          zIndex: "1500",
        }}
        padding={2}
      >
        <div>{label}</div>
        {payload.map(({ fill, dataKey, value }, i) => {
          return (
            <div key={i}>
              <span
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: color,
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              />{" "}
              {dataKey} ({value})
            </div>
          );
        })}
      </Box>
    );
  }

  return null;
};
