import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { RealmContext } from '../ReactRealmProvider';

export const DataContext = createContext({});

const addId = (array, field = '_id') => array.map((el) => ({ id: el[field], ...el }));

export const DataProvider = ({ children }) => {
    const { mongo } = useContext(RealmContext);

    const [registries, setRegistries] = useState();
    const [registryGroups, setRegistryGroups] = useState();
    const [registrars, setRegistrars] = useState();
    const [registrarGroups, setRegistrarGroups] = useState();
    const [tlds, setTlds] = useState();
    const [enrichments, setEnrichments] = useState([]);

    const loading = (registries && registryGroups && registrars && registrarGroups && tlds && enrichments) ? false : Object.entries({
        registries, registryGroups, registrars, registrarGroups, tlds
    }).filter(([ name, value ]) => !value).map(([ name ]) => name);

    // TODO: add enrichments here
    /*
    { name: 'rdap', primary: 'RDAP', secondary: 'Domain registration date, status, nameservers, and more' },
    { name: 'dns', primary: 'DNS Lookup', secondary: 'Domain nameservers, IP and IPv6 addresses, and more' },
    { name: 'cleandns', primary: 'CleanDNS', secondary: 'Known abuse activity' },
    { name: 'virustotal', primary: 'Virus Total', secondary: 'Known abuse activity' },
    { name: 'hybridanalysis', primary: 'Hybrid Analysis', secondary: 'Known abuse activity' },
    */

    const updateData = useCallback(() => Promise.all([
        mongo.db('cart').collection('tlds').find({}, { sort: { tld: 1 } }).then((r) => setTlds(addId(r))),
        mongo.db('cart').collection('registries').find({}, { sort: { name: 1 } }).then((r) => setRegistries(addId(r))),
        mongo.db('cart').collection('groupedRegistries').find({}, { sort: { name: 1 } }).then((r) => setRegistryGroups(addId(r))),
        mongo.db('cart').collection('registrars').find({ status: "Accredited" }, { sort: { name: 1 } }).then((r) => setRegistrars(addId(r))),
        mongo.db('cart').collection('groupedRegistrars').find({}, { sort: { name: 1 } }).then((r) => setRegistrarGroups(addId(r))),
        // mongo.db('cart').collection('enrichments').find({}, { sort: { name: 1 } }).then((r) => setEnrichments(r));
    ]), [mongo]);

    useEffect(() => {
        if (mongo) updateData();
    }, [mongo]);

    return <DataContext.Provider value={{
        loading,
        registries,
        registryGroups,
        registrars,
        registrarGroups,
        tlds,
        enrichments,
        updateData,
    }}>{children}</DataContext.Provider>;
};
