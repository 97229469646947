import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RealmContext } from '../../ReactRealmProvider';
import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Slider, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { DataContext } from '../../contexts/DataProvider';
import { accountTypes } from '../../data/accountTypes';
import { Link } from 'react-router-dom';
import { Add, Delete, Visibility, VisibilityOff } from '@mui/icons-material';
import { abuseNames } from '../../data/abuseNames';

export const TestAPI = () => {
    const { user, customData, mongo, refreshCustomData } = useContext(RealmContext);
    const data = useContext(DataContext);

    const initialAccount = useRef({
        api: customData.api || {},
    }).current;

    const [account, setAccount] = useState(initialAccount);
    const [showApiKey, setShowApiKey] = useState(false);
    const [ now ] = useState(new Date ());

    const [ apiArgs, setApiArgs ] = useState({
        // since: new Date (now.getTime() - 60000 * now.getTimezoneOffset()).toISOString().substr(0, 16),
        // until: new Date (now.getTime() - 60000 * now.getTimezoneOffset()).toISOString().substr(0, 16),
    });

    const [ apiResponse, setApiResponse ] = useState({});

    const apiSend = useCallback((endpoint, args) => {
        const query = new URLSearchParams (args);

        console.log({ endpoint, query: query.toString() });

        fetch(`${process.env.REACT_APP_NETBEACON_API}/${endpoint}?${query.toString()}`, {
            headers: {
                Authorization: `Bearer ${account.api.key}`
            },
        }).then((r) => r.json()).then((r) => {
            console.log(r);
            setApiResponse(r);
        })
    }, [account]);

    const newApiKey = async () => {
        await refreshCustomData();

        // check realm for api key
        const hasToken = (await user.apiKeys.fetchAll()).find((k) => k.name === 'token');

        // if realm has the token but the user doc doesn't, delete the realm key
        if (hasToken) await user.apiKeys.delete(hasToken._id);

        // create api key
        const api = await user.apiKeys.create('token');
        await mongo.db('cart').collection('users').updateOne({ email: user.profile.email }, { $set: { api } })
        .then(() => {
            setAccount((account) => ({ ...account, api }));
        })
        .catch((error) => {
            user.apiKeys.delete(api.id);
            console.error(error);
        });
    }

    return (<>
        <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
                <Typography mt={1} variant="h4">Test API</Typography>
            </Grid>
            <Grid item>
                <Button sx={{mr:1}} variant='outlined' component={Link} to="/account">Settings</Button>
                <Button variant='outlined' component={Link} to="/account/reports">My Reports</Button>
            </Grid>
        </Grid>
        <Grid container spacing={2} mt={2} alignItems='flex-start'>
            {(account.type !== 'reporter') && <>
            <Grid item xs={12} container flex alignItems='center' spacing={2}>
                <Grid item xs={4} container flex justifyContent='flex-end'>
                    <Button variant="contained" onClick={() => newApiKey()}>Generate New API Key</Button>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        type={showApiKey ? 'text' : 'password'}
                        value={account.api?.key || ''}
                        label="API Key"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => setShowApiKey((v) => !v)}>{showApiKey ? <VisibilityOff/> : <Visibility/>}</IconButton>
                            </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={4} container flex justifyContent='flex-end'>
                    <Button variant='contained' onClick={() => apiSend('receive/incidents', apiArgs)}>Receive Abuse Incidents</Button>
                </Grid>
                <Grid item xs={8}>
                    Since <TextField type='datetime-local' value={apiArgs.since || ''} onChange={(ev) => setApiArgs((args) => ({ ...args, since: ev.target.value }))}/>
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={8}>
                    Until <TextField type='datetime-local' value={apiArgs.until || ''} onChange={(ev) => setApiArgs((args) => ({ ...args, until: ev.target.value }))}/>
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={8}>
                    Limit <TextField type='number' value={apiArgs.limit || 3} onChange={(ev) => setApiArgs((args) => ({ ...args, limit: ev.target.value }))}/>
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={8}>
                    <xmp>{JSON.stringify(apiResponse, null, 4)}</xmp>
                </Grid>
            </Grid>
            </>}
        </Grid>
        {true && <xmp>{JSON.stringify(account, null, 2)}</xmp>}
    </>);
};
