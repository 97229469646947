import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DropzoneAreaBase } from 'mui-file-dropzone';
import { ErrorBoundary } from '../ErrorBoundary';
// import { encode } from 'base64-arraybuffer';

const cached = {};

export const FileUpload = ({ value, setValue, config, onRejectedImage }) => {
    const [ files, setFiles ] = useState(value || []);
    useEffect(() => setValue(files), [files]);

    const onAdd = useCallback(async (newFiles) => {
        setFiles((files) => ([ ...files, ...newFiles ]));

        for (const file of newFiles) {
            // const image = encode(await f.file.arrayBuffer());
            const image = file.data.replace(/^.*?base64,/, '');
            if (! cached[image]) {
                const r = await fetch(`https://us-central1-cart-324516.cloudfunctions.net/safeSearchImage`, {
                    method: 'POST',
                    body: JSON.stringify({ image }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Headers': '*',
                    }        
                }).then((r) => r.json());

                if (r.success) {
                    if (r.adult >= 2 || r.racy >= 2 || r.violence >= 2) {
                        cached[image] = -1;
                        setFiles((files) => files.filter((f) => f !== file));
                        onRejectedImage && onRejectedImage(file);
                        // alert(file.file.name);
                    }
                    else {
                        cached[image] = 1;
                    }
                }
                else cached[image] = 0;
            }
            else if (cached[image] < 0) {
                setFiles((files) => files.filter((f) => f !== file));
                // alert(file.file.name);
            }
        }
    }, [setFiles]);

    const onDelete = useCallback(async (file, index) => {
        setFiles((files) => files.filter((f, i) => i !== index));
    }, [setFiles]);

    return <>
        <Box margin="1em">
            <ErrorBoundary>
                <Typography mb={2}>Accepted file types: .jpg, .png, .gif, .txt, .html</Typography>
                <DropzoneAreaBase
                    onAdd={onAdd}
                    onDelete={onDelete}
                    showAlerts={false}
                    fileObjects={files}
                    useChipsForPreview
                    acceptedFiles={['image/*', 'text/*']}
                    dropzoneText={'Click to upload, or drag and drop files here'}
                    filesLimit={config.multi ? 5 : 1}
                />
            </ErrorBoundary>
        </Box>
    </>;
};
