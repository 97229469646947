import { Box, Button, Divider, Grid, Typography, Tooltip } from "@mui/material";
import { ResponsiveContainer } from 'recharts';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LinearProgress } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { downloadCSV } from "../../utils/downloadCSV";
import './index.css';

export const ChartWrapper = ({
    children,
    data,
    fullwidth = false,
    showTable = false,
    headers,
    needsDivider = false,
    title,
    xs = 4,
    height,
    idField = "_id"
}) => {
    return (
        <>
            <Grid item xs={xs} mt={2}>
                <Box display='flex' alignItems="center" justifyContent="space-between">
                    <Box sx={{ width: showTable ? '67%' : '100%' }}>
                        <Box display='flex' alignItems="center" justifyContent="space-between">
                            <Typography color='gray' variant='h6' sx={{ fontVariant: 'all-small-caps' }}>{title}</Typography>
                            {!showTable && (
                                <Button
                                    size="small"
                                    onClick={() =>
                                        downloadCSV(
                                            title,
                                            children?.props?.data
                                        )
                                    }
                                >
                                    <FileDownloadOutlinedIcon />
                                    Export
                                </Button>
                            )}
                        </Box>
                        <ResponsiveContainer width={(fullwidth || data?.length >= 5) ? "100%" : ((((data?.length || 1)) * 20) + '%')} height={height}>
                            {children}
                        </ResponsiveContainer>
                    </Box>
                    {showTable &&
                        <DataGrid
                            rowHeight={52}
                            style={{ height: `${height}px`, overflowY: 'auto' }}
                            columns={headers.map(({ name, field }) => ({ field, headerName: name, flex: 1, minWidth: 100 }))}
                            rows={data}
                            components={{
                                Toolbar: () => <CustomToolbar fileName={title}/>,
                                LoadingOverlay: LinearProgress,
                                Cell: CustomCell
                            }}
                            loading={false}
                            // pageSize={50}
                            // rowsPerPageOptions={[25,50,100]}
                            getRowId={(row) => row[idField]?.toString()}

                        />
                    }
                </Box>
                {needsDivider && <Grid xs={xs} offset={1}>
                    <Divider sx={{ marginRight: '1rem' }} light />
                </Grid>}
            </Grid>
        </>
    )
};

const CustomCell = (props) => {
    const type = typeof (props.value);
    let value;
    switch (type) {
        case 'object':
            value = Object.entries(props.value).map(([key, value]) => `${key}: ${value}`)
            break;
        default:
            value = props.value;
            break;
    }

    return (
        <div
            style={{ width: `${props.width}px`, overflowY: 'auto' }}
            className='MuiDataGrid-cell MuiDataGrid-cell--textLeft'
        >
            <div
                style={{ wordWrap: 'break-word' }}
                className="MuiDataGrid-cellContent">
                <Tooltip title={value && <div> {Array.isArray(value) ? value.map((v) => <div>{v}</div>) : value}</div>}>
                    {Array.isArray(value) ? value.join(', ') : value ? value : 'N/A'}
                </Tooltip>
            </div>
        </div>
    )
}

const CustomToolbar = (props) => {
    return (
        <GridToolbarContainer sx={{ justifyContent: 'right' }}>
            <GridToolbarExport csvOptions={{ fileName: props.fileName }} printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    )
};

// <TableContainer component={Paper} sx={{ height: '300px', overflowY: 'auto', width: '25%' }} >
//     {/* how do I set the table height with an overflow for scroll? */}
//     <Table stickyHeader={true} size='medium' aria-label="simple table">
//         <TableHead>
//             <TableRow sx={{ maxWidth: '100px' }}>
//                 {headers.map((header) => {
//                     return <TableCell>{header}</TableCell>
//                 })}
//             </TableRow>
//         </TableHead>
//         <TableBody>
//             {data.map((row) => (
//                 <TableRow
//                     key={row._id}
//                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                 >
//                     <TableCell component="th" scope="row">
//                         {row._id}
//                     </TableCell>
//                     <TableCell align="left">{row.count}</TableCell>
//                 </TableRow>
//             ))}
//         </TableBody>
//     </Table>
// </TableContainer>