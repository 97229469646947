import { BarChart } from "../../../components/charts";
import { useDashboardAggregation } from "../../../hooks/useDashboardAggregations";
import { Box } from '@mui/material';
export const IncidentsByUserChart = () => {
  const { data, loading, colors, ticks } = useDashboardAggregation({
    aggregation: "incidentsByRealmId",
  });

  return (
    <BarChart
      colors={colors}
      ticks={ticks}
      countsField={"incidents"}
      xAxisFieldName="email"
      layout="vertical"
      showTable={true}
      height={!data.length ? 120 : Math.min(480, 90 * data.length)}
      needsDivider={false}
      loading={loading}
      data={data}
      truncate={10}
      title={"Incidents by User"}
      xs={12}
      headers={[
        { name: "User", field: "email" },
        { name: "Count", field: "incidents" },
      ]}
      CustomTooltip={CustomTooltip}
    />
  );
};

export default IncidentsByUserChart;

const CustomTooltip = ({ active, payload, label, data, colors }) => {

  if (active && payload && payload.length) {
    const { _id } = payload[0].payload;
    const color = colors[data.findIndex((d) => d._id === _id)];
    return (
      <Box
        sx={{
          background: "#FFFFFF",
          opacity: "100",
          width: "300px",
          height: "fit-content",
          border: "1px solid grey",
          zIndex: "1500",
        }}
        padding={2}
      >
        <div>
          {label}
        </div>
        {payload.map(({ fill, dataKey, value }, i) => {

          return (
            <div key={i}>
              <span
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: color,
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              />{" "}
              {dataKey} ({value})
            </div>
          );
        })}
      </Box>
    );
  }

  return null;
};